import { useEffect, useState } from "react";
import {
    Grid,
    Pagination,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import waiting from '../assets/waiting.jpg';

import visa from '../assets/visa.png';
import master from '../assets/master.png';
import elo from '../assets/elo_debito.png';
import hipercard from '../assets/hipercard.png';
import Pix from '../assets/Pix.png';
import amex from '../assets/amex.png';
import flagXulis from '../assets/flags/flagXulis.png';

import Stylesheet from "reactjs-stylesheet";
import { format } from "date-fns";

const TabelaGroup = ({ hoje, agora, isMobile, rows, selectedItem, setSelectedItem, setSelectedItemValor }) => {

    const bandeiraIcons = {
        'XULIZ': flagXulis,
        'Visa Débito': visa,
        'VISA': visa,
        'Master Débito': master,
        'MASTER': master,
        'Elo Débito': elo,
        'ELO': elo,
        'Amex Débito': amex,
        'AMEX': amex,
        'Hipercard Débito': hipercard,
        'HIPERCARD': hipercard,
        'PIX': Pix
    };

    const title = [
        ['Data de pagamento', 'Bandeira', 'Tipo de lançamento', 'Quantidade de transações', 'Valor bruto', 'Valor líquido', ''],
        ['ID', 'Data da venda', 'Data de pagamento', 'Bandeira', 'Tipo de lançamento', 'Valor bruto', 'Taxa', 'Valor líquido', 'Status']
        // ['Data da venda', 'Data de pagamento', 'Bandeira', 'Tipo de lançamento', 'Valor bruto', 'Taxa', 'Valor líquido', 'Status', 'Banco depositado']
    ]

    const [showTextOnly, setShowTextOnly] = useState(false);

    useEffect(() => {
        // Define o temporizador para 5 segundos (5000 ms)
        const timer = setTimeout(() => {
            setShowTextOnly(true); // Atualiza o estado para exibir apenas o texto
        }, 5000);

        // Limpa o temporizador quando o componente for desmontado
        return () => clearTimeout(timer);
    }, [rows]);

    const grouped = new Map();

    rows.forEach((row) => {
        // Cria a chave única para o agrupamento
        const key = JSON.stringify([row.datavencimento, row.bandeira, row.bandeiraxuliz === 'Xuliz' ? row.bandeiraxuliz :
            (row.bandeiraxuliz?.split(' ')[1] === 'Crédito' && row.nrtotalparcelas > 1) ? 'Crédito Parcelado' :
                (row.bandeiraxuliz?.split(' ')[1] === 'Crédito' && row.nrtotalparcelas === 1) ? 'Crédito à Vista' :
                    row.bandeiraxuliz?.split(' ')[1]]);

        // Converte valores para float
        const valor = parseFloat(row.valor) || 0;
        const valorbruto = parseFloat(row.valorbruto) || 0;

        // Verifica se a chave já existe no Map
        if (grouped.has(key)) {
            // Atualiza o grupo existente
            const group = grouped.get(key);
            group.valor += valor;
            group.valorbruto += valorbruto;
            group.count += 1;
            group.items.push(row);
        } else {
            // Cria um novo grupo
            grouped.set(key, {
                datavencimento: row.datavencimento,
                bandeira: row.bandeira,
                lancamento: row.bandeiraxuliz === 'Xuliz' ? row.bandeiraxuliz :
                    (row.bandeiraxuliz?.split(' ')[1] === 'Crédito' && row.nrtotalparcelas > 1) ? 'Crédito Parcelado' :
                        (row.bandeiraxuliz?.split(' ')[1] === 'Crédito' && row.nrtotalparcelas <= 1) ? 'Crédito à Vista' :
                            row.bandeiraxuliz === 'Pix' ? 'Pix' :
                                row.bandeiraxuliz?.split(' ')[1],
                valor: valor,
                valorbruto: valorbruto,
                count: 1,
                items: [row]
            });
        }
    });

    const groupedArray = Array.from(grouped.values());

    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(6);

    useEffect(() => {
        setPage(1)
    }, [rows, selectedItem])

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        }).format(value);
    };

    const CustomPagination = ({ totalItems, itemsPerPage, page, setPage }) => {
        const totalPages = Math.ceil(totalItems / itemsPerPage);

        const handleChange = (event, value) => {
            setPage(value);
        };

        return (
            <Grid
                style={{
                    padding: isMobile ? '30px 10px' : '30px 40px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: '#F0EFEB'
                }}
            >
                <Typography sx={{ color: '#142A3099', fontSize: '14px' }}>
                    {`${(page - 1) * itemsPerPage + 1}-${Math.min(page * itemsPerPage, totalItems)} de ${totalItems} recebíveis`}
                </Typography>
                <Pagination
                    count={totalPages}
                    siblingCount={isMobile ? 0 : 1}
                    page={page}
                    onChange={handleChange}
                    variant="outlined"
                    color="primary"
                    shape="rounded"
                    sx={{
                        '& .MuiPaginationItem-root.Mui-selected': {
                            backgroundColor: '#142A30',
                            fontWeight: 600,
                            borderRadius: '10px',
                            color: '#FFFFFF',
                            '&:hover': {
                                backgroundColor: '#142A30',
                            }
                        },
                        '& .MuiPaginationItem-root': {
                            backgroundColor: '#FEFDF9',
                            fontWeight: 600,
                            borderRadius: '10px',
                            color: '#142A30',
                            '&:hover': {
                                backgroundColor: '#FEFDF9',
                            }
                        },
                    }}
                />
            </Grid>
        );
    };

    return (
        <Paper xs={12} elevation={0} sx={{ width: '100%', overflow: 'hidden', borderRadius: '20px' }} >
            <TableContainer sx={{ maxHeight: 720, overflowY: 'auto' }}>
                <Table stickyHeader aria-label="sticky table" sx={{ bgcolor: '#F0EFEB' }}>
                    <TableHead>
                        <TableRow>
                            {title[selectedItem?.items?.length > 0 ? 1 : 0].map(label => (
                                <TableCell key={label}
                                    sx={{
                                        fontSize: '14px',
                                        color: '#142A30',
                                        fontWeight: 700,
                                        textAlign: 'center',
                                        bgcolor: '#F0EFEB',
                                        width: '15ch',
                                        minWidth: label == '' ? null : '14ch',
                                        textAlign: label == 'Banco depositado' ? 'start' : 'center'
                                    }}>
                                    {label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {selectedItem?.items?.length > 0 ?
                            <>
                                {selectedItem?.items
                                    .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                                    .map((row, index) => (
                                        <TableRow key={index} sx={{ maxHeight: '20px' }}>
                                            <TableCell sx={[styles.textTable, { textAlign: 'center' }]}>
                                                {row.idvenda}
                                            </TableCell>
                                            <TableCell sx={[styles.textTable, { textAlign: 'center' }]}>
                                                {row.data ? format(`${row.data}T00:00:00`, 'dd/MM/yyyy') : 'N/A'}
                                            </TableCell>
                                            <TableCell sx={[styles.textTable, { textAlign: 'center' }]}>
                                                {row.datavencimento ? format(`${row.datavencimento}T00:00:00`, 'dd/MM/yyyy') : 'N/A'}
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>
                                                {row.bandeira && bandeiraIcons[row.bandeira] ? (
                                                    <img src={bandeiraIcons[row.bandeira]} style={{ width: 50, borderRadius: 4 }} alt={row.bandeira} />
                                                ) : (
                                                    <p>{row.bandeira}</p>
                                                )}
                                            </TableCell>
                                            <TableCell sx={[styles.textTable, { textAlign: 'center' }]}>
                                                {row.bandeiraxuliz?.split(' ') == 'Xuliz' ? "Xulis" :
                                                    row.bandeiraxuliz?.split(' ')[1] == 'Crédito' && row.nrtotalparcelas > 1 ? 'Crédito Parcelado' :
                                                        row.bandeiraxuliz?.split(' ')[1] == 'Crédito' && row.nrtotalparcelas == 1 ? 'Crédito à Vista' :
                                                            row.bandeiraxuliz == 'Pix' ? 'Pix' :
                                                                row.bandeiraxuliz?.split(' ')[1]}
                                            </TableCell>
                                            <TableCell sx={[styles.textTableBold, { textAlign: 'center' }]}>
                                                {formatCurrency(row.valorbruto)}
                                            </TableCell>
                                            <TableCell sx={[styles.textTable, { textAlign: 'center' }]}>
                                                {formatCurrency(row.valor - row.valorbruto)}
                                            </TableCell>
                                            <TableCell sx={[styles.textTableBold, { textAlign: 'center' }]}>
                                                {formatCurrency(row.valor)}
                                            </TableCell>
                                            <TableCell sx={[styles.statusTable, {
                                                textAlign: 'center',
                                                color: row.datavencimento < hoje ? '#7FA800' : row.datavencimento == hoje && agora >= '15:10' ? '#7FA800' : '#faa528',
                                            }]}>
                                                {
                                                    row.datavencimento < hoje ? 'Pago' :
                                                        row.datavencimento == hoje && agora >= '15:10' ? 'Pago' : 'A receber'
                                                }
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </>
                            :
                            <>
                                {groupedArray
                                    .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                                    .map((row, index) => (
                                        <TableRow key={index} sx={{ maxHeight: '20px' }}>
                                            {/* <TableCell sx={[styles.textTable, { textAlign: 'start' }]}>
                                                {row.idvenda} #
                                                {console.log('row', row)}
                                            </TableCell> */}
                                            <TableCell sx={[styles.textTable, { textAlign: 'center', display: 'flex', flexDirection: 'column' }]}>
                                                {row.datavencimento ? format(`${row.datavencimento}T00:00:00`, 'dd/MM/yyyy') : 'N/A'}
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>
                                                {row.bandeira && bandeiraIcons[row.bandeira] ? (
                                                    <img src={bandeiraIcons[row.bandeira]} style={{ width: 50, borderRadius: 4 }} alt={row.bandeira} />
                                                ) : (
                                                    <p>{row.bandeira}</p>
                                                )}
                                            </TableCell>
                                            <TableCell sx={[styles.textTable, { textAlign: 'center' }]}>
                                                {row.lancamento == "Xuliz" ? "Xulis" : row.lancamento}
                                            </TableCell>
                                            <TableCell sx={[styles.textTableBold, { textAlign: 'center' }]}>
                                                {row.count}
                                            </TableCell>
                                            <TableCell sx={[styles.textTable, { textAlign: 'center' }]}>
                                                {formatCurrency(row.valorbruto)}
                                            </TableCell>
                                            <TableCell sx={{ padding: '0px' }}>
                                                {/* <Grid sx={{ display: 'flex', justifyContent: 'center' }}> */}
                                                <Typography sx={{ ...styles.textTable, textAlign: 'center' }}>{formatCurrency(row.valor)}</Typography>
                                                {/* <Typography sx={styles.actionTable} onClick={() => { console.log('Details for', row); }}>⋮</Typography> */}
                                                {/* </Grid> */}
                                            </TableCell>
                                            <TableCell sx={{ width: '', paddingLeft: 0 }}>
                                                <Typography sx={{ ...styles.actionTable, textAlign: 'start' }} onClick={() => { setSelectedItem(row); setSelectedItemValor(row) }}>⋮</Typography>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </>
                        }

                    </TableBody>
                </Table>
                {showTextOnly && groupedArray.length === 0 ?
                    <Typography sx={{
                        padding: '10px',
                        textAlign: 'center',
                        backgroundColor: '#f0efeb'
                    }}>
                        Nenhum dado a ser exibido
                    </Typography> :
                    groupedArray.length === 0 && (
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: 30, backgroundColor: '#f0efeb' }}>
                            <img src={waiting} style={{ width: 50, height: 50 }} alt="Loading" />
                        </div>
                    )}
            </TableContainer>
            <CustomPagination
                totalItems={selectedItem?.items?.length ? selectedItem?.items?.length : groupedArray?.length}
                itemsPerPage={6}
                page={page}
                setPage={setPage} />
        </Paper >
    );
};

export default TabelaGroup;

const styles = Stylesheet.create({
    textTable: {
        color: '#142A30',
        fontSize: '14px',
        textAlign: 'end',
        marginBottom: 0
    },
    textTableBold: {
        color: '#142A30',
        fontSize: '14px',
        fontWeight: 700,
        textAlign: 'end',
        marginBottom: 0
    },
    statusTable: {
        fontSize: '15px',
        fontWeight: 700,
        textAlign: 'end'
    },
    actionTable: {
        color: '#142A30',
        cursor: 'pointer',
        fontWeight: 700
    }
});

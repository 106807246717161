import { useState } from "react";
import { Grid } from "@mui/material";
import Stylesheet from "reactjs-stylesheet";
import MenuOnTop from "../componentes/menus/MenuOnTop";
import { ControllerPrimeiroAcesso } from "./ControllerPrimeiroAcesso";
import InfoContent from "./InfoContent/InfoContent";

import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import Step3 from "./Steps/Step3";
import Step4 from "./Steps/Step4";
import ModalAlertInputs from "./Modals/ModalAlertInputs";


function ViewsPrimeiroAcesso() {

    const {
        isMobile,
        screenWidth,
        status,
        companyData,
        handleFieldChangeCompany,
        businessAddress,
        handleChangeBusinessAddress,
        partner,
        handleChangePartner,
        bankInfo,
        handleChangeBankInfo,
        moreInfo,
        handleChangeMoreInfo,
        configuracaoPix,
        handleChangeConfiguracaoPix,
        logo,
        handleChangeLogo,
        unitDetails,
        handleChangeUnitDetails,
        addNewPartner,
        correspondenceAddress,
        handleChangeCorrespondenceAddress,
        getSameAdress,
        moreInfoEstablishment,
        handleChangeMoreInfoEstablishment
    } = ControllerPrimeiroAcesso();

    const [step, setStep] = useState(0);
    const [modalAlertInputs, setModalAlertInputs] = useState(false);


    const renderStep = () => {
        switch (step) {
            case 0:
                return <Step1
                    status={status}
                    companyData={companyData}
                    handleFieldChangeCompany={handleFieldChangeCompany}
                    businessAddress={businessAddress}
                    handleChangeBusinessAddress={handleChangeBusinessAddress}
                    partner={partner}
                    handleChangePartner={handleChangePartner}
                    addNewPartner={addNewPartner}
                />;
            case 1:
                return <Step2
                    status={status}
                    bankInfo={bankInfo}
                    handleChangeBankInfo={handleChangeBankInfo}
                    moreInfo={moreInfo}
                    handleChangeMoreInfo={handleChangeMoreInfo}
                    configuracaoPix={configuracaoPix}
                    handleChangeConfiguracaoPix={handleChangeConfiguracaoPix}
                />;
            case 2:
                return <Step3
                    status={status}
                    logo={logo}
                    handleChangeLogo={handleChangeLogo}
                    unitDetails={unitDetails}
                    handleChangeUnitDetails={handleChangeUnitDetails}
                    correspondenceAddress={correspondenceAddress}
                    handleChangeCorrespondenceAddress={handleChangeCorrespondenceAddress}
                    getSameAdress={getSameAdress}
                    moreInfoEstablishment={moreInfoEstablishment}
                    handleChangeMoreInfoEstablishment={handleChangeMoreInfoEstablishment}
                />;
            case 3:
                return <Step4 logo={logo} status={status} />;
            default:
                return null;
        }
    };

    function calcMimMax(min, max, divide) {
        const calculated = screenWidth / divide;

        if (calculated > min && calculated < max) {
            return calculated;
        } else if (calculated <= min) {
            return min;
        } else if (calculated >= max) {
            return max;
        }
    }

    return (
        <>
            <ModalAlertInputs modal={modalAlertInputs} close={() => setModalAlertInputs(false)} />

            <MenuOnTop calcMimMax={calcMimMax} screenWidth={screenWidth} />
            <Grid sx={{
                ...styles.content,
                display:'flex',
                flexDirection:'row',
                gap: `${screenWidth / 55}px`,
                padding: screenWidth <= 1400 ? `${calcMimMax(11, 45, 94)}px ${calcMimMax(11, 100, 94)}px` : `${calcMimMax(11, 45, 32)}px ${calcMimMax(11, 100, 14)}px`,
            }}>
                <InfoContent setStep={setStep} setModalAlertInputs={setModalAlertInputs} status={status} screenWidth={screenWidth} />

                <Grid sx={{ width: '68%' }}>
                    {renderStep()}
                </Grid>
            </Grid>
        </>
    )
}

export default ViewsPrimeiroAcesso;

const styles = Stylesheet.create({
    content: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: '#FEFDF9'
    }
});
import { useRef, useState } from "react";
import 'jspdf-autotable';
import jsPDF from 'jspdf';
import { CSVLink } from "react-csv";
import { utils, writeFile } from "xlsx";
import Stylesheet from "reactjs-stylesheet";
import { Box, Button, Grid, Modal, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const ModalExport = ({ rows, open, setOpen, isMobile }) => {
    const [selected, setSelected] = useState('');
    const csvLinkRef = useRef();

    const exportToExcel = () => {
        const worksheet = utils.json_to_sheet(rows);
        const workbook = utils.book_new();
        utils.book_append_sheet(workbook, worksheet, "Tabela");

        writeFile(workbook, "tabela.xlsx");
    };

    const csvHeaders = [
        { key: 'id', label: 'ID#' },
        { key: 'codigo_autorizacao', label: 'Código de Autorização' },
        { key: 'dataHora', label: 'Data/Hora' },
        { key: 'dataHora', label: 'Previsão de pagamento' },
        { key: 'bandeira', label: 'Bandeira' },
        { key: 'forma_pagamento', label: 'Forma de Pagamento' },
        { key: 'valor_venda', label: 'Valor venda' },
        { key: 'Cashback', label: 'Cashback' },
        { key: 'valor_taxa', label: 'Valor da taxa/tarifa' },
        { key: 'valor_liquido', label: 'Valor líquido' },
        { key: 'status', label: 'Status' },
    ];

    const exportToPDF = () => {
        const doc = new jsPDF();

        doc.autoTable({
            head: [csvHeaders.map(header => header.label)],
            body: rows.map(row => csvHeaders.map(header => row[header.key])),
        });

        doc.save('tabela.pdf');
    };

    const exportData = () => {
        if (selected == 'excel') {
            exportToExcel();
        }
        if (selected == 'csv') {
            csvLinkRef.current.link.click();
        }
        if (selected == 'pdf') {
            exportToPDF();
        }

    };

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disablePortal
            disableEnforceFocus
            disableAutoFocus
            disableScrollLock
        >
            <Box sx={isMobile ? styles.modalMobile : styles.modal}>

                <CSVLink
                    data={rows}
                    headers={csvHeaders}
                    filename={"tabela.csv"}
                    className="hidden"
                    ref={csvLinkRef}
                />

                <Grid sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                    <Button onClick={() => setOpen(false)}
                        sx={{
                            padding: 0,
                            display: 'flex',
                            cursor: 'pointer',
                            justifyContent: 'flex-end',
                            '&:hover': { backgroundColor: '#FFFFFF' },
                            '&:active': { backgroundColor: '#FFFFFF' }
                        }}>
                        <FontAwesomeIcon
                            icon={faXmark}
                            style={{ width: 20, height: 20, color: "#262626", }}
                        />
                    </Button>
                </Grid>
                <Grid container direction="column" spacing={3}>
                    <Grid item sx={{ paddingBottom: '50px' }}>
                        <Typography id="modal-modal-title" sx={styles.titleModal}>
                            Exportar histórico de vendas
                        </Typography>
                        <Typography id="modal-modal-description">
                            Selecione alguma das opções abaixo para salvar seu(s) arquivo(s).
                        </Typography>
                    </Grid>


                    <Grid item>
                        <ToggleButtonGroup
                            exclusive
                            value={selected}
                            onChange={(event, newValue) => setSelected(newValue)} // Corrige a forma de acessar o valor selecionado
                            aria-label="radio buttons as buttons"
                            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: isMobile ? 'center' : null, paddingBottom: '50px', gap: '50px', flexDirection: isMobile ? 'column' : null }}
                        >
                            <ToggleButton value="excel" aria-label="EXCEL" sx={{ ...styles.radioButton, height: isMobile ? '120px' : '220px' }}>
                                {'Excel (.xls)'}
                            </ToggleButton>
                            <ToggleButton value="csv" aria-label="CSV" sx={{ ...styles.radioButton, height: isMobile ? '120px' : '220px' }}>
                                CSV
                            </ToggleButton>
                            <ToggleButton value="pdf" aria-label="PDF" sx={{ ...styles.radioButton, height: isMobile ? '120px' : '220px' }}>
                                PDF
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                </Grid>
                <Grid>
                    <Button onClick={() => exportData()} sx={{
                        color: '#FFFFFF',
                        bgcolor: '#142A30',
                        borderRadius: '15px',
                        padding: '10px 25px',
                        textTransform: 'none',
                        '&:hover': {
                            backgroundColor: '#142A30'
                        },
                        '&:active': {
                            backgroundColor: '#142A30'
                        }
                    }}>
                        Exportar
                    </Button>
                </Grid>
            </Box>

        </Modal>
    )

}

export default ModalExport;

const styles = Stylesheet.create({
    modal: {
        width: '100%',
        maxWidth: '1100px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: '#FFFFFF',
        borderRadius: '10px',
        boxShadow: 24,
        p: '65px 78px',
    },
    modalMobile: {
        width: '90%',
        maxWidth: '1100px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: '#FFFFFF',
        borderRadius: '10px',
        boxShadow: 24,
        p: '35px 28px',
    },
    titleModal: {
        fontSize: '24px',
        fontWeight: 600
    },
    subTitleModal: {
        fontSize: '15px',
        fontWeight: 400
    },
    radioButton: {
        width: '100%',
        maxWidth: '280px',
        fontWeight: 500,
        color: '#3D3D3D',
        textTransform: 'none',
        backgroundColor: '#F1F1F1',
        fontSize: '20px',
        fontWeight: 600,
        border: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&.Mui-selected': {
            border: 'none',
            fontWeight: 600,
            borderRadius: '10px',
            color: '#FFFFFF',
            backgroundColor: '#142A3099',
            '&:focus': {
                color: '#FFFFFF',
                backgroundColor: '#142A3099',
                borderRadius: '10px',
            },
            '&:active': {
                color: '#FFFFFF',
                backgroundColor: '#142A3099',
                borderRadius: '10px',
            }
        },
        '&:not(.Mui-selected)': {
            border: '0px solid #142A3099',
            borderRadius: '10px !important',
        },
    }
});
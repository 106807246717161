import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Stylesheet from 'reactjs-stylesheet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom';

export default function CustomSeparator({ menuOption, item, props }) {

    const navigate = useNavigate();

    const options = {
        Xloja: [
            { value: 0, label: 'Realizar venda', url: '/xloja' },
            { value: 1, label: 'Vendas de hoje', url: '/vendashoje' },
            { value: 2, label: 'Histórico de vendas', url: '/minhasvendas' }
        ],
        Recebiveis: [
            { value: 0, label: 'Recebíveis', url: '/recebiveis' },
            { value: 1, label: 'Resumo', url: '/resumo_recebiveis' },
        ]
    };

    const selectedOptions = options[menuOption];

    return (
        <Stack spacing={2} sx={{width: '80%'}}>
            <Breadcrumbs separator="" aria-label="breadcrumb">
                {selectedOptions.map((i) => (
                    <Link
                        underline="hover"
                        key={i.value}
                        style={item === i.value ? styles.fistStepSelected : styles.fistStepUnselected}
                        onClick={() => { navigate(`${i.url}`, { state: { param: props } }) }}
                    >
                        {i.label}
                        <FontAwesomeIcon
                            icon={faChevronRight}
                            style={item === i.value ? styles.separtorSelected : styles.separtorUnselected}
                        />
                    </Link>
                ))}
            </Breadcrumbs>
        </Stack>
    );
}

const styles = Stylesheet.create({
    fistStepSelected: {
        fontSize: 16,
        display: 'flex',
        color: '#142A30',
        cursor: "pointer",
        fontWeight: 'bold',
        fontFamily: 'Inter',
        alignItems: 'center',
        textDecoration: 'none'
    },
    fistStepUnselected: {
        fontSize: 16,
        display: 'flex',
        color: '#142A3066',
        cursor: "pointer",
        fontWeight: 'bold',
        fontFamily: 'Inter',
        alignItems: 'center',
        textDecoration: 'none'
    },
    stepSelected: {
        marginLeft: 30,
        fontSize: 16,
        display: 'flex',
        color: '#142A30',
        cursor: "pointer",
        fontWeight: 'bold',
        fontFamily: 'Inter',
        alignItems: 'center',
        textDecoration: 'none'
    },
    stepUnselected: {
        marginLeft: 30,
        fontSize: 16,
        display: 'flex',
        color: '#142A3066',
        cursor: "pointer",
        fontWeight: 'bold',
        fontFamily: 'Inter',
        alignItems: 'center',
        textDecoration: 'none'
    },
    separtorSelected: {
        paddingLeft: 10,
        fontSize: 16,
        cursor: "pointer",
        color: '#142A30',
        fontFamily: 'Inter',
        fontWeight: 'bold'
    },
    separtorUnselected: {
        paddingLeft: 10,
        fontSize: 16,
        cursor: "pointer",
        color: '#142A3066',
        fontFamily: 'Inter',
        fontWeight: 'bold'
    }
}); 
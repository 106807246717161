
import Header from "./Header";
import { Grid } from "@mui/material";
import Content from "./Content";
import Stylesheet from "reactjs-stylesheet";

const Membros = ({
    isMobile,
    screenWidth,
    allMembros,
    dadosX,
    membroSearch,
    handleChangeMembroSearch
}) => {

    return (
        <Grid item xs sx={screenWidth <= 1400 ? styles.contentSmallScreen : isMobile ? styles.contentMobile : styles.content}>
            <Grid container spacing={0}>
                <Header
                    isMobile={isMobile}
                    membroSearch={membroSearch}
                    handleChangeMembroSearch={handleChangeMembroSearch}
                />
                <Content
                    screenWidth={screenWidth}
                    allMembros={allMembros}
                    dadosX={dadosX}
                    membroSearch={membroSearch}
                />
            </Grid>
        </Grid >
    )
}

export default Membros;

const styles = Stylesheet.create({
    content: {
        margin: '40px 50px 16px 70px'
    },
    contentSmallScreen: {
        margin: '45px 25px'
    },
    contentMobile: {
        margin: '20px 10px'
    }
});
import { useState } from "react";
import Stylesheet from "reactjs-stylesheet";
import ModalUploadFile from "../Modals/ModalUploadFile";
import { Button, Grid, OutlinedInput, Typography } from "@mui/material";
import ModalCashback from "../Modals/ModalCashback";
import ConfiguracaoPix from "../Modals/ConfiguracaoPix";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faChevronRight, faClose, faExclamation } from "@fortawesome/free-solid-svg-icons";
import ArrowUpButton from '../../assets/icons/arrowUpButton.png'

const Step2 = ({
    status,
    bankInfo,
    handleChangeBankInfo,
    moreInfo,
    handleChangeMoreInfo,
    configuracaoPix,
    handleChangeConfiguracaoPix
}) => {

    const [modalComprovacaoConta, setModalComprovacaoConta] = useState(false);
    const [modalCashback, setModalCashback] = useState(false);
    const [modalConfiguracaoPix, setModalConfiguracaoPix] = useState(false);
    const [modalCertificadoDigital, setModalCertificadoDigital] = useState(false);

    const [validation, setValidation] = useState(0)

    const [validating, setValidating] = useState(false)

    const checkFilePix = () => {
        setValidating(true);
        setTimeout(() => {
            setValidation(validation === 0 ? 1 : validation === 1 ? 2 : 0);
            setValidating(false);
        }, 1000);
    }

    return (

        <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
            {status[1].error ?
                <Grid sx={{
                    // minWidth: '790px',
                    bgcolor: '#F0EFEB',
                    borderRadius: '30px',
                    marginBottom: '30px',
                    border: '3px solid #E57373',
                    padding: '46px 50px 30px 50px'
                }}>
                    <Grid sx={{ bgcolor: '#FEFDF9', padding: '20px', marginBottom: '30px', borderRadius: '30px' }}>
                        <Grid sx={{
                            gap: '10px',
                            display: 'flex',
                            alignItems: 'center',
                            paddingBottom: '15px',
                        }}>
                            <Grid sx={{
                                width: '30px',
                                height: '30px',
                                borderRadius: '50px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                bgcolor: '#E57373'
                            }}>
                                <FontAwesomeIcon icon={faClose} style={{ fontSize: 24, color: "#FFFFFF" }} />
                            </Grid>
                            <Typography sx={{
                                fontSize: '16px',
                                fontWeight: 600,
                                color: '#142A30'
                            }}>
                                Explicação da inconsistência identificada
                            </Typography>
                        </Grid>
                        <Grid>
                            <Typography sx={{ fontSize: '16px', color: '#142A3099', paddingBottom: '15px' }}>
                                Sua conta não corresponde à empresa cadastrada e o Client ID não está ativo no sistema da Sicoob. Para resolver esse problema e garantir a aprovação da sua conta, é fundamental cadastrar uma conta bancária em nome da empresa e seguir o processo para gerar um novo Client ID.
                            </Typography>
                            <Typography sx={{ fontSize: '16px', color: '#142A3099' }}>
                                Se precisar de ajuda, entre em contato com o Xuxu, que irá guiá-lo em todas as etapas necessárias.
                            </Typography>
                        </Grid>
                    </Grid>
                    <Button
                        // onClick={() => { set('instituicao', 'Sicoob'); close() }}
                        sx={{
                            width: '100%',
                            borderRadius: '30px',
                            bgcolor: '#17333A',
                            '&:hover': { backgroundColor: '#142A30' },
                            '&:active': { backgroundColor: '#142A30' }
                        }}
                    >
                        <Typography sx={{ color: '#FFFFFF', textTransform: 'none', padding: '10px' }}>
                            Reenviar Dados Financeiros
                        </Typography>
                    </Button>
                </Grid> : null}

            <Grid sx={{
                // minWidth: '796px',
                bgcolor: '#F0EFEB',
                borderRadius: '30px',
                border: status[0].success ? '3px solid #9DE200' : status[1].error ? '3px solid #E57373' : null,
            }}>

                <ModalUploadFile
                    modal={modalComprovacaoConta}
                    close={() => setModalComprovacaoConta(false)}
                    file={bankInfo.comprovacaoConta}
                    setFile={handleChangeBankInfo}
                    fieldName='comprovacaoConta'
                />

                <ModalCashback
                    modal={modalCashback}
                    close={() => setModalCashback(false)}
                    file={moreInfo.tipoCashback}
                    setFile={handleChangeMoreInfo}
                    fieldName='tipoCashback'
                />

                <ConfiguracaoPix
                    modal={modalConfiguracaoPix}
                    close={() => setModalConfiguracaoPix(false)}
                    set={handleChangeConfiguracaoPix}
                />

                <ModalUploadFile
                    modal={modalCertificadoDigital}
                    close={() => setModalCertificadoDigital(false)}
                    file={configuracaoPix.certificadoDigital}
                    setFile={handleChangeConfiguracaoPix}
                    fieldName='certificadoDigital'
                />

                <Grid sx={{ padding: '46px 50px 30px 50px' }}>
                    <Grid sx={{ paddingBottom: '30px' }}>
                        <Typography sx={{ fontSize: '24px', fontWeight: 700, color: '#142A30' }}>
                            Dados bancários
                        </Typography>
                        <Grid sx={{ padding: '20px 15px', bgcolor: '#FFF8EA', borderRadius: '30px', margin: '10px 0px 0px 0px' }}>
                            <Typography sx={{ fontSize: '16px', color: '#142A3099' }}>
                                1. Certifique-se que sua conta bancária está registrada sob o mesmo nome comercial do CNPJ informado anteriormente.
                            </Typography>
                            <Typography sx={{ fontSize: '16px', color: '#142A3099' }}>
                                2. Os fundos serão enviados de forma automática (de acordo com o período acordado) para a conta registrada abaixo.
                            </Typography>
                            <Typography sx={{ fontSize: '16px', color: '#142A3099' }}>
                                3. A conta só será aprovada se todos os dados estiverem 100% corretos.
                            </Typography>
                            <Typography sx={{ fontSize: '16px', color: '#142A3099' }}>
                                4. Caso tenha alguma dúvida, nossa equipe está a disposição para ajudar.
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid sx={{
                        gap: '10px',
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'space-between'
                    }}>
                        <OutlinedInput
                            sx={{
                                ...styles.customInputNumber,
                                // minWidth: '165px',
                                flexBasis: '25%',
                                maxWidth: '100%',
                            }}
                            type="text"
                            placeholder="*Código banco"
                            value={bankInfo.codigoBanco}
                            onChange={(e) => handleChangeBankInfo('codigoBanco', e.target.value)}
                        />
                        <OutlinedInput
                            sx={{
                                ...styles.customInputNumber,
                                // minWidth: '515px',
                                flexBasis: '70%',
                                maxWidth: '100%',
                            }}
                            type="text"
                            placeholder="*Nome banco"
                            value={bankInfo.nomeBanco}
                            onChange={(e) => handleChangeBankInfo('nomeBanco', e.target.value)}
                        />
                        <OutlinedInput
                            sx={{
                                ...styles.customInputNumber,
                                // minWidth: '165px',
                                flexBasis: '10%',
                                maxWidth: '100%',
                            }}
                            type="text"
                            placeholder="*Agência"
                            value={bankInfo.agencia}
                            onChange={(e) => handleChangeBankInfo('agencia', e.target.value)}
                        />
                        <OutlinedInput
                            sx={{
                                ...styles.customInputNumber,
                                // minWidth: '165px',
                                flexBasis: '5%',
                                maxWidth: '100%',
                            }}
                            type="text"
                            placeholder="*Conta + dígito"
                            value={bankInfo.condaDigito}
                            onChange={(e) => handleChangeBankInfo('condaDigito', e.target.value)}
                        />
                        <Grid sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            border: '2px solid #142A3066',
                            borderRadius: '30px',
                            bgcolor: '#FBFBF6',
                            flexBasis: '49%',
                            maxWidth: '49%',
                        }}>
                            <OutlinedInput
                                sx={{
                                    ...styles.customInputNumber,
                                    flexBasis: '5%',
                                    maxWidth: '49%',
                                    // minWidth: '301px',
                                    '& .MuiOutlinedInput-input': {
                                        display: 'flex',
                                        justifyContent: 'center',
                                        borderRadius: '30px',
                                        border: 'none',
                                        cursor: 'pointer'
                                    },
                                }}
                                type="text"
                                placeholder="+Comprovação de conta"
                                onClick={() => setModalComprovacaoConta(true)}
                                value={bankInfo.comprovacaoConta?.name ? bankInfo.comprovacaoConta?.name : ''}
                            />
                            <FontAwesomeIcon icon={faChevronRight} style={{ fontSize: 15, color: "#142A3099", paddingRight: '25px' }} />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid sx={{
                    opacity: '0.7',
                    borderTopStyle: 'dashed',
                    borderTopWidth: '1.5px',
                    borderTopColor: '#142A3066',
                    margin: '0px 50px'
                }} />

                <Grid sx={{ padding: '30px 50px 30px 50px' }}>
                    <Grid sx={{ paddingBottom: '30px' }}>
                        <Typography sx={{ fontSize: '24px', fontWeight: 700, color: '#142A30' }}>
                            Mais informações
                        </Typography>
                    </Grid>

                    <Grid sx={{
                        gap: '10px',
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'space-between'
                    }}>
                        <OutlinedInput
                            sx={{
                                ...styles.customInputNumber,
                                // minWidth: '315px',
                                flexBasis: '35%',
                                maxWidth: '49%',
                            }}
                            type="text"
                            placeholder="*Média de vendas no cartão (mensal)"
                            value={moreInfo.mediaVendasCartao}
                            onChange={(e) => handleChangeMoreInfo('mediaVendasCartao', e.target.value)}
                        />
                        <OutlinedInput
                            sx={{
                                ...styles.customInputNumber,
                                // minWidth: '315px',
                                flexBasis: '35%',
                                maxWidth: '49%',
                            }}
                            type="text"
                            placeholder="*Maior valor em uma única transação"
                            value={moreInfo.maiorValorUnicaTransacao}
                            onChange={(e) => handleChangeMoreInfo('maiorValorUnicaTransacao', e.target.value)}
                        />
                        <OutlinedInput
                            sx={{
                                ...styles.customInputNumber,
                                // minWidth: '315px',
                                flexBasis: '35%',
                                maxWidth: '49%',
                            }}
                            type="text"
                            placeholder="*Faturamento mensal (bruto)"
                            value={moreInfo.faturamentoMensalBruto}
                            onChange={(e) => handleChangeMoreInfo('faturamentoMensalBruto', e.target.value)}
                        />
                        <Grid sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            border: '2px solid #142A3066',
                            borderRadius: '30px',
                            bgcolor: '#FBFBF6',
                            flexBasis: '49%',
                            maxWidth: '49%',
                        }}>
                            <OutlinedInput
                                sx={{
                                    ...styles.customInputNumber,
                                    flexBasis: '5%',
                                    maxWidth: '49%',
                                    // minWidth: '302px',
                                    '& .MuiOutlinedInput-input': {
                                        display: 'flex',
                                        justifyContent: 'center',
                                        borderRadius: '30px',
                                        border: 'none',
                                        cursor: 'pointer'
                                    },
                                }}
                                type="text"
                                placeholder="*Tipo cashback"
                                onClick={() => setModalCashback(true)}
                                value={moreInfo.tipoCashback.name}
                            />
                            <FontAwesomeIcon icon={faChevronRight} style={{ fontSize: 15, color: "#142A3099", paddingRight: '25px' }} />
                        </Grid>
                    </Grid>
                </Grid>

                <Typography onClick={() => setModalCashback(true)} sx={{ margin: '0px 50px 30px', fontSize: '16px', color: '#142A30', cursor: 'pointer' }}>
                    Ver configuração do cashback
                </Typography>

                <Grid sx={{
                    opacity: '0.7',
                    borderTopStyle: 'dashed',
                    borderTopWidth: '1.5px',
                    borderTopColor: '#142A3066',
                    margin: '0px 50px'
                }} />

                <Grid sx={{ padding: '30px 50px 30px 50px' }}>
                    <Grid sx={{ paddingBottom: '30px' }}>
                        <Typography sx={{ fontSize: '24px', fontWeight: 700, color: '#142A30' }}>
                            Configuração Pix
                        </Typography>
                    </Grid>

                    <Grid sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        border: '2px solid #142A3066',
                        borderRadius: '30px',
                        bgcolor: '#FBFBF6',
                        marginBottom: '15px'
                    }}>
                        <OutlinedInput
                            sx={{
                                ...styles.customInputNumber,
                                flexBasis: '5%',
                                maxWidth: '100%',
                                // minWidth: '302px',
                                '& .MuiOutlinedInput-input': {
                                    display: 'flex',
                                    justifyContent: 'center',
                                    borderRadius: '30px',
                                    border: 'none',
                                    cursor: 'pointer'
                                },
                            }}
                            type="text"
                            placeholder="*instituição"
                            value={configuracaoPix.instituicao}
                            onClick={() => setModalConfiguracaoPix(true)}
                        />
                        <FontAwesomeIcon icon={faChevronRight} style={{ fontSize: 15, color: "#142A3099", paddingRight: '25px' }} />
                    </Grid>
                    {configuracaoPix?.instituicao ?
                        <Grid sx={{
                            bgcolor: '#FEFDF9',
                            borderRadius: '30px',
                            padding: '25px 30px'
                        }}>
                            <Grid sx={{ paddingBottom: '30px' }}>
                                <Typography sx={{
                                    fontSize: '24px',
                                    color: '#142A30',
                                    fontWeight: 700
                                }}>
                                    Sua chave Pix
                                </Typography>
                                <Typography sx={{
                                    color: '#142A3099',
                                }}>
                                    Insira a sua chave Pix abaixo, que pode ser a copia e cola, CNPJ, e-mail ou número de celular.
                                </Typography>
                            </Grid>

                            <OutlinedInput
                                sx={{
                                    ...styles.customInputNumber,
                                    width: '100%',
                                    paddingBottom: '30px'
                                }}
                                type="text"
                                placeholder="*Chave pix"
                                value={configuracaoPix.chavePix}
                                onChange={(e) => handleChangeConfiguracaoPix('chavePix', e.target.value)}
                            />

                            <Grid sx={{
                                opacity: '0.7',
                                borderTopStyle: 'dashed',
                                borderTopWidth: '1.5px',
                                borderTopColor: '#142A3066',
                                paddingBottom: '30px'
                            }} />

                            <Grid sx={{ paddingBottom: '30px' }}>
                                <Typography sx={{
                                    fontSize: '24px',
                                    color: '#142A30',
                                    fontWeight: 700
                                }}>
                                    Client ID Pix
                                </Typography>
                                <Typography sx={{
                                    color: '#142A3099',
                                }}>
                                    Está com dúvidas sobre onde encontrar as informações? Não se preocupe! Temos um artigo que explica detalhadamente cada passo do processo.
                                </Typography>
                                <Typography
                                    onClick={() => window.location.href = 'https://docsxulis.notion.site/APIs-1a97a4da9a1048da9dcb61cb2978cc7e?p=e094885a87574f35b4a345c7f2654830&pm=c'}
                                    sx={{
                                        color: '#142A30',
                                        cursor: 'pointer'
                                    }}>
                                    Ver artigo
                                </Typography>
                            </Grid>

                            <OutlinedInput
                                sx={{
                                    ...styles.customInputNumber,
                                    width: '100%',
                                    paddingBottom: '30px'
                                }}
                                type="text"
                                placeholder="*Copie aqui seu Client ID Pix"
                                value={configuracaoPix.idPix}
                                onChange={(e) => handleChangeConfiguracaoPix('idPix', e.target.value)}
                            />

                            <Grid sx={{
                                opacity: '0.7',
                                borderTopStyle: 'dashed',
                                borderTopWidth: '1.5px',
                                borderTopColor: '#142A3066',
                                paddingBottom: '30px'
                            }} />

                            <Grid sx={{ gap: '15px', display: 'flex', flexDirection: 'column', paddingBottom: '30px' }}>
                                <Grid sx={{ gap: '15px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <Grid sx={{
                                        width: '30px',
                                        height: '30px',
                                        display: 'flex',
                                        borderRadius: '20px',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        bgcolor: validation == 0 ? '#FFC581' : validation == 1 ? '#E57373' : '#9DE200',
                                    }}>
                                        <FontAwesomeIcon
                                            icon={validation == 0 ? faExclamation : validation == 1 ? faClose : faCheck}
                                            style={{
                                                fontSize: 15,
                                                color: validation == 0 ? "#000000" : validation == 1 ? '#FFFFFF' : '#17333A'
                                            }}
                                        />
                                    </Grid>
                                    <Typography sx={{
                                        fontSize: '24px',
                                        color: '#142A30',
                                        fontWeight: 700
                                    }}>
                                        Certificado digital
                                    </Typography>
                                </Grid>
                                <Typography sx={{
                                    color: '#142A3099',
                                    paddingLeft: '45px'
                                }}>
                                    Mesmo certificado que e utilizado para emissão de notas fiscais no sistema da empresa.
                                </Typography>
                            </Grid>

                            <Grid sx={{
                                gap: '10px',
                                display: 'flex',
                                flexWrap: 'wrap',
                                justifyContent: 'space-between',
                                paddingBottom: '10px'
                            }}>
                                <OutlinedInput
                                    sx={{
                                        ...styles.customInputNumber,
                                        flexBasis: '5%',
                                        maxWidth: '49%',
                                    }}
                                    type="text"
                                    placeholder="+Certificado digital"
                                    onClick={() => setModalCertificadoDigital(true)}
                                    value={configuracaoPix.certificadoDigital?.name ? configuracaoPix.certificadoDigital?.name : ''}
                                />
                                <OutlinedInput
                                    sx={{
                                        ...styles.customInputNumber,
                                        flexBasis: '5%',
                                        maxWidth: '49%',
                                    }}
                                    type="text"
                                    placeholder="*Senha"
                                    value={configuracaoPix.senha}
                                    onChange={(e) => handleChangeConfiguracaoPix('senha', e.target.value)}
                                />
                            </Grid>
                            {!validating ? <Button
                                // onClick={() => setValidation(validation == 0 ? 1 : validation == 1 ? 2 : 0)}
                                onClick={() => checkFilePix()}
                                sx={{
                                    width: '100%',
                                    borderRadius: '30px',
                                    bgcolor: validation == 0 ? '#17333A' : validation == 1 ? '#E57373' : '#9DE200',
                                    '&:hover': { backgroundColor: validation == 0 ? '#17333A' : validation == 1 ? '#E57373' : '#9DE200' },
                                    '&:active': { backgroundColor: validation == 0 ? '#17333A' : validation == 1 ? '#E57373' : '#9DE200' }
                                }}
                            >
                                <Typography sx={{
                                    color: validation == 0 ? '#FFFFFF' : validation == 1 ? '#FFFFFF' : '#17333A',
                                    textTransform: 'none',
                                    padding: '3px',
                                    fontWeight: 600
                                }}>
                                    {validation == 0 ? 'Validar' : validation == 1 ? 'Tentar novamente' : 'Validado'}
                                </Typography>
                            </Button>
                                :
                                <Button
                                    disabled={true}
                                    sx={{
                                        width: '100%',
                                        borderRadius: '30px',
                                        bgcolor: '#17333A',
                                        '&:hover': { backgroundColor: '#17333A' },
                                        '&:active': { backgroundColor: '#17333A' }
                                    }}
                                >
                                    <Typography sx={{
                                        color: '#FFFFFF',
                                        textTransform: 'none',
                                        padding: '3px',
                                        fontWeight: 600
                                    }}>
                                        Validando
                                    </Typography>
                                    <img src={ArrowUpButton} style={{ width: '17.25px', height: '17.25px' }} />
                                </Button>}
                        </Grid>
                        : null}
                </Grid>


            </Grid>

        </Grid >
    )
}

export default Step2;

const styles = Stylesheet.create({
    customInputNumber: {
        flexGrow: 1,
        flexShrink: 1,
        // minWidth: '340px',
        borderRadius: '15px',
        '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
        },
        '& .MuiOutlinedInput-input': {
            display: 'flex',
            justifyContent: 'center',
            borderRadius: '30px',
            border: '2px solid #142A3066',
            cursor: 'pointer'
        },
        '& .MuiInputBase-input': {
            color: '#142A30',
            fontWeight: 'bold',
            fontSize: '14px',
            // fontSize: calcMimMax(12, 16, 100),
            padding: '12px',
            bgcolor: '#FEFDF999',
        },
        '& .MuiInputBase-input::placeholder': {
            fontSize: '14px',
            // fontSize: calcMimMax(12, 16, 100),
            color: '#142A3066',
            opacity: 1,
            fontWeight: 'bold'
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
    }
})
import { Button, Grid, Menu, MenuItem, Typography } from "@mui/material";
import Logo_parceiros from '../../assets/logos/logo.parceiros.png';
import { useState } from "react";

import companyLogo from "../../assets/icons/companyLogo.png"
import doubleArrowOff from "../../assets/icons/doubleArrowOff.png"
import separator from "../../assets/icons/separator.png"
import homeOff from "../../assets/icons/homeOff.png"
import bagOn from "../../assets/icons/bagOn.png"
import bagOff from "../../assets/icons/bagOff.png"
import calculatorOff from "../../assets/icons/calculatorOff.png"
import dolarOn from "../../assets/icons/dolarOn.png"
import dolarOff from "../../assets/icons/dolarOff.png"
import reportOff from "../../assets/icons/reportOff.png"
import productOff from "../../assets/icons/productOff.png"
import marketingOn from "../../assets/icons/funnelOn.png"
import marketingOff from "../../assets/icons/funnelOff.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-regular-svg-icons";
import { faChevronDown, faUser } from "@fortawesome/free-solid-svg-icons";
import ModalAccess from "../../PrimeiroAcesso/Modals/ModalAccess";
import UserGroup from '../../assets/icons/userGroup.png';
import GearMenu from '../../assets/icons/gearMenu.png';
import CloseMenu from '../../assets/icons/closeMenu.png';

const MenuOnTop = ({ calcMimMax, screenWidth }) => {

    const [modalAlertAccess, setModalAlertAccess] = useState(false);
    const [menuInfo] = useState([
        { name: 'Início', icon: homeOff },
        { name: 'XLoja', icon: bagOff },
        { name: 'Calculadora', icon: calculatorOff },
        { name: 'Recebíveis', icon: dolarOff },
        { name: 'Relatórios', icon: reportOff },
        { name: 'Produtos', icon: productOff },
        { name: 'Marketing', icon: marketingOff }
    ])

    const menuItens = [
        { icon: UserGroup, title: 'Contas', subTitle1: 'Gerencia acessos ao Xulis', subTitle2: 'Parceiros' },
        { icon: GearMenu, title: 'Configurações', subTitle1: 'Acesse todas as', subTitle2: 'configurações disponíveis' },
    ]

    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    }

    return (
        <Grid sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            bgcolor: '#142A30',
            padding: screenWidth <= 1400 ? `${calcMimMax(25, 30, 94)}px ${calcMimMax(11, 100, 94)}px` : `${calcMimMax(11, 30, 32)}px ${calcMimMax(11, 100, 14)}px`,
        }}>

            <ModalAccess modal={modalAlertAccess} close={() => setModalAlertAccess(false)} />

            <Grid sx={{
                width: '100%',
                display: 'flex',
                paddingBottom: '30px',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderBottom: '1px solid #5A8292'
            }}>
                <Grid>
                    <img src={Logo_parceiros} alt="Logo Parceiros" style={{ width: 150, height: 'auto', }} />
                </Grid>

                <Grid sx={{
                    display: 'flex',
                    gap: '26px',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}>

                    <Grid sx={{
                        gap: '20px',
                        // width: '339px',
                        height: '52px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: '#2F4650',
                        padding: '11px 13px',
                        borderRadius: '30px'
                    }}>
                        <Grid sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '15px'
                        }}>
                            <Grid sx={{
                                width: '30px',
                                height: '30px',
                                bgcolor: '#17333A',
                                borderRadius: '11px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <img src={companyLogo} alt="Logo Parceiros"
                                    style={{
                                        width: 14,
                                        height: 'auto',
                                    }}
                                />
                            </Grid>
                            <Grid>
                                <Typography sx={{
                                    fontWeight: 700,
                                    fontSize: '10px',
                                    color: '#7998A3',
                                    textAlign: 'start',
                                }}>
                                    -
                                </Typography>
                                <Typography sx={{
                                    fontWeight: 200,
                                    fontSize: '10px',
                                    color: '#7998A3',
                                    textAlign: 'start',
                                }}>
                                    --.---.---/-------
                                </Typography>
                            </Grid>
                        </Grid>
                        <Button
                            // onClick={() => setModal(true)}
                            sx={{
                                gap: '10px',
                                padding: '0px 13px',
                                height: '26px',
                                display: 'flex',
                                bgcolor: '#17333A',
                                borderRadius: '15px',
                                flexDirection: 'row',
                                textTransform: 'none',
                                justifyContent: 'center',
                            }}>

                            <img src={doubleArrowOff} alt="Logo Parceiros" style={{ width: 12, height: 'auto' }} />

                            <Grid>
                                <Typography sx={{ color: '#7998A3', fontSize: '10px' }}>Trocar de estabelecimento</Typography>
                            </Grid>
                        </Button>
                    </Grid>

                    <img src={separator} alt="separador" style={{ height: 27 }} />

                    <Grid sx={{ display: 'flex', flexDirection: 'row', gap: '7px', }}>

                        <Grid sx={{
                            width: '52px',
                            height: '52px',
                            bgcolor: '#2F4650',
                            borderRadius: '55px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <Grid sx={{
                                width: '22px',
                                height: '22px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                position: 'relative'
                            }}>
                                <Grid sx={{
                                    width: '11px',
                                    height: '11px',
                                    borderRadius: '20px',
                                    bgcolor: '#9DE200',
                                    position: 'absolute',
                                    border: '1px solid #2F4650',
                                    top: -1,
                                    right: 2.6
                                }} />
                                <FontAwesomeIcon icon={faBell} style={{ fontSize: 20, color: "#FFFFFF" }} />
                            </Grid>
                        </Grid>

                        {/* <Grid sx={{
                            width: '249px',
                            height: '52px',
                            bgcolor: '#2F4650',
                            borderRadius: '55px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            padding: '6px'
                        }}>
                            <Grid sx={{
                                gap: '10px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <Grid sx={{
                                    width: '36px',
                                    height: '36px',
                                    display: 'flex',
                                    borderRadius: '40px',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    border: '7px solid #FFFFFF'
                                }}>
                                    <FontAwesomeIcon icon={faUser} style={{ fontSize: 15, color: "#FFFFFF", backgroundColor: '#17333A', border: '5px solid #17333A', borderRadius: '20px', }} />
                                </Grid>

                                <Grid>
                                    <Typography sx={{ textAlign: 'start', fontSize: '14px', fontWeight: 600, color: '#FFFFFF' }}>Grupo Schueng</Typography>
                                    <Typography sx={{ textAlign: 'start', fontSize: '13px', color: '#FFFFFF' }}>Rubens Schueng Netto</Typography>
                                </Grid>
                            </Grid>

                            <Grid sx={{
                                width: '22px',
                                height: '22px',
                                border: '1px solid #FFFFFF',
                                backgroundColor: '#0D2128',
                                borderRadius: '40px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginRight: '4px'
                            }}>
                                <FontAwesomeIcon icon={faChevronDown}
                                    style={{ fontSize: 8, color: "#FFFFFF", backgroundColor: '#0D2128', border: '5px solid #0D2128', borderRadius: '20px' }} />
                            </Grid>

                        </Grid> */}

                        <Button
                            id="basic-button"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                            sx={{ textTransform: 'none', padding: 0 }}
                        >
                            <Grid sx={{
                                width: '249px',
                                height: '52px',
                                bgcolor: '#2F4650',
                                borderRadius: '55px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                padding: '6px'
                            }}>
                                <Grid sx={{
                                    gap: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
                                    <Grid sx={{
                                        width: '36px',
                                        height: '36px',
                                        display: 'flex',
                                        borderRadius: '40px',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        border: '7px solid #FFFFFF'
                                    }}>
                                        <FontAwesomeIcon icon={faUser} style={{ fontSize: 15, color: "#FFFFFF", backgroundColor: '#17333A', border: '5px solid #17333A', borderRadius: '20px', }} />
                                    </Grid>

                                    <Grid>
                                        <Typography sx={{ textAlign: 'start', fontSize: '14px', fontWeight: 600, color: '#FFFFFF' }}>Grupo Schueng</Typography>
                                        <Typography sx={{ textAlign: 'start', fontSize: '13px', color: '#FFFFFF' }}>Rubens Schueng Netto</Typography>
                                    </Grid>
                                </Grid>

                                <Grid sx={{
                                    width: '22px',
                                    height: '22px',
                                    border: '1px solid #FFFFFF',
                                    backgroundColor: '#0D2128',
                                    borderRadius: '40px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginRight: '4px'
                                }}>
                                    <FontAwesomeIcon icon={faChevronDown}
                                        style={{ fontSize: 8, color: "#FFFFFF", backgroundColor: '#0D2128', border: '5px solid #0D2128', borderRadius: '20px' }} />
                                </Grid>

                            </Grid>
                        </Button>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            sx={{
                                marginTop: '15px',
                                '& .MuiMenu-paper': {
                                    borderRadius: '30px',
                                    // bgcolor:'red'
                                }
                            }}
                        // MenuListProps={{
                        //     'aria-labelledby': 'basic-button',
                        // }}
                        >
                            <Grid sx={{ width: '249px', }}>
                                <Typography sx={{ fontSize: '15px', color: '#142A30', fontWeight: 700, padding: '12px 20px' }}>Mais opções</Typography>
                                <Grid sx={{
                                    opacity: '0.7',
                                    borderTopStyle: 'solid',
                                    borderTopWidth: '2px',
                                    borderTopColor: '#142A3099',
                                }} />
                                <Grid>
                                    {menuItens.map((i) => (
                                        <MenuItem onClick={handleClose}>
                                            <Grid sx={{
                                                gap: '10px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}>
                                                <Grid>
                                                    <img src={i.icon}
                                                        style={{
                                                            width: '30px',
                                                            height: '30px',
                                                            borderRadius: '20px',
                                                            padding: 0
                                                        }} />
                                                </Grid>
                                                <Grid>
                                                    <Typography sx={{ textAlign: 'start', fontSize: '14px', fontWeight: 600, color: '#142A30' }}>
                                                        {i.title}
                                                    </Typography>
                                                    <Typography sx={{ width: '12ch', textAlign: 'start', fontSize: '13px', color: '#142A3099' }}>
                                                        {i.subTitle1}
                                                    </Typography>
                                                    <Typography sx={{ width: '12ch', textAlign: 'start', fontSize: '13px', color: '#142A3099' }}>
                                                        {i.subTitle2}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </MenuItem>
                                    ))}
                                    <MenuItem onClick={handleClose}>
                                        <Grid sx={{
                                            gap: '10px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}>
                                            <Grid>
                                                <img src={CloseMenu}
                                                    style={{
                                                        width: '30px',
                                                        height: '30px',
                                                        borderRadius: '20px',
                                                        padding: 0
                                                    }} />
                                            </Grid>
                                            <Typography sx={{ textAlign: 'start', fontSize: '14px', fontWeight: 600, color: '#9E4848' }}>
                                                Sair
                                            </Typography>
                                        </Grid>
                                    </MenuItem>
                                </Grid>
                            </Grid>
                        </Menu>


                    </Grid>
                </Grid>
            </Grid>

            <Grid sx={{
                width: '100%',
                display: 'flex',
                // maxWidth: '1240px',
                paddingTop: '30px',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}>
                {menuInfo.map((i) => (
                    <Grid
                        onClick={() => setModalAlertAccess(true)}
                        sx={{
                            // width:'122px',
                            // height:'30px',
                            gap: '12px',
                            padding: '0px 14px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer'
                        }}>
                        <Grid sx={{
                            width: '30px', height: '30px', bgcolor: '#0D2128', borderRadius: '11px', display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                            <img src={i.icon} alt="Logo Parceiros" style={{ width: 14, height: 'auto' }} />
                        </Grid>
                        <Typography sx={{
                            fontWeight: 700,
                            fontSize: '12px',
                            color: '#2F4650',
                            textAlign: 'center',
                        }}>
                            {i.name}
                        </Typography>
                    </Grid>
                ))}
            </Grid>

        </Grid >
    )
}

export default MenuOnTop;
import { useState } from 'react';
import Stylesheet from 'reactjs-stylesheet';
import closeCircle from '../../assets/icons/closeCircle.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { Modal, Grid, Typography, Button, Switch, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';

function ModalHorarioComercial({ modal, close, file, setFile, fieldName }) {

    const [selectedButton, setSelectedButton] = useState(0);
    const [expanded, setExpanded] = useState(false);

    const [week, setWeek] = useState({
        name: '',
        horario: [
            { name: 'Domingo', startIn: null, endsIn: null },
            { name: 'Segunda-feira', startIn: null, endsIn: null },
            { name: 'Terça-feira', startIn: null, endsIn: null },
            { name: 'Quarta-feira', startIn: null, endsIn: null },
            { name: 'Quinta-feira', startIn: null, endsIn: null },
            { name: 'Sexta-feira', startIn: null, endsIn: null },
            { name: 'Sábado', startIn: null, endsIn: null },
        ]
    });

    const changeTipe = (tipe) => {
        setSelectedButton(tipe);
        setExpanded(false);

        if (tipe == 1) {
            setWeek({
                name: 'Configurado - Aberto em horário selecionado',
                horario: [
                    { name: 'Domingo', startIn: null, endsIn: null },
                    { name: 'Segunda-feira', startIn: null, endsIn: null },
                    { name: 'Terça-feira', startIn: null, endsIn: null },
                    { name: 'Quarta-feira', startIn: null, endsIn: null },
                    { name: 'Quinta-feira', startIn: null, endsIn: null },
                    { name: 'Sexta-feira', startIn: null, endsIn: null },
                    { name: 'Sábado', startIn: null, endsIn: null },
                ]
            })
        } else if (tipe == 2) {
            setWeek({
                name: 'Configurado - Aberto 24h',
                products: []
            })
        }
    };


    const handleTimeChange = (value, index, field) => {
        const updatedWeek = [...week.horario];
        updatedWeek[index][field] = value;

        setWeek({
            ...week,
            horario: updatedWeek 
        });
    };


    // Gera uma lista de horários em formato "HH:MM"
    const generateTimeOptions = () => {
        const times = [];
        for (let hour = 0; hour < 24; hour++) {
            for (let minute = 0; minute < 60; minute += 30) {
                const formattedHour = hour.toString().padStart(2, '0');
                const formattedMinute = minute.toString().padStart(2, '0');
                times.push(`${formattedHour}:${formattedMinute}`);
            }
        }
        return times;
    };

    const timeOptions = generateTimeOptions();

    const send = () => {
        setFile(fieldName, week);
        close();
    };

    return (
        <Modal open={modal} onClose={close}>
            <Grid
                sx={{
                    width: '100%',
                    maxWidth: '564px',
                    top: '50%',
                    left: '50%',
                    boxShadow: 24,
                    border: 'none',
                    bgcolor: '#F0EFEB',
                    padding: '46px 49px',
                    borderRadius: '20px',
                    position: 'absolute',
                    transform: 'translate(-50%, -50%)'
                }}
            >
                <Grid sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography sx={{ fontSize: '24px', color: '#142A30', fontWeight: 700 }}>
                        Horário comercial
                    </Typography>
                    <img
                        src={closeCircle}
                        onClick={close}
                        alt="Close"
                        style={{ width: '30px', height: '30px', cursor: 'pointer' }}
                    />
                </Grid>
                <Typography sx={{ width: '45ch', fontSize: '14px', color: '#142A3099', paddingBottom: '35px' }}>
                    Por favor, informe abaixo o horário de funcionamento e os dias da semana em que o seu estabelecimento está aberto.
                </Typography>

                <Accordion
                    expanded={expanded} // Controla a expansão do Accordion
                    onChange={() => setExpanded(!expanded)}
                    sx={{
                        bgcolor: '#FEFDF9',
                        boxShadow: 'none',
                        position: 'static',
                        borderRadius: '30px !important',
                    }}
                >
                    <AccordionSummary
                        aria-controls="panel1-content"
                        id="panel1-header"
                        sx={{
                            padding: '0px',
                            height: '35px',
                            minHeight: '10px !important',
                        }}
                    >
                        <Grid
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: '10px 20px',
                                bgcolor: '#F0EFEB',
                                borderRadius: '30px',
                                border: '2px solid #FEFDF9',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '15px',
                                    color: '#142A30',
                                    fontWeight: 700,
                                }}
                            >
                                Horário
                            </Typography>
                            <Grid sx={{
                                gap: '10px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}>
                                <Typography
                                    sx={{
                                        fontSize: '15px',
                                        color: '#142A3099',
                                    }}
                                >
                                    {selectedButton == 1 ? 'Aberto em horário selecionado' : 'Aberto 24h'}
                                </Typography>
                                {expanded ? <FontAwesomeIcon icon={faChevronDown} style={{ fontSize: 15, color: "#142A3099" }} />
                                    : <FontAwesomeIcon icon={faChevronUp} style={{ fontSize: 15, color: "#142A3099" }} />}
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails
                        onClick={() => changeTipe(1)}
                        sx={{ padding: '8px 16px 0px', cursor: 'pointer' }}
                    >
                        <Grid
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                padding: '0px 6px',
                                marginTop: '17px',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '15px',
                                    color: '#142A3099',
                                }}
                            >
                                Aberto em horário selecionado
                            </Typography>
                            <FontAwesomeIcon icon={faChevronRight} style={{ fontSize: 15, color: "#142A3099" }} />
                        </Grid>
                    </AccordionDetails>
                    <AccordionDetails
                        onClick={() => changeTipe(2)}
                        sx={{ cursor: 'pointer' }}
                    >
                        <Grid
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                padding: '0px 6px',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '15px',
                                    color: '#142A3099',
                                }}
                            >
                                Aberto 24h
                            </Typography>
                            <FontAwesomeIcon icon={faChevronRight} style={{ fontSize: 15, color: "#142A3099" }} />
                        </Grid>
                    </AccordionDetails>
                </Accordion>

                <Grid sx={{ paddingBottom: '35px', marginTop: '10px', }}>
                    {selectedButton == 1 && (
                        week.horario.map((i, index) => (
                            <Grid
                                key={i.name}
                                sx={{
                                    display: 'flex',
                                    bgcolor: '#FEFDF9',
                                    borderRadius: '30px',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    marginBottom: '10px',
                                    padding: '13px 20px',
                                }}
                            >
                                <Typography sx={{ width: '26%', fontSize: '15px', color: '#142A30', fontWeight: 700 }}>
                                    {i.name}
                                </Typography>
                                <select
                                    value={i.startIn}
                                    onChange={(e) => handleTimeChange(e.target.value, index, 'startIn')}
                                    style={{
                                        marginRight: '10px',
                                        padding: '5px 0px',
                                        border: 'none',
                                        fontSize: '15px',
                                        backgroundColor: '#FEFDF9',
                                        appearance: 'none',
                                        WebkitAppearance: 'none',
                                        fontWeight: 700,
                                        borderBottom: '1px solid #142A30'
                                    }}
                                >
                                    {timeOptions.map((time) => (
                                        <option key={time} value={time}>{time}</option>
                                    ))}
                                </select>

                                <select
                                    value={i.endsIn}
                                    onChange={(e) => handleTimeChange(e.target.value, index, 'endsIn')}
                                    style={{
                                        padding: '5px 0px',
                                        border: 'none',
                                        fontSize: '15px',
                                        backgroundColor: '#FEFDF9',
                                        appearance: 'none',
                                        WebkitAppearance: 'none',
                                        fontWeight: 700,
                                        borderBottom: '1px solid #142A30'

                                    }}
                                >
                                    {timeOptions.map((time) => (
                                        <option key={time} value={time}>{time}</option>
                                    ))}
                                </select>

                                <Switch sx={styles.switch} />
                            </Grid>
                        ))
                    )}
                </Grid>

                <Button
                    onClick={() => send()}
                    sx={{
                        width: '100%',
                        borderRadius: '30px',
                        bgcolor: '#17333A',
                        '&:hover': { backgroundColor: '#142A30' },
                        '&:active': { backgroundColor: '#142A30' }
                    }}
                >
                    <Typography sx={{ color: '#FFFFFF', textTransform: 'none', padding: '3px' }}>
                        Configurar
                    </Typography>
                </Button>
            </Grid>
        </Modal >
    );
}

export default ModalHorarioComercial;

const styles = Stylesheet.create({
    customInputText: {
        flexGrow: 1,
        flexShrink: 1,
        // minWidth: '340px',
        borderRadius: '15px',
        '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
        },
        '& .MuiOutlinedInput-input': {
            display: 'flex',
            justifyContent: 'center',
            borderRadius: '30px',
            // border: '2px solid #142A3066',
            cursor: 'pointer'
        },
        '& .MuiInputBase-input': {
            color: '#142A30',
            // fontWeight: 'bold',
            fontSize: '14px',
            // fontSize: calcMimMax(12, 16, 100),
            padding: '2px 10px',
            bgcolor: '#F0EFEB',
        },
        '& .MuiInputBase-input::placeholder': {
            fontSize: '14px',
            // fontSize: calcMimMax(12, 16, 100),
            color: '#142A3066',
            opacity: 1,
            // fontWeight: 'bold'
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
    },
    customInputNumber: {
        flexGrow: 1,
        flexShrink: 1,
        // minWidth: '340px',
        borderRadius: '15px',
        '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
        },
        '& .MuiOutlinedInput-input': {
            display: 'flex',
            justifyContent: 'center',
            borderRadius: '30px',
            // border: '2px solid #142A3066',
            cursor: 'pointer',
            textAlign: 'center'
        },
        '& .MuiInputBase-input': {
            color: '#142A30',
            // fontWeight: 'bold',
            fontSize: '14px',
            // fontSize: calcMimMax(12, 16, 100),
            padding: '2px 10px',
            bgcolor: '#F0EFEB',
        },
        '& .MuiInputBase-input::placeholder': {
            fontSize: '14px',
            // fontSize: calcMimMax(12, 16, 100),
            color: '#142A3066',
            opacity: 1,
            // fontWeight: 'bold'
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
    },
    customInputNumber2: {
        flexGrow: 1,
        flexShrink: 1,
        // minWidth: '340px',
        borderRadius: '15px',
        '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
        },
        '& .MuiOutlinedInput-input': {
            display: 'flex',
            justifyContent: 'center',
            borderRadius: '20px',
            // border: '2px solid #142A3066',
            cursor: 'pointer',
            textAlign: 'center',
            height: '54px',
        },
        '& .MuiInputBase-input': {
            color: '#142A30',
            // fontWeight: 'bold',
            fontSize: '14px',
            // fontSize: calcMimMax(12, 16, 100),
            padding: '2px 10px',
            bgcolor: '#F0EFEB',
        },
        '& .MuiInputBase-input::placeholder': {
            fontSize: '14px',
            // fontSize: calcMimMax(12, 16, 100),
            color: '#142A3066',
            opacity: 1,
            // fontWeight: 'bold'
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
    },
    switch: {
        width: 31,
        height: 16,
        padding: 0,
        display: 'flex',
        '& .MuiSwitch-switchBase': {
            padding: '2px',
            '&.Mui-checked': {
                transform: 'translateX(12px)',
                '& + .MuiSwitch-track': {
                    opacity: 1,
                    backgroundColor: '#7FA80033',
                },
                '& .MuiSwitch-thumb': {
                    backgroundColor: '#7FA800',
                },
            },
            '&.Mui-disabled': {
                '& + .MuiSwitch-track': {
                    backgroundColor: '#2F465040',
                },
            },
        },
        '& .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: '#2F465040',
            borderRadius: 13,
        },
        '& .MuiSwitch-thumb': {
            boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
            width: 12,
            height: 12,
            borderRadius: 6,
            backgroundColor: '#17333A',
        },
    }
})
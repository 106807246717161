import React from 'react';
import { Grid, Typography } from '@mui/material';
import { format, getDate, parseISO } from 'date-fns';

const calendarioMobile = ({ agora, allDays, toggleValue, selectedValues }) => {
  const week = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];
  const today = new Date();

  const getStatus = (i) => {
    if (i.status == '') {
      return 'Sem venda';
    } else if (i.data == format(today, 'yyyy-MM-dd') && agora < '15:10') {
      return 'A receber'
    } else {
      const dataDate = new Date(i.data);
      return dataDate <= today ? 'Pago' : 'A receber';
    }
  };

  return (
    <Grid container direction="column"
      sx={{

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
      <Grid container sx={{
        width: '95%',
        display: 'flex',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        justifyContent: 'space-around'
      }}>
        {week.map((dayName, index) => (
          <Grid item key={index}>
            <Typography align="center" sx={{ fontSize: '15px', color: '#17333A', height: '45px', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>{dayName}</Typography>
          </Grid>
        ))}
      </Grid>
      <Grid container sx={{
        width: '95%',
        height: '220px',
        display: 'flex',
        borderRadius: '10px',
        justifyContent: 'flex-start'
      }}>
        {allDays.map((i, index) => (
          <Grid item key={index}
            onClick={() => toggleValue(i)}
            sx={{
              display: 'flex',
              cursor: 'pointer',
              alignItems: 'center',
              justifyContent: 'center',
              width: `${100 / 7}%`,
              height: `${220 / (allDays.length >= 36 ? 6 : 5)}px`
            }}
          >
            {i.empty ? null :
              <>
                <Grid>
                  <Typography sx={{
                    width: '38px',
                    height: '38px',
                    display: 'flex',
                    fontSize: '14px',
                    fontWeight: 700,
                    borderRadius: '20px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: selectedValues.find(item => item.data === i.data && item.day === i.day) ? '1.5px solid #17333A' : null,
                    color: '#FFFFFF',
                    bgcolor:
                      getStatus(i) == 'Pago' && selectedValues.find(item => item.data === i.data && item.day === i.day) ? '#BCDF4B' :
                        getStatus(i) == 'Pago' ? '#9DE200' :
                          getStatus(i) == 'A receber' && selectedValues.find(item => item.data === i.data && item.day === i.day) ? '#FFC581' :
                            getStatus(i) == 'A receber' ? '#FFB74D' :
                              getStatus(i) == 'Sem venda' && selectedValues.find(item => item.data === i.data && item.day === i.day) ? '#F09292' :
                                '#E57373',
                  }}>
                    {i.day || getDate(parseISO(i.data))}
                  </Typography>
                </Grid>
              </>
            }
          </Grid>
        ))}
      </Grid>
    </Grid >
  );
};

export default calendarioMobile;

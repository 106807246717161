import styles from "./LoginAreaparceiros.module.css";
import img from './assets/imgs/bg.padrao.png'; // Tell webpack this JS file uses this image
import logo1 from './assets/logos/logo.xulis.name.png'; // Tell webpack this JS file uses this image
// import Input from "./Input";
import { useState, useEffect } from "react";
import axios from "axios";
import { Typography } from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'

const Swal = require('sweetalert2')

const LoginAreaparceiros = () => {

  const [isMobile, setIsMobile] = useState(false);
  const [meuIP, setMeuIP] = useState('');
  const [showPassword, setShowPassword] = useState(false);



  useEffect(() => {

    obterIP()

    updateDimensions();
    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);


  const obterIP = async () => {

    const response = await axios.get('https://geolocation-db.com/json/');
    setMeuIP(JSON.stringify(response.data));
  }

  const updateDimensions = () => {
    setIsMobile(window.innerWidth <= 800); // Defina o limite de largura para o modo mobile conforme necessário

  };


  const clickEntrar = async () => {

    var email = document.getElementById('email_id').value;
    var senha = document.getElementById('senha_id').value;

    if (email == '') {
      return false;
    }

    if (senha == '') {
      return false;
    }

    try {
      const form = new FormData();
      form.append("metodo", "ConsultaUsuarioParceiro");
      form.append("idempresa", "1350");
      form.append("token", "e84d7ad2-f0cc-11eb-b838-568a76eb275e");
      form.append("usuario", email);
      form.append("senha", senha);
      form.append("ip", meuIP);

      const options = {
        method: 'POST',
      };

      options.body = form;

      fetch('https://xuliz.com.br/painel/integracao/login.php?t=2', options)
        .then(response => response.json())
        .then(response => {
          tratarInfo(response)
        })
        .catch(err => {
          Swal.fire(
            '',
            'Aconteceu um erro e caso o problema persista, favor entrar em contato com suporte@xuliz.com.br',
            'error'
          )
        });

    } catch (error) {
      console.log(error)
    }

    //  location.href = './tela-home-areaparceiros'

  }


  const tratarInfo = async (dados) => {
    console.log(dados)
    try {

      if (dados.erro == "S") {
        Swal.fire(
          '',
          dados.mensagem,
          'warning'
        )

        return false;
      }


      await Swal.fire(
        'Bem vindo',
        dados.nome,
        'success'
      )

      await localStorage.setItem('login', JSON.stringify(dados))
      
      await localStorage.setItem('tipoPerfil', dados.idusuariotipo)



      location.href = './xloja'



    } catch (error) {
      console.log(error)
      Swal.fire(
        'Ops!',
        'Aconteceu um erro e caso o problema persista, favor entrar em contato com suporte@xuliz.com.br',
        'error'
      )
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      clickEntrar();
    }
  };

  useEffect(() => {
    const senhaInput = document.getElementById('senha_id');
    const emailInput = document.getElementById('email_id');
    if (senhaInput) {
      senhaInput.addEventListener('keypress', handleKeyPress);
    }
    if (emailInput) {
      emailInput.addEventListener('keypress', handleKeyPress);
    }

  }, []);

  if (isMobile == true) {
    return (
      <div style={{ display: 'flex', backgroundColor: '#17333A', width: '100vw', height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <img src={logo1} style={{ width: 130, marginBottom: 50 }} />
          
          <form style={{ width: '80%' }}>
            <div class="mb-3">
              <label style={{ color: "#FFFFFF" }} for="exampleInputEmail1" class="form-label">Usuário</label>
              <input type="email" class="form-control" id="email_id" aria-describedby="emailHelp" />
            </div>
            <div class="mb-3">
              <label style={{ color: "#FFFFFF" }} for="exampleInputPassword1" class="form-label">Senha</label>
              <input type="password" class="form-control" id="senha_id" />
            </div>

            <div class="d-grid gap-2" style={{ textAlign: '-webkit-center' }}>
              <button class="btn btn-primary" type="button"
                style={{ height: 50, backgroundColor: '#FFF8EA', borderColor: '#262626', color: '#17333A', marginTop: 40, fontWeight: '700' }}
                onClick={() => clickEntrar()}>
                Entrar
              </button>
              <a href="./esquecisenha"
                class="btn btn-primary" type="button"
                style={{ alignContent: 'center', height: 50, backgroundColor: '#17333A', borderColor: '#FFF8EA', color: '#FFF8EA', marginTop: 11, fontWeight: '700' }}>
                Esqueci minha senha
              </a>
              {/* <a href="./esquecisenha" style={{ color: '#FFFFFF' }}>Esqueci minha senha</a> */}
            </div>
          </form>
        </div>
      </div>
    )
  }


  if (isMobile == false) {
    return (
      <div style={{ flex: 1, display: 'flex', backgroundColor: '#17333A', height: '100vh' }}>
        <div style={{ width: '70%' }} id="home2">
          <img src={img} style={{ objectFit: 'cover', width: '100%', height: '100%' }} />
        </div>

        <div style={{ width: '30%', backgroundColor: '#17333A', display: "flex", padding: 50 }} id="home3">
          <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'center' }}>

            <div style={{ marginBottom: 40 }}>
              <img src={logo1} style={{ width: '130px', marginBottom: 50 }} />
              <Typography
                style={{
                  fontSize: 24,
                  color: '#FFFFFF',
                  fontWeight: 'bold',
                }}>
                Boas vidas ao
              </Typography>
              <Typography
                style={{
                  fontSize: 24,
                  color: '#FFFFFF',
                  fontWeight: 'bold',
                }}>
                Xulis Parceiros
              </Typography>
            </div>

            <form style={{ width: '100%', borderTop: "1px solid #9DE200" }}>
              <div className="mb-3 mt-5">
                <input style={{
                  borderRadius: 15,
                  height: 48
                }}
                  type="email"
                  className="form-control"
                  id="email_id"
                  aria-describedby="emailHelp"
                  placeholder="Digite seu usuário"
                />
              </div>
              <div className="mb-3" style={{ position: 'relative' }}>
                <input
                  style={{
                    borderRadius: 15,
                    paddingRight: '2.5rem',
                    height: 48
                  }}
                  type={showPassword ? "text" : "password"}
                  className="form-control"
                  id="senha_id"
                  placeholder="Digite sua senha"
                />
                <FontAwesomeIcon
                  icon={showPassword ? faEyeSlash : faEye}
                  style={{
                    fontSize: 16,
                    color: "#262626",
                    position: 'absolute',
                    right: 10,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    cursor: 'pointer'
                  }}
                  onClick={() => { setShowPassword(!showPassword) }}
                />
              </div>

              <div className="d-grid gap-2" style={{ textAlign: 'center' }}>
                <button
                  className="btn btn-primary"
                  type="button"
                  style={{
                    height: 50,
                    marginTop: 40,
                    borderRadius: 15,
                    color: '#17333A',
                    fontWeight: '700',
                    borderColor: '#FFF8EA',
                    backgroundColor: '#FFF8EA',
                  }}
                  onClick={clickEntrar}
                >
                  Entrar
                </button>
                <a
                  href="./esquecisenha"
                  className="btn btn-primary"
                  type="button"
                  style={{
                    height: 50,
                    marginTop: 11,
                    borderRadius: 15,
                    color: '#FFF8EA',
                    fontWeight: '700',
                    alignContent: 'center',
                    borderColor: '#FFF8EA',
                    backgroundColor: '#17333A',
                  }}
                >
                  Esqueci minha senha
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }

  return (<></>)
};

export default LoginAreaparceiros;

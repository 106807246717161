import bigPlus from '../../assets/icons/bigPlus.png';
import closeCircle from '../../assets/icons/closeCircle.png';
import Sicoob from '../../assets/bankLogos/Sicoob.png';
import { Modal, Grid, Typography, Button } from '@mui/material';
import { useState } from 'react';

function ConfiguracaoPix({ modal, close, set }) {

    const [selected, setSelected] = useState(0)

    return (
        <Modal open={modal} onClose={close}>
            <Grid
                sx={{
                    width: '100%',
                    maxWidth: '748px',
                    top: '50%',
                    left: '50%',
                    boxShadow: 24,
                    border: 'none',
                    bgcolor: '#F0EFEB',
                    padding: '46px 49px',
                    borderRadius: '20px',
                    position: 'absolute',
                    transform: 'translate(-50%, -50%)'
                }}
            >
                <Grid sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography sx={{ fontSize: '24px', color: '#142A30', fontWeight: 700 }}>
                        Qual instituição enviaremos o seu Pix?
                    </Typography>
                    <img
                        src={closeCircle}
                        onClick={close}
                        alt="Close"
                        style={{ width: '30px', height: '30px', cursor: 'pointer' }}
                    />
                </Grid>
                <Typography sx={{ width: '60ch', fontSize: '14px', color: '#142A3099', paddingBottom: '35px' }}>
                    Selecione a instituição bancária para enviarmos o seu Pix, de acordo com as taxas acordadas.
                </Typography>

                <Grid
                    onClick={() => setSelected(selected == 1 ? 0 : 1)}
                    sx={{
                        gap: '10px',
                        width: '100%',
                        bgcolor: selected == 1 ? '#5A8292' : '#FEFDF9',
                        borderRadius: '30px',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        marginBottom: '35px',
                        padding: '5px',
                        cursor: 'pointer'
                    }}>
                    <img
                        src={Sicoob}
                        onClick={close}
                        alt="Close"
                        style={{ width: '52px', height: '52px', cursor: 'pointer' }}
                    />
                    <Typography sx={{
                        color: selected == 1 ? '#FEFDF9' : '#142A30',
                        fontWeight: 700
                    }}>
                        Sicoob
                    </Typography>
                </Grid>

                <Button
                    onClick={() => { selected == 1 ? set('instituicao', 'Sicoob') : set('instituicao', ''); close() }}
                    sx={{
                        width: '100%',
                        borderRadius: '30px',
                        bgcolor: '#17333A',
                        '&:hover': { backgroundColor: '#142A30' },
                        '&:active': { backgroundColor: '#142A30' }
                    }}
                >
                    <Typography sx={{ color: '#FFFFFF', textTransform: 'none', padding: '3px' }}>
                        Escolher
                    </Typography>
                </Button>
            </Grid>
        </Modal >
    );
}

export default ConfiguracaoPix;

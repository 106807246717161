import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import bagOff from "../assets/icons/bagOff.png"
import bagOn from "../assets/icons/bagOn.png"
import marketingOn from "../assets/icons/funnelOn.png"
import marketingOff from "../assets/icons/funnelOff.png"
import dolarOn from "../assets/icons/dolarOn.png"
import dolarOff from "../assets/icons/dolarOff.png"
import config from "../assets/icons/config.png"
import sair from "../assets/icons/exit.png"

import { useEffect } from "react";
import logo_parceiros from '../assets/logos/logo.parceiros.png'; // Tell webpack this JS file uses this image
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse, faShoppingBag, faChartPie, faBox, faCircleDollarToSlot, faBullhorn, faQuestionCircle, faUsers, faBars, faSquareXmark, faXmark, faGear, faDollar, faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons'
import { useState } from "react";

const Menu = ({ page }) => {
  const navigate = useNavigate();
  const Swal = require('sweetalert2')

  const [pageRef, setPageRef] = useState(page);
  const [emailRef, setEmailRef] = useState('');
  const [usuario, setUsuario] = useState('');
  const [urlLogo, setUrlLogo] = useState('');
  const [openMenu, setOpenMenu] = useState(false);
  const [scrollEnabled, setScrollEnabled] = useState(true);
  const [idMarketinck, setidMarketinck] = useState('flex')
  const [configuracoes, setConfiguracoes] = useState('flex')




  const onGroupContainer13Click = useCallback(() => {

    localStorage.clear()
    navigate("/");
  }, [navigate]);

  useEffect(() => {

    getDados()

  }, []);



  const toggleScroll = (css) => {
    const body = document.querySelector('body');
    if (scrollEnabled) {
      body.style.overflow = css;//css  hidden
    } else {
      body.style.overflow = css; // auto
    }
    setScrollEnabled(!scrollEnabled);
  };



  const getDados = async () => {
    var dados = await localStorage.getItem('login')


    if (dados == null) {
      return false;
    }


    dados = JSON.parse(dados)
    var dadosX = dados;
    verificarNiveisLogin(dadosX)

    setEmailRef(dadosX.email)
    setUsuario(dadosX.empresa)
    setUrlLogo(dadosX.urllogo)
    verificarCDL(dadosX)
  }


  const verificarCDL = (dadosX) => {
    var idparceiro = dadosX.parceiros;

    // console.log('idparceiro',dadosX)

    if (idparceiro == null) {
      setidMarketinck('none')
      return false;
    }

    if (idparceiro.length == 0) {
      setidMarketinck('none')
      return false;
    }

    if (idparceiro[0] == null) {
      setidMarketinck('none')
      return false;
    }
  }

  const verificarNiveisLogin = async (dadosX) => {
    var idperfil = dadosX.idusuariotipo;



    if (idperfil == "7") {
      await sleep(50)



      setidMarketinck('none')
      setConfiguracoes('none')

    }



    if (idperfil == "8") {
      await sleep(50)

      //financeiro somente relatorios
      document.getElementById('realizar_vendaID').style.display = "none";
      document.getElementById('menu_marketing').style.display = "none";
      document.getElementById('menu_configuracoes').style.display = "none";

    }

  }

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }




  const clickAbrirMenu = (abrir) => {

    if (abrir == true) {
      toggleScroll('hidden');
    } else {
      toggleScroll('auto');
    }



    setOpenMenu(abrir)

  }


  const irPage = (page) => {
    location.href = './' + page
  }

  if (openMenu == false) {
    return (
      <>
        <div style={{ width: '100%', height: 70, backgroundColor: '#142A30', display: 'flex' }}>
          <div style={{ flex: 1, display: 'flex', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
            <FontAwesomeIcon icon={faBars} style={{ fontSize: 20, color: '#FFFFFF' }} onClick={() => { clickAbrirMenu(true); }} />
          </div>

          <div style={{ flex: 5, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
            <img src={logo_parceiros} style={{ width: "auto", height: 25 }} />
          </div>

          <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={urlLogo} style={{ width: "auto", width: 40, height: "auto", objectFit: "cover", borderRadius: 20 }} />
          </div>
        </div>
      </>
    )

  }


  if (openMenu == true) {

    return (
      <>
        <div style={{ position: 'fixed', top: 0, right: 0, padding: 20 }}>
          <FontAwesomeIcon icon={faXmark} style={{ fontSize: 35, color: '#7f7f7f' }} onClick={() => { clickAbrirMenu(false) }} />
        </div>

        <div style={{ width: '100%', height: '100vh', backgroundColor: '#142A30', padding: '20px' }}>

          <div style={{
            flex: 1,
            width: '100%',
            height: '100%',
            display: "flex",
            justifyContent: 'space-between',
            flexDirection: 'column',
            alignItems: 'center'
          }}>

            <div style={{ display: "flex", flex: 1, width: '100%', flexDirection: 'column' }}>

              <div style={{ display: "flex", height: 60, fontSize: '25px', justifyContent: 'left', alignItems: 'center', cursor: "pointer", width: 100 }}>
                <span style={{ paddingLeft: 10, fontWeight: 600, color: "#FFFFFF" }}>
                  Menu
                </span>
              </div>

              <div style={{ display: "flex", height: 40, justifyContent: 'left', alignItems: 'center', cursor: "pointer", width: 100 }} onClick={() => irPage('xloja')}>
                <img src={pageRef == "xloja" ? bagOn : bagOff} style={{ borderLeft: pageRef == "xloja" ? "2px solid #9DE200" : "0px", paddingLeft: 13, width: 32 }} />
                <span style={{ paddingLeft: 10, fontWeight: 600, color: pageRef == "xloja" ? "#FFFFFF" : '#5A829259' }}>
                  XLoja
                </span>
              </div>

              <div style={{ display: idMarketinck, height: 40, justifyContent: 'left', alignItems: 'center', cursor: "pointer", width: 100 }} onClick={() => irPage('marketing')} >
                <img src={pageRef == "marketing" ? marketingOn : marketingOff} style={{ borderLeft: pageRef == "marketing" ? "2px solid #9DE200" : "0px", paddingLeft: 13, width: 32 }} />
                <span style={{ paddingLeft: 10, fontWeight: '600', color: pageRef == "marketing" ? "#FFFFFF" : '#5A829259' }}>Marketing</span>
              </div>

              <div style={{ display: 'flex', height: 40, justifyContent: 'left', alignItems: 'center', cursor: "pointer", width: 100 }} onClick={() => irPage('recebiveis')} >
                <img src={pageRef == "recebiveis" ? dolarOn : dolarOff} style={{ borderLeft: pageRef == "recebiveis" ? "2px solid #9DE200" : "0px", paddingLeft: 13, width: 32 }} />
                <span style={{ paddingLeft: 10, fontWeight: '600', color: pageRef == "recebiveis" ? "#FFFFFF" : '#5A829259' }}>
                  Recebíveis
                </span>
              </div>
            </div>

            <div style={{ display: "flex", width: '100%', flexDirection: 'column' }}>
              <div style={{
                width: 150,
                height: 50,
                display: 'flex',
                cursor: "pointer",
                alignItems: 'center',
                padding:'0px 10px',
                justifyContent: 'space-between',
                borderRadius: '15px',
                border: '1px solid #9DE200'
              }} onClick={() => irPage('')} >
                {/* <img src={pageRef == "sair" ? sair : sair} style={{ borderLeft: pageRef == "sair" ? "2px solid #f1e2c4" : "0px", paddingLeft: 13, width: 32 }} /> */}
                <span style={{fontWeight: '600', color: "#FFFFFF" }}>
                  Sair
                </span>
                <FontAwesomeIcon icon={faArrowRightFromBracket} style={{ fontSize: 20, color: '#FFFFFF' }} />
              </div>
            </div>

          </div>

        </div>

      </>
    )
  }

}

export default Menu;

import Stylesheet from "reactjs-stylesheet";
import { Grid, OutlinedInput, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight, faSearch } from "@fortawesome/free-solid-svg-icons";

const Header = ({ }) => {

    return (
        <Grid sx={styles.gridHeader}>

            <Grid>
                <Typography sx={{ fontSize: '32px', color: '#142A30', fontWeight: 700 }}>
                    Recursos de marketing
                </Typography>
                <Typography sx={{ fontSize: '15px', color: '#142A3099' }}>
                    Use esses recursos para sua empresa alcançar um público mais amplo.
                </Typography>
            </Grid>

            {/* Vai ser usado futuramente */}
            {/* <Grid sx={styles.gridArrow}>

                <Grid sx={styles.boxArrow}>
                    <FontAwesomeIcon icon={faChevronLeft} style={styles.arrow} />
                </Grid>
                <Grid sx={styles.boxArrow}>
                    <FontAwesomeIcon icon={faChevronRight} style={styles.arrow} />
                </Grid>

                <Grid sx={styles.gridSearch}>
                    <FontAwesomeIcon icon={faSearch} style={styles.iconSearch} />
                    <OutlinedInput
                        sx={{
                            ...styles.customInputCod,
                            '& .MuiInputBase-input': {
                                color: '#142A30'
                            },
                            '& .MuiInputBase-input::placeholder': {
                                opacity: 1,
                                fontWeight: 600,
                                fontSize: '15px',
                                color: '#142A30',
                            },
                            width: '100%',
                            maxWidth: '280px'
                        }}
                        placeholder="Procure por um recurso"
                    // value={formatCurrency(valorBrutoFilter)}
                    // onChange={handleValorBruto}
                    />
                </Grid>

            </Grid> */}

        </Grid>
    );
};

export default Header;

const styles = Stylesheet.create({
    customInputCod: {
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
        }
    },
    gridHeader: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        paddingBottom: '28px',
        alignItems: 'flex-end',
        justifyContent: 'space-between'
    },
    gridArrow: {
        gap: '10px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    boxArrow: {
        width: '34px',
        display: 'flex',
        bgcolor: '#142A30',
        borderRadius: '10px',
        justifyContent: 'center'
    },
    arrow: {
        padding: '7px',
        color: "#FFFFFF"
    },
    gridSearch: {
        height: '35px',
        display: 'flex',
        bgcolor: '#FEFDF9',
        padding: '5px 10px',
        borderRadius: '20px',
        flexDirection: 'row',
        alignItems: 'center',
        border: '2px solid #142A30'
    },
    iconSearch: {
        width: '15px',
        height: '15px',
        color: "#142A30"
    }
});
import Stylesheet from "reactjs-stylesheet";
import {
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import waiting from '../assets/waiting.jpg';
import visa from '../assets/visa.png';
import master from '../assets/master.png';
import elo from '../assets/elo_debito.png';
import hipercard from '../assets/hipercard.png';
import Pix from '../assets/Pix.png';
import amex from '../assets/amex.png';
import flagXulis from '../assets/flags/flagXulis.png';
import { format } from "date-fns";

const Tabela = ({
    rows
}) => {

    const columnsComissão = [
        { status: 'status', label: 'Status' },
        { valor_liquido: 'valor_liquido', label: 'Valor líquido' },
        { valorcomissao: 'valorcomissao', label: 'Comissão' },
        { valor_taxa: 'valor_taxa', label: 'Valor da taxa/tarifa' },
        { cashback: 'Cashback', label: 'Cashback' },
        { valor_venda: 'valor_venda', label: 'Valor venda' },
        { forma_pagamento: 'forma_pagamento', label: 'Forma de Pagamento' },
        { bandeira: 'bandeira', label: 'Bandeira' },
        { dataHora: 'dataHora', label: 'Previsão de pagamento' },
        { dataHora: 'dataHora', label: 'Data/Hora' },
        { codigo_autorizacao: 'codigo_autorizacao', label: 'Código de Autorização' },
        { id: 'id', label: 'ID#' },
    ];

    const columns = [
        { status: 'status', label: 'Status' },
        { valor_liquido: 'valor_liquido', label: 'Valor líquido' },
        { valor_taxa: 'valor_taxa', label: 'Valor da taxa/tarifa' },
        { cashback: 'Cashback', label: 'Cashback' },
        { valor_venda: 'valor_venda', label: 'Valor venda' },
        { forma_pagamento: 'forma_pagamento', label: 'Forma de Pagamento' },
        { bandeira: 'bandeira', label: 'Bandeira' },
        { dataHora: 'dataHora', label: 'Previsão de pagamento' },
        { dataHora: 'dataHora', label: 'Data/Hora' },
        { codigo_autorizacao: 'codigo_autorizacao', label: 'Código de Autorização' },
        { id: 'id', label: 'ID#' },
    ];

    const verDetalhes = async (dados) => {
        let data = dados.dataHora.split(" ")[0];
        location.href = './detalhesvendas?id=' + dados.id + '&data=' + data;
    };

    const formatCurrency = (value, id) => {

        // Verifica se o valor é nulo ou indefinido
        if (value == null) {
            return 'R$ 0,00'; // Retorna um valor padrão
        }

        // Converte o valor em uma string, remove caracteres não numéricos e tenta convertê-lo em um número
        const numericValue = parseFloat(String(value).replace(/[^\d.-]/g, ''));

        // Verifica se o valor é um número válido
        if (!isNaN(numericValue)) {
            return new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL'
            }).format(numericValue);
        } else {
            // Retorna o valor original se não for um número válido
            return String(value);
        }
    };

    return (
        <Paper xs={12} elevation={0} sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer className="table-container" xs={6} sx={{
                maxHeight: 559,
                overflowY: 'auto',
                direction: 'rtl',
                '&::-webkit-scrollbar': {
                    width: '6px',
                    height: '6px',
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#5A8292',
                    borderRadius: '10px',
                    border: '1px solid transparent',
                    backgroundClip: 'content-box',
                },
                '&::-webkit-scrollbar-track': {
                    backgroundColor: '#F0EFEB',
                    borderRadius: '10px',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                    backgroundColor: '#555',
                },
            }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {(rows.some(row => parseFloat(row.valorcomissao.replace("R$", "").trim()) > 0) ? columnsComissão : columns).map((column) => (
                                <TableCell
                                    sx={{ fontSize: '15px', color: '#142A3099', textAlign: 'end' }}
                                    key={column.codigo_autorizacao}
                                    align={column.align}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow key={row.codigo_autorizacao}>
                                <TableCell component="th" scope="row">
                                    <Grid sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', alignItems: 'center', gap: '20px' }}>
                                        <Typography sx={styles.actionTable} onClick={() => { verDetalhes(row) }}>⋮</Typography>
                                        <Typography sx={styles.statusTable}>{row.status}</Typography>
                                    </Grid>
                                </TableCell>
                                <TableCell component="th" sx={[styles.textTable, { fontSize: '15px', textAlign: 'end', padding: '16px 5px' }]}>
                                    {formatCurrency(row.valor_liquido)}
                                </TableCell>
                                {/* {<TableCell component="th" sx={[styles.textTable, { fontSize: '15px', textAlign: 'end', padding: '16px 5px' }]}>
                                    {row.valorcomissao == '-' ? '0,00' : formatCurrency(row.valorcomissao)} -
                                </TableCell>} */}
                                {rows.some(row => parseFloat(row.valorcomissao.replace("R$", "").trim()) > 0) ? <TableCell component="th" sx={[styles.textTable, { fontSize: '15px', textAlign: 'end', padding: '16px 5px' }]}>
                                    {row.valorcomissao == '-' ? '0,00' : formatCurrency(row.valorcomissao)} -
                                </TableCell> : null}
                                <TableCell component="th" scope="row" sx={styles.taxaTable}>
                                    {row.valor_taxa == '-' ? '0,00' : formatCurrency(row.valor_taxa)} -
                                </TableCell>
                                <TableCell component="th" sx={[styles.textTable, { fontSize: '15px', textAlign: 'end', padding: '16px 5px' }]}>
                                    {row.cashback == '-' ? '0,00' : formatCurrency(row.cashback)} -
                                </TableCell>
                                <TableCell component="th" sx={[styles.textTable, { fontSize: '15px', textAlign: 'end', padding: '16px 5px' }]}>
                                    {formatCurrency(row.valor_venda, row.id)}
                                </TableCell>
                                <TableCell component="th" sx={[styles.textTable, { fontSize: '15px', textAlign: 'end', padding: '16px 16px' }]}>
                                    {row.forma_pagamento == "Xuliz" ? "Xulis" : row.forma_pagamento == "XULIZ" ? "Xulis" : row.forma_pagamento} {row.vendaparcela > 1 ? ` - ${row.vendaparcela}x` : ''}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.bandeira ? (
                                        <>
                                            {row.bandeira === 'XULIZ' || row.bandeira === 'Xuliz' ? (
                                                <img src={flagXulis} style={{ width: 50, borderRadius: 4 }} alt="Bandeira XULIZ" />
                                            ) : row.bandeira === 'Visa Crédito' || row.bandeira === 'Visa Débito' ? (
                                                <img src={visa} style={{ width: 50, borderRadius: 4 }} alt="Visa" />
                                            ) : row.bandeira === 'Master Crédito' || row.bandeira === 'Master Débito' ? (
                                                <img src={master} style={{ width: 50, borderRadius: 4 }} alt="MasterCard" />
                                            ) : row.bandeira === 'Elo Crédito' || row.bandeira === 'Elo Débito' ? (
                                                <img src={elo} style={{ width: 50, borderRadius: 4 }} alt="Elo" />
                                            ) : row.bandeira === 'Amex Crédito' || row.bandeira === 'Amex Débito' ? (
                                                <img src={amex} style={{ width: 50, borderRadius: 4 }} alt="American Express" />
                                            ) : row.bandeira === 'Hipercard Crédito' || row.bandeira === 'Hipercard Débito' || row.bandeira === 'Hiper Crédito' || row.bandeira === 'Hiper Débito' ? (
                                                <img src={hipercard} style={{ width: 50, borderRadius: 4 }} alt="Hipercard" />
                                            ) : row.bandeira === 'PIX' ? (
                                                <>
                                                    <img src={Pix} style={{ width: 50, borderRadius: 4 }} alt="Pix" />
                                                </>
                                            ) : (
                                                <p style={{ marginBottom: 0 }}>{row.bandeira}</p>
                                            )}
                                        </>
                                    ) : null}
                                </TableCell>
                                <TableCell component="th" scope="row" sx={[styles.textTable, { fontSize: '15px', textAlign: 'end', padding: '16px 16px' }]}>
                                    {format(row.prevPagamento, 'dd/MM/yyyy')}
                                </TableCell>
                                <TableCell component="th" scope="row" sx={[styles.textTable, { fontSize: '15px', textAlign: 'end', padding: '16px 16px' }]}>
                                    {row.dataHora}
                                </TableCell>
                                <TableCell component="th" scope="row" sx={[styles.textTable, { fontSize: '15px', textAlign: 'end', padding: '16px 16px' }]}>
                                    {row.codigo_autorizacao}
                                </TableCell>
                                <TableCell component="th" scope="row" sx={[styles.textTable, { fontSize: '15px', textAlign: 'end', padding: '16px 16px' }]}>
                                    {row.id}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

                {!rows[0]?.id && <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: 30 }}>
                    <img src={waiting} style={{ width: 50, height: 50 }} />
                </div>}

            </TableContainer>
        </Paper>
    );
};

export default Tabela;

const styles = Stylesheet.create({
    textTable: {
        color: '#142A30',
        fontWeight: 700,
        textAlign: 'end',
        marginBottom: 0,
    },
    taxaTable: {
        color: '#E95454',
        fontSize: '15px',
        fontWeight: 700,
        textAlign: 'end',
        marginBottom: 0,
    },
    statusTable: {
        color: '#7FA800',
        fontSize: '15px',
        fontWeight: 700,
        textAlign: 'end',
        marginBottom: 0,
    },
    actionTable: {
        fontSize: '18px',
        cursor: 'pointer',
    },
});

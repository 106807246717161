import { Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material"
import trash from '../assets/icons/trash.png'
import UpercaseName from "../globalFunctions/reduceName"

const Lista = ({ subMembrosList, excluirMembro, getInitials }) => {

    const tableMembros = (row, index) => {
        return (
            <TableRow key={index} sx={{ maxHeight: '20px' }}>
                <TableCell sx={{ border: 'none' }}>
                    <Grid sx={{
                        display: 'flex',
                        width: '25px',
                        height: '25px',
                        bgcolor: '#9DE200',
                        borderRadius: '20px',
                        alignItems: 'center',
                        justifyContent: 'space-evenly',
                    }}>
                        <Typography sx={{ fontSize: '15px', color: '#142A30', fontWeight: 600 }}>
                            {index + 1}
                        </Typography>
                    </Grid>
                </TableCell>
                <TableCell sx={{ border: 'none' }}>
                    {
                        row.urlfotoperfil ? <img src={row.urlfotoperfil} style={{ width: '45px', height: '45px', objectFit: 'cover', border: '2px solid #17333A', borderRadius: '50px' }} />
                            :
                            <Typography sx={{
                                width: '45px',
                                height: '45px',
                                display: 'flex',
                                fontWeight: 600,
                                color: '#FFFFFF',
                                bgcolor: '#17333A',
                                alignItems: 'center',
                                borderRadius: '50px',
                                justifyContent: 'center',
                                border: '2px solid #17333A',
                            }}>
                                {getInitials(row.nome)}
                            </Typography>
                    }
                </TableCell>
                <TableCell sx={{ border: 'none' }}>
                    <Typography sx={{ fontSize: '14px', color: '#142A3099' }}>ID</Typography>
                    <Typography sx={{ fontSize: '15px', color: '#142A30', fontWeight: 600 }}>
                        {row.idusuario ? row.idusuario : '--------'}
                    </Typography>
                </TableCell>
                <TableCell sx={{ border: 'none' }}>
                    <Typography sx={{ fontSize: '14px', color: '#142A3099' }}>Nome</Typography>
                    <Typography sx={{ fontSize: '15px', color: '#142A30', fontWeight: 600 }}>
                        {UpercaseName(row.nome)}
                    </Typography>
                </TableCell>
                <TableCell sx={{ border: 'none' }}>
                    <Typography sx={{ fontSize: '14px', color: '#142A3099' }}>Nº Celular</Typography>
                    <Typography sx={{ fontSize: '15px', color: '#142A30', fontWeight: 600 }}>
                        {row.celular}
                    </Typography>
                </TableCell>
                <TableCell sx={{ border: 'none' }}>
                    <Typography sx={{ fontSize: '14px', color: '#142A3099' }}>Situação</Typography>
                    <Typography sx={{ fontSize: '15px', color: row.situacao == "OK" ? '#7FA800' : '#FFB74D', fontWeight: 600 }}>
                        {row.situacao}
                    </Typography>
                </TableCell>
                <TableCell sx={{ border: 'none' }}>
                    <Button
                        sx={{
                            display: 'flex',
                            width: '40px',
                            height: '30px',
                            bgcolor: '#E57373',
                            borderRadius: '20px',
                            alignItems: 'center',
                            justifyContent: 'space-evenly',
                            '&:hover': {
                                backgroundColor: '#E57373'
                            },
                        }} onClick={() => excluirMembro(row.idempresaparceirousuario, row.situacao)}>
                        <img src={trash} style={{ width: '15px', height: 'auto' }} />
                    </Button>
                </TableCell>
            </TableRow>
        )
    }

    return (
        <Paper xs={12} elevation={0} sx={{ width: '100%', overflow: 'hidden', borderRadius: '20px' }} >
            <TableContainer
                sx={{

                    overflowX: 'auto',
                    boxShadow: 'none',
                    // direction: 'rtl', // Mantém a barra de rolagem à esquerda
                    '&::-webkit-scrollbar': {
                        width: '6px', // Largura da barra de rolagem
                        height: '6px', // Largura da barra de rolagem
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#5A8292', // Cor da barra
                        borderRadius: '10px', // Borda arredondada
                        border: '1px solid trasparent', // Espaço para adicionar efeito de "padding"
                        backgroundClip: 'content-box', // Para manter o border transparente
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: '#F0EFEB', // Cor do track (fundo da barra)
                        borderRadius: '10px',
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                        backgroundColor: '#555', // Cor ao passar o mouse sobre a barra
                    },
                }}>
                <Table stickyHeader aria-label="sticky table" sx={{ bgcolor: '#F0EFEB', minWidth: '940px' }}>
                    <TableBody>
                        {subMembrosList?.map((row, index) => (
                            row.dependentes?.length > 0 ?
                                row.dependentes.map((dependente, depIndex) => (
                                    tableMembros(dependente, depIndex)
                                ))
                                :
                                tableMembros(row, index)
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}

export default Lista;
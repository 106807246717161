import { Avatar, AvatarGroup, Button, Grid, OutlinedInput, Typography } from "@mui/material";
import Stylesheet from "reactjs-stylesheet";
import xulisMembros from '../assets/imgs/xulisMembros.png'
import xulisGirl from '../assets/imgs/xulisGirl.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const ContentOne = ({ isMobile, membros, subMembros, cashbackPlus, setCashbackPlusVenda, alterarCash }) => {
    return (
        <Grid sx={{ maxWidth: '716px', width: '100%', borderRadius: '20px' }}>

            <Grid sx={{
                bgcolor: '#F0EFEB',
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                borderRadius: '20px',
                marginBottom: '13px'
            }}>
                <Grid sx={{ padding: '25px 32px' }}>
                    <Grid sx={{ paddingBottom: '20px' }}>
                        <Typography sx={{ fontSize: '20px', color: '#142A30', fontWeight: 700 }}>Minha empresa é CDL</Typography>
                        <Typography sx={{ fontSize: '15px', color: '#142A3099' }}>Cadastre membros+ e submembros+ para que  eles possam ganhar cashback+ em todas as lojas parceiras CDL e também tenham um selo exclusivo no app.</Typography>
                    </Grid>
                    <Button sx={{
                        bgcolor: '#17333A',
                        padding: '8px 29px',
                        borderRadius: '20px',
                        '&:hover': {
                            backgroundColor: '#17333A'
                        },
                    }}
                        onClick={() => { location.href = './cadastrar_membros' }}>
                        <Typography sx={{ color: '#FFFFFF', textTransform: 'none', fontSize: '15px' }}>
                            Cadastrar Membro
                        </Typography>
                        <FontAwesomeIcon icon={faPlus} style={styles.arrow} />
                    </Button>
                </Grid>
                <img src={xulisMembros} style={{ objectFit: 'cover', width: isMobile ? '100%' : '48%', height: 'auto', borderTopRightRadius: '20px' }} />
            </Grid>

            <Grid sx={{
                justifyContent: 'space-between',
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                marginBottom: '13px',
                gap: isMobile ? '10px' : null
            }}>
                <Grid sx={{ width: isMobile ? '100%' : '48%', padding: '28px 32px', bgcolor: '#F0EFEB', display: 'flex', flexDirection: 'row', borderRadius: '20px', justifyContent: 'space-around', alignItems: 'center' }}>
                    <Typography sx={{ width: '10ch', fontWeight: 600 }}>
                        Membros+
                        cadastrados
                    </Typography>
                    <Typography sx={{ fontSize: '40px', fontWeight: 600, padding: '5px 40px', bgcolor: '#17333A', color: '#FFFFFF', borderRadius: '20px' }}>
                        {membros.length ? membros.length : 0}
                    </Typography>
                </Grid>
                <Grid sx={{ width: isMobile ? '100%' : '48%', padding: '28px 32px', bgcolor: '#F0EFEB', display: 'flex', flexDirection: 'row', borderRadius: '20px', justifyContent: 'space-around', alignItems: 'center' }}>
                    <Typography sx={{ width: '10ch', fontWeight: 600 }}>
                        Submembros+
                        cadastrados
                    </Typography>
                    <Typography sx={{ fontSize: '40px', fontWeight: 600, padding: '5px 40px', bgcolor: '#17333A', color: '#FFFFFF', borderRadius: '20px' }}>
                        {subMembros ? subMembros : 0}
                    </Typography>
                </Grid>
            </Grid>

            <Grid sx={{
                maxHeight: isMobile ? null : '262px',
                bgcolor: '#F0EFEB',
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                borderRadius: '20px'
                , marginBottom: '13px'
            }}>
                <img src={xulisGirl} style={{ objectFit: 'cover', width: isMobile ? '100%' : '48%', height: 'auto', borderTopRightRadius: '20px' }} />
                <Grid sx={{ padding: '25px 32px' }}>
                    <Grid sx={{ paddingBottom: '20px' }}>
                        <Typography sx={{ width: '18ch', fontSize: '20px', color: '#142A30', fontWeight: 700 }}>Configurar Cashback+ para os parceiros CDL</Typography>
                        <Typography sx={{ fontSize: '15px', color: '#142A3099' }}>Para fazer parte da comunidade CDL & Xuliz é necessário que sua empresa disponibilize cashback+ para os usuários permitidos.</Typography>
                    </Grid>

                    <Grid sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}>
                        <Grid sx={{ display: 'flex', flexDirection: 'row' }}>
                            <OutlinedInput
                                sx={{
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none'
                                    },
                                    '& .MuiInputBase-input': {
                                        height: '33px',
                                        padding: '0px',
                                        fontWeight: 600,
                                        color: '#17333A',
                                        marginRight: '4px',
                                        textAlign: 'center',
                                        borderRadius: '10px',
                                        backgroundColor: '#FFFFFF'
                                    },
                                    '& .MuiInputBase-input::placeholder': {
                                        opacity: 1,
                                        fontWeight: 600,
                                        fontSize: '15px',
                                        color: '#17333A'
                                    },
                                    maxWidth: '45px'
                                }}
                                placeholder="0"
                                value={cashbackPlus}
                                onChange={(event) => setCashbackPlusVenda(event.target.value)}
                            />
                            <Typography sx={{ color: '#17333A', textTransform: 'none', fontSize: '20px', fontWeight: 700 }}>
                                %
                            </Typography>
                        </Grid>

                        <Button sx={{
                            bgcolor: '#17333A',
                            padding: '10px 15px',
                            borderRadius: '20px',
                            '&:hover': {
                                backgroundColor: '#17333A'
                            },
                        }}
                            onClick={() => alterarCash()}>
                            <Typography sx={{ color: '#FFFFFF', textTransform: 'none', fontSize: '15px' }}>
                                Configurar
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Grid>

            <Grid>
                <Typography sx={{ fontSize: '20px', color: '#142A30', fontWeight: 700 }}>
                    Ver todos os Membros+
                </Typography>

                <Grid sx={{ border: '1px solid #7FA800', marginBottom: '16px' }} />

                <Grid sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingBottom: '35px' }}>
                    <Grid>
                        <Typography sx={{ width: '32ch', fontSize: '15px', color: '#142A3099' }}>
                            Gerencie os seus Membros+ como também os Submembros+ clicando abaixo.
                        </Typography>
                    </Grid>

                    {membros.length > 0 ? (
                        <AvatarGroup
                            max={4}
                            sx={{
                                alignItems: 'center',
                                '& .MuiAvatarGroup-avatar': {
                                    fontSize: '15px',
                                    fontWeight: 700,
                                    color: '#142A3099',
                                    bgcolor: '#FFFFFF',
                                },
                                '& .MuiAvatar-circular': {
                                    border: 'none'
                                },

                            }}
                            renderSurplus={(resto) => <span>+{resto}</span>}
                            total={membros.length + (membros.dependentes?.length || 0)}
                        >
                            {membros.map((i) => (
                                <>
                                    <Avatar
                                        sx={{
                                            bgcolor: '#17333A', width: '35px', height: '35px', marginLeft: '-20px !important'
                                        }}
                                        key={i.nome}
                                        alt={i.nome}
                                        src={i.urlfotoperfil || undefined}
                                    >
                                        {!i.urlfotoperfil ? i.nome.charAt(0) : null}
                                    </Avatar>
                                    {i.dependentes?.map((dependente) => (
                                        <Avatar
                                            sx={{
                                                bgcolor: '#17333A', width: '35px', height: '35px', marginLeft: '-20px !important'
                                            }}
                                            key={dependente.nome}
                                            alt={dependente.nome}
                                            src={dependente.urlfotoperfil || undefined}
                                        >
                                            {!dependente.urlfotoperfil ? dependente.nome.charAt(0) : null}
                                        </Avatar>
                                    ))}
                                </>
                            ))}
                        </AvatarGroup>
                    ) : null}
                </Grid>

                <Button sx={{
                    bgcolor: '#17333A',
                    padding: '10px 15px',
                    borderRadius: '20px',
                    '&:hover': {
                        backgroundColor: '#17333A'
                    },
                }}
                    onClick={() => { location.href = './membros' }}>
                    <Typography sx={{ color: '#FFFFFF', textTransform: 'none', fontSize: '15px' }}>
                        Ver agora
                    </Typography>
                </Button>
            </Grid>

        </Grid >
    );
};

export default ContentOne;

const styles = Stylesheet.create({
    arrow: {
        color: '#9DE200'
    }
})
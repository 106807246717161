import Stylesheet from "reactjs-stylesheet";
import { Button, Grid, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";


const Header = ({ isMobile }) => {

    return (
        <Grid sx={{ width: '100%' }}>
            <Button onClick={() => history.back()}>
                <FontAwesomeIcon
                    icon={faArrowLeft}
                    style={{ fontSize: '16px', color: "#999999", cursor: 'pointer', paddingRight: '3px' }}
                />
                <Typography style={{ fontSize: '13px', color: '#999999', textTransform: 'none' }}>
                    Voltar
                </Typography>
            </Button>
            <Grid style={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                alignItems: isMobile ? 'flex-start' : 'flex-end',
                justifyContent: 'space-between'
            }}>
                <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Grid sx={{
                        display: 'flex',
                        alignItems: 'self-start',
                        flexDirection: 'column',
                        paddingBottom: isMobile ? '20px' : null
                    }}>
                        <Typography style={{ fontSize: '32px', color: '#142A30', fontWeight: 700 }}>
                            Cadastro de Membro+
                        </Typography>
                        <Grid sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'row',
                            gap: '5px',
                            backgroundColor: '#9DE200',
                            padding: '5px 10px',
                            borderRadius: '10px'
                        }}>
                            <Typography style={{ fontSize: '10px', color: '#142A30' }}>
                                Marketing
                            </Typography>
                            <FontAwesomeIcon
                                icon={faChevronRight}
                                style={{ fontSize: '10px', color: "#142A30", cursor: 'pointer', paddingRight: '3px' }}
                            />
                            <Typography style={{ fontSize: '10px', color: '#142A30', fontWeight: 700 }}>
                                Cadastro Membro+
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Header;

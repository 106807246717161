import { useState } from 'react';
import bigPlus from '../../assets/icons/bigPlus.png';
import closeCircle from '../../assets/icons/closeCircle.png';
import fileIconCircle from '../../assets/icons/fileIconCircle.png';
import { Modal, Grid, Typography, Button } from '@mui/material';

function ModalQtdColaboradores({ modal, close, file, setFile, fieldName }) {

    const [selectedQtd, setSelectedQtd] = useState('')

    const [qtd, setQtd] = useState([
        { id: 0, qtd: '0-10' },
        { id: 1, qtd: '10-50' },
        { id: 2, qtd: '50-100' },
        { id: 3, qtd: '100-500' },
        { id: 4, qtd: '500-1000' },
        { id: 5, qtd: '+1000' },
    ])

    const sendSelectTipe = () => {
        setFile(fieldName, selectedQtd)
        close();
    };

    return (
        <Modal open={modal} onClose={close}>
            <Grid
                sx={{
                    width: '100%',
                    maxWidth: '564px',
                    top: '50%',
                    left: '50%',
                    boxShadow: 24,
                    border: 'none',
                    bgcolor: '#F0EFEB',
                    padding: '46px 49px',
                    borderRadius: '20px',
                    position: 'absolute',
                    transform: 'translate(-50%, -50%)'
                }}
            >
                <Grid sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '35px' }}>
                    <Typography sx={{ fontSize: '24px', color: '#142A30', fontWeight: 700 }}>
                        Quantidade de colaboradores
                    </Typography>
                    <img
                        src={closeCircle}
                        onClick={close}
                        alt="Close"
                        style={{ width: '30px', height: '30px', cursor: 'pointer' }}
                    />
                </Grid>

                <Grid sx={{
                    gap: '15px',
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    paddingBottom: '35px',
                    justifyContent: 'space-between'
                }}>
                    {qtd.map((i) => (
                        <Button
                            onClick={() => setSelectedQtd(i)}
                            sx={{
                                width: '48%',
                                bgcolor: selectedQtd.id != i.id ? '#FEFDF9' : '#17333A',
                                borderRadius: '30px',
                                border: `2px solid  ${selectedQtd.id != i.id ? '#142A3066' : '#FFFFFF'}`,
                                '&:hover': { backgroundColor: selectedQtd.id != i.id ? '#FEFDF9' : '#17333A' },
                                '&:active': { backgroundColor: selectedQtd.id != i.id ? '#FEFDF9' : '#17333A' }
                            }}
                        >
                            <Typography sx={{ color: selectedQtd.id != i.id ? '#142A3066' : '#FFFFFF', fontWeight: 700, textTransform: 'none', padding: '3px' }}>
                                {i.qtd}
                            </Typography>
                        </Button>))}
                </Grid>

                <Button
                    onClick={() => sendSelectTipe()}
                    sx={{
                        width: '100%',
                        borderRadius: '30px',
                        bgcolor: '#17333A',
                        '&:hover': { backgroundColor: '#142A30' },
                        '&:active': { backgroundColor: '#142A30' }
                    }}
                >
                    <Typography sx={{ color: '#FFFFFF', textTransform: 'none', padding: '3px' }}>
                        Configurar
                    </Typography>
                </Button>
            </Grid>
        </Modal >
    );
}

export default ModalQtdColaboradores;

import { Button, Grid, OutlinedInput, TextField, Typography } from "@mui/material";
import Stylesheet from "reactjs-stylesheet";
import xulisGirl from "../assets/imgs/xulisGirl.png";
import warning from "../assets/icons/warning.png";
import xulisCDL from "../assets/icons/xulisCDL.png";
import xulisCDLError from "../assets/icons/xulisCDLError.png";
import xulisPlus from "../assets/icons/xulisPlus.png";
import xulisExclamacao from "../assets/icons/xulisExclamacao.png";
import user from "../assets/icons/user.png";
import Lista from "./Lista";
import InputMask from 'react-input-mask';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import UpercaseName from "../globalFunctions/reduceName";

const Content = ({
    membro,
    setMembro,
    salvarMembro,
    screenWidth,
    subMembrosList,
    relacaoEmpresa,
    relacaoMembro,
    subMembro,
    setSubMembro,
    adicionarSubmembro,
    excluirMembro,
    getInitials
}) => {

    function calcMimMax(min, max, divide) {
        const calculated = screenWidth / divide;
        s
        if (calculated > min && calculated < max) {
            return calculated;
        } else if (calculated < min) {
            return min;
        } else if (calculated > max) {
            return max;
        }

        return 16;
    }

    return (
        <Grid sx={{ 
            // width: '790px' 
            width: '100%' 
            }}>

            <Grid sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: screenWidth <= 1020 ? 'column' : 'row',
                gap: screenWidth <= 1150 ? '10px' : null
            }}>
                <Grid sx={{
                    width: screenWidth <= 1020 ? '100%' : screenWidth <= 1150 ? '69%' : '70%',
                    display: 'flex',
                    flexWrap: 'wrap',
                    bgcolor: '#F0EFEB',
                    justifyContent: 'space-between',
                    padding: '25px',
                    borderRadius: '20px',
                    gap: '10px'
                }}>
                    <InputMask
                        maskChar={null}
                        mask={"(99) 99999-9999"}
                        value={membro.celular}
                        onChange={(i) => setMembro({ celular: i.target.value })}
                    >
                        {() => (
                            <OutlinedInput
                                placeholder="Número de celular"
                                type="text"
                                sx={{
                                    ...styles.customInputNumber,
                                    '& .MuiOutlinedInput-input': {
                                        display: 'flex',
                                        justifyContent: 'center',
                                        borderRadius: '20px',
                                        border: '2px solid #142A3066'
                                    },
                                    '& .MuiInputBase-input': {
                                        color: '#142A30',
                                        fontWeight: 'bold',
                                        fontSize: calcMimMax(12, 16, 100),
                                        padding: '12px',
                                        bgcolor: '#FEFDF9',
                                    },
                                    '& .MuiInputBase-input::placeholder': {
                                        fontSize: calcMimMax(12, 16, 100),
                                        color: '#142A3066',
                                        opacity: 1,
                                        fontWeight: 'bold',
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none',
                                    },
                                }}
                            />
                        )}
                    </InputMask>
                    <TextField
                        select
                        variant="outlined"
                        sx={{
                            ...styles.customInputNumber,
                            '& .MuiOutlinedInput-input': {
                                display: 'flex',
                                justifyContent: 'center',
                                borderRadius: '20px',
                                border: '2px solid #142A3066'
                            },
                            '& .MuiInputBase-input': {
                                color: '#142A30',
                                fontWeight: 'bold',
                                fontSize: calcMimMax(12, 16, 100),
                                padding: '12px',
                                bgcolor: '#FEFDF9',
                            },
                            '& .MuiInputBase-input::placeholder': {
                                fontSize: calcMimMax(12, 16, 100),
                                color: '#142A3066',
                                opacity: 1,
                                fontWeight: 'bold',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: 'none',
                            },
                            '&:hover': {
                                borderRadius: '20px',
                            },
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        value={membro.relacaoEmpresa}
                        onChange={(i) => setMembro((prevState) => ({ ...prevState, relacaoEmpresa: i.target.value }))}
                    >
                        {relacaoEmpresa?.map((option, index) => (
                            <option key={index.iddependentetipo} value={option.iddependentetipo}>
                                {option.dependentetipo}
                            </option>
                        ))}
                    </TextField>
                    <OutlinedInput
                        sx={{
                            ...styles.customInputNumber,
                            '& .MuiOutlinedInput-input': {
                                display: 'flex',
                                justifyContent: 'center',
                                borderRadius: '20px',
                                border: '2px solid #142A3066'
                            },
                            '& .MuiInputBase-input': {
                                color: '#142A30',
                                fontWeight: 'bold',
                                fontSize: calcMimMax(12, 16, 100),
                                padding: '12px',
                                bgcolor: '#FEFDF9',
                            },
                            '& .MuiInputBase-input::placeholder': {
                                fontSize: calcMimMax(12, 16, 100),
                                color: '#142A3066',
                                opacity: 1,
                                fontWeight: 'bold',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: 'none',
                            },
                        }}
                        type="text"
                        placeholder="Nome"
                        value={membro.nome}
                        onChange={(i) => setMembro((prevState) => ({ ...prevState, nome: i.target.value }))}
                    />
                </Grid>

                <Grid sx={{
                    width: screenWidth <= 1020 ? '100%' : screenWidth <= 1020 ? '30%' : '29%',
                    display: 'flex',
                    flexDirection: 'row',
                    bgcolor: '#F0EFEB',
                    justifyContent: screenWidth <= 1020 ? 'flex-start' : 'space-around',
                    alignItems: 'center',
                    padding: '25px',
                    borderRadius: '20px',
                    gap: '10px'
                }}>
                    {membro.erro == 'S' ?
                        <Grid sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px'
                        }}>
                            <img src={xulisCDLError}
                                style={{
                                    objectFit: 'cover',
                                    width: calcMimMax(65, 146, screenWidth >= 1400 ? 13 : 18),
                                    height: calcMimMax(55, 116, screenWidth >= 1400 ? 11 : 19)
                                }} />
                            <Grid>
                                <Typography sx={{ fontSize: screenWidth <= 1020 ? '20px' : calcMimMax(15, 20, 94), color: '#142A30', fontWeight: 700 }}>Usuário não existe</Typography>
                                <Typography sx={{ fontSize: screenWidth <= 1020 ? '15px' : calcMimMax(11, 15, 126), color: '#142A3099' }}>
                                    Mas não se preocupe, enviaremos um SMS para ele criar uma conta Xulis.
                                </Typography>
                            </Grid>
                        </Grid>
                        :
                        membro.idusuario ?
                            <>
                                {membro.urlfotoperfil ? <img src={membro.urlfotoperfil} style={{ objectFit: 'cover', width: calcMimMax(80, 116, 16), height: calcMimMax(80, 116, 16), borderRadius: '20px' }} />
                                    : <img src={user} style={{ objectFit: 'cover', width: calcMimMax(40, 60, 26), height: calcMimMax(40, 60, 26), borderRadius: '20px' }} />}
                                <Grid>
                                    <Grid>
                                        <Typography sx={{ fontSize: calcMimMax(11, 15, 126), color: '#142A3099' }}>Nome</Typography>
                                        <Typography sx={{ fontSize: calcMimMax(11, 16, 126), color: '#142A30', fontWeight: 700 }}>{UpercaseName(membro.nome)}</Typography>
                                    </Grid>
                                    <Grid>
                                        <Typography sx={{ fontSize: calcMimMax(11, 15, 126), color: '#142A3099' }}>Situação</Typography>
                                        <Typography sx={{ fontSize: calcMimMax(11, 16, 126), color: '#142A30', fontWeight: 700 }}>
                                            OK
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </>
                            :
                            <Grid sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                            }}>
                                <img src={xulisCDL} style={{ objectFit: 'cover', width: calcMimMax(100, 146, 13), height: calcMimMax(80, 116, 16) }} />
                                <Grid>
                                    <Typography
                                        sx={{
                                            fontSize: screenWidth <= 1020 ? '20px' : calcMimMax(15, 20, 94),
                                            color: '#142A30',
                                            fontWeight: 700
                                        }}>
                                        Xuliz & CDL
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: screenWidth <= 1020 ? '15px' : calcMimMax(11, 15, 126),
                                            color: '#142A3099'
                                        }}>
                                        Digite o número de
                                        celular para cadastrar um
                                        novo Membro+
                                    </Typography>
                                </Grid>
                            </Grid>
                    }
                </Grid>
            </Grid>

            <Grid sx={{ border: '1px solid #142A3099', margin: '30px 0px' }} />

            <Grid sx={{
                display: 'flex',
                flexDirection: screenWidth <= 1800 ? 'column' : 'row',
                justifyContent: 'space-between',
            }}>
                <Grid sx={{ width: screenWidth <= 1800 ? '100%' : '32%' }}>
                    <Grid sx={{ paddingBottom: '44px' }}>
                        <Typography sx={{ fontSize: '24px', color: '#142A30', fontWeight: 700 }}>Adicionar Submembros+</Typography>
                        <Typography sx={{ fontSize: '15px', color: '#142A3099' }}>Área destinada ao cadastro de submembros+ do membro+ acima.</Typography>
                    </Grid>

                    <Grid sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignitems: 'center',
                        justifyContent: screenWidth <= 1800 ? 'flex-start' : 'space-between',
                        gap: screenWidth <= 1800 ? '10px' : null,
                        paddingBottom: '25px'
                    }}>

                        <Grid sx={{ display: 'flex', flexDirection: 'row' }}>
                            {subMembro.erro == 'S' ?
                                <Grid sx={{
                                    border: '1px solid #17333A',
                                    borderRadius: '20px',
                                    marginRight: '16px',
                                    width: '50px', height: '50px',
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}>
                                    <img src={xulisExclamacao}
                                        style={{
                                            objectFit: 'cover',
                                            width: '6px', height: '25px',
                                            borderRadius: '20px',
                                            margin: '12px'
                                        }}
                                    />
                                </Grid>
                                :
                                <>
                                    {subMembro.idusuario ?
                                        <Grid>
                                            {subMembro.urlfotoperfil ?
                                                <img src={subMembro.urlfotoperfil} style={{ objectFit: 'cover', width: '50px', height: '50px', marginRight: '16px', borderRadius: '20px', border: '1.5px solid #17333A' }} />
                                                :
                                                <Typography sx={{
                                                    width: '45px',
                                                    height: '45px',
                                                    display: 'flex',
                                                    fontWeight: 600,
                                                    color: '#FFFFFF',
                                                    bgcolor: '#17333A',
                                                    alignItems: 'center',
                                                    borderRadius: '50px',
                                                    justifyContent: 'center',
                                                    border: '2px solid #17333A',
                                                    marginRight: '16px'
                                                }}>
                                                    {getInitials(subMembro.nome)}
                                                </Typography>
                                            }
                                        </Grid>
                                        :
                                        <Grid sx={{
                                            border: '1px solid #17333A',
                                            borderRadius: '20px',
                                            marginRight: '16px',
                                        }}>
                                            <img src={xulisPlus}
                                                style={{
                                                    objectFit: 'cover',
                                                    width: '25px', height: '25px',
                                                    borderRadius: '20px',
                                                    margin: '12px'
                                                }}
                                            />
                                        </Grid>
                                    }
                                </>
                            }
                            <Grid>
                                <Typography sx={{ fontSize: '12px', color: '#142A3099' }}>Situação</Typography>
                                <Typography
                                    sx={{
                                        fontSize: '16px',
                                        color: !subMembro.erro ? '#142A30' : subMembro.erro == 'S' ? '#FFB74D' : '#7FA800',
                                        fontWeight: 700
                                    }}>
                                    {!subMembro.erro ? 'DIGITE O NÚMERO' : subMembro.erro == 'S' ? 'NÃO EXISTENTE' : 'OK'}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid sx={{ height: '50px', border: '1px solid #142A3099' }} />

                        <Grid>
                            <Typography sx={{ width: '20ch', fontSize: '15px', color: '#142A3099', lineHeight: '17.6px' }}>
                                *Usuário não existente, vai ser enviado um SMS para que seja criada a conta.
                            </Typography>
                        </Grid>

                    </Grid>

                    <Grid sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '10px',
                        paddingBottom: '25px',
                    }}>
                        <InputMask
                            maskChar={null}
                            mask={"(99) 99999-9999"}
                            value={subMembro.celular}
                            onChange={(i) => setSubMembro({ celular: i.target.value })}
                        >
                            {() => (
                                <OutlinedInput
                                    sx={styles.customInputNumberSub}
                                    type="text"
                                    placeholder="Número de celular"
                                />
                            )}
                        </InputMask>

                        <OutlinedInput
                            sx={styles.customInputNumberSub}
                            type="text"
                            placeholder="Nome"
                            value={UpercaseName(subMembro.nome)}
                            onChange={(i) => setSubMembro((prevState) => ({ ...prevState, nome: i.target.value }))}
                        />
                        <TextField
                            select
                            variant="outlined"
                            sx={styles.customInputNumberSub}
                            SelectProps={{
                                native: true,
                            }}
                            value={subMembro.relacaoMembro}
                            onChange={(i) => setSubMembro((prevState) => ({ ...prevState, relacaoMembro: i.target.value }))}
                        >
                            {relacaoMembro?.map((option, index) => (
                                <option key={index.iddependentetipo} value={option.iddependentetipo}>
                                    {option.dependentetipo}
                                </option>
                            ))}
                        </TextField>


                    </Grid>
                    <Button sx={{
                        bgcolor: '#17333A',
                        padding: '10px 15px',
                        borderRadius: '20px',
                        '&:hover': {
                            backgroundColor: '#17333A'
                        },
                    }}
                        onClick={() => adicionarSubmembro()}>
                        <Typography sx={{ color: '#FFFFFF', textTransform: 'none', fontSize: '15px' }}>
                            Adicionar Submembro<FontAwesomeIcon icon={faPlus} style={{ color: '#9DE200', fontSize: '12px' }} />
                        </Typography>
                    </Button>
                </Grid>

                {screenWidth <= 1800 ? <Grid sx={{ border: '1px solid #142A3099', margin: '30px 0px' }} /> : null}

                <Grid sx={{ width: screenWidth <= 1800 ? '100%' : '65%' }}>
                    <Grid sx={{ paddingBottom: '44px' }}>
                        <Typography sx={{ fontSize: '24px', color: '#142A30', fontWeight: 700 }}>Submembros+ cadastrados</Typography>
                        <Typography sx={{ fontSize: '15px', color: '#142A3099' }}>Abaixo estão todos os submembros+ que foram cadastrados.</Typography>
                    </Grid>

                    <Grid sx={{ height: '350px', bgcolor: '#F0EFEB', borderRadius: '20px' }}>
                        {subMembrosList.length > 0 ?
                            <Lista subMembrosList={subMembrosList} excluirMembro={excluirMembro} getInitials={getInitials} />
                            :
                            <Grid sx={{
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column',
                                height: '100%',
                                justifyContent: 'center'
                            }}>
                                <img src={warning} style={{ objectFit: 'cover', width: '50px', height: '50px', borderRadius: '20px', marginBottom: '15px' }} />
                                <Typography sx={{ fontSize: '24px', color: '#142A30', fontWeight: 700 }}>
                                    Sem submembros+
                                </Typography>
                                <Typography sx={{ maxWidth: '298px', fontSize: '14px', color: '#142A3099', textAlign: 'center' }}>
                                    Coloque as informações ao lado solicitadas para adicionar um submembro+
                                </Typography>
                            </Grid>
                        }
                    </Grid>

                </Grid>

            </Grid>

            <Grid sx={{ border: '1px solid #142A3099', margin: '30px 0px' }} />

            <Grid sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '15px',
                // paddingRight: '25px',
            }}>
                <Button sx={{
                    bgcolor: '#E57373',
                    padding: '10px 15px',
                    borderRadius: '20px',
                    '&:hover': {
                        backgroundColor: '#E57373'
                    },
                }}
                    onClick={() => history.back()}>
                    <Typography sx={{ color: '#FFFFFF', textTransform: 'none', fontSize: '15px' }}>
                        Cancelar
                    </Typography>
                </Button>
                <Button sx={{
                    bgcolor: '#17333A',
                    padding: '10px 15px',
                    borderRadius: '20px',
                    '&:hover': {
                        backgroundColor: '#17333A'
                    },
                }}
                    onClick={() => salvarMembro()}>
                    <Typography sx={{ color: '#FFFFFF', textTransform: 'none', fontSize: '15px' }}>
                        Salvar Membro<FontAwesomeIcon icon={faPlus} style={{ color: '#9DE200', fontSize: '12px' }} />
                    </Typography>
                </Button>
            </Grid>

        </Grid >
    );
};

export default Content;

const styles = Stylesheet.create({
    customInputNumber: {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: '49%',
        minWidth: '285px',
        maxWidth: '100%',
        borderRadius: '15px',
        '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
        },
    },
    customInputNumberSub: {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: '49%',
        minWidth: '285px',
        maxWidth: '100%',
        borderRadius: '15px',

        display: 'flex',
        justifyContent: 'center',
        '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
        },
        '& .MuiOutlinedInput-input': {
            display: 'flex',
            justifyContent: 'center',
            borderRadius: '20px',
            border: '2px solid #142A3066',
            bgcolor: '#FEFDF9',
        },
        '& .MuiInputBase-input': {
            color: '#142A30',
            fontWeight: 'bold',
            fontSize: '16px',
            padding: '12px',
        },
        '& .MuiInputBase-input::placeholder': {
            fontSize: '16px',
            color: '#142A3066',
            opacity: 1,
            fontWeight: 'bold',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        }
    }
})
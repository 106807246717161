import { useState, useEffect } from 'react';

export function ControllerPrimeiroAcesso() {

    const [isMobile, setIsMobile] = useState(false);
    const [screenWidth, setScreenWidth] = useState(null);

    useEffect(() => {
        updateDimensions();
        window.addEventListener('resize', updateDimensions);

        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    }, []);

    const updateDimensions = () => {
        setIsMobile(window.innerWidth <= 800);
        setScreenWidth(window.innerWidth);
    };

    const [status, setStatus] = useState([
        { step: 0, success: false, error: false },
        { step: 1, success: false, error: false },
        { step: 2, success: false, error: false },
        { step: 3, success: false, error: false },
    ]);

    //DOCUMENTOS DA EMPRESA E SÓCIOS - START
    const [companyData, setCompanyData] = useState({
        razaoSocial: '',
        nomeFantasia: '',
        cpfCnpj: '',
        inscricaoEstadual: '',
        urlSite: '',
        constratoSocialInscricaoMEI: {}
    });

    const handleFieldChangeCompany = (field, value) => {
        setCompanyData(prevState => ({
            ...prevState,
            [field]: value,
        }));
    };

    const [businessAddress, setBusinessAddress] = useState({
        endereco: '',
        bairro: '',
        cep: '',
        estado: '',
        cidade: '',
        complemento: '',
        pontoReferencia: '',
        comprovanteEndereco: {}
    });

    const handleChangeBusinessAddress = (field, value) => {
        setBusinessAddress(prevState => ({
            ...prevState,
            [field]: value,
        }));
    };

    const [partner, setPartner] = useState([{
        nome: '',
        email: '',
        cpf: '',
        dtNascimento: '',
        cell1: '',
        cell2: '',
        comprovanteEndereco: {},
        fotoCNHRG: {}
    }]);

    const handleChangePartner = (index, field, value) => {
        setPartner((prevPartners) =>
            prevPartners.map((partner, i) =>
                i === index
                    ? { ...partner, [field]: value } // Atualiza o campo do parceiro no índice especificado
                    : partner // Retorna os parceiros não alterados
            )
        );
    };

    const addNewPartner = () => {
        setPartner((prevPartners) => [
            ...prevPartners,
            {
                nome: '',
                email: '',
                cpf: '',
                dtNascimento: '',
                cell1: '',
                cell2: '',
                comprovanteEndereco: {},
                fotoCNHRG: {}
            }
        ]);
    };
    //DOCUMENTOS DA EMPRESA E SÓCIOS - END

    //DADOS BANCÁRIOS - START
    const [bankInfo, setBankInfo] = useState({
        codigoBanco: '',
        nomeBanco: '',
        agencia: '',
        condaDigito: '',
        comprovacaoConta: {},
    });

    const handleChangeBankInfo = (field, value) => {
        setBankInfo(prevState => ({
            ...prevState,
            [field]: value,
        }));
    };

    const [moreInfo, setMoreInfo] = useState({
        mediaVendasCartao: '',
        maiorValorUnicaTransacao: '',
        faturamentoMensalBruto: '',
        tipoCashback: {
            name: '',
            comicao: '',
            products: [{ name: '', cod: '', comicao: '' }]
        }
    });

    const handleChangeMoreInfo = (field, value) => {
        setMoreInfo(prevState => ({
            ...prevState,
            [field]: value,
        }));
    };

    const [configuracaoPix, setConfiguracaoPix] = useState({
        instituicao: '',
        chavePix: '',
        idPix: '',
        certificadoDigital: {},
        senha: ''
    });

    const handleChangeConfiguracaoPix = (field, value) => {
        setConfiguracaoPix(prevState => ({
            ...prevState,
            [field]: value,
        }));
    };
    //DADOS BANCÁRIOS - END

    //INFORMAÇÕES DO ESTABELECIMENTO - START
    const [logo, setLogo] = useState({
        img: ''
    });

    const handleChangeLogo = (field, value) => {
        setLogo(prevState => ({
            ...prevState,
            [field]: value,
        }));
    };

    const [unitDetails, SetUnitDetails] = useState({
        horario: {
            name: '',
            horario: []
        },
        areaConstruida: '',
        linkGoogleMaps: '',
        lojaAlugada: '',
        qtdColaboradores: '',
        fotoFachada: ''
    });

    const handleChangeUnitDetails = (field, value) => {
        SetUnitDetails(prevState => ({
            ...prevState,
            [field]: value,
        }));
    };

    const [correspondenceAddress, setCorrespondenceAddress] = useState({
        endereco: '',
        bairro: '',
        cep: '',
        estado: '',
        cidade: '',
        complemento: '',
        pontoReferencia: '',
        comprovanteEndereco: ''
    });

    const handleChangeCorrespondenceAddress = (field, value) => {
        setCorrespondenceAddress(prevState => ({
            ...prevState,
            [field]: value,
        }));
    };

    const getSameAdress = (event) => {
        if (event.target.checked) {
            setCorrespondenceAddress(businessAddress);
        } else {
            setCorrespondenceAddress({
                endereco: '',
                bairro: '',
                cep: '',
                estado: '',
                cidade: '',
                complemento: '',
                pontoReferencia: '',
                comprovanteEndereco: ''
            })
        }
    }

    const [moreInfoEstablishment, setMoreInfoEstablishment] = useState({
        info: '',
    });

    const handleChangeMoreInfoEstablishment = (field, value) => {
        setMoreInfoEstablishment(prevState => ({
            ...prevState,
            [field]: value,
        }));
    };
    //INFORMAÇÕES DO ESTABELECIMENTO - END

    useEffect(() => {
        console.log('correspondenceAddress', correspondenceAddress)
    }, [correspondenceAddress])

    // useEffect(() => {
    //     getUsers();
    // }, []);

    // async function getUsers() {
    //     setLoading(true);
    //     try {
    //         const users = await fetchUsers();
    //         setUsers(users);
    //     } catch (error) {
    //         console.error('Erro ao carregar usuários:', error);
    //         setUsers([]);
    //     } finally {
    //         setLoading(false);
    //     }
    // }

    return {
        isMobile,
        screenWidth,
        status,
        companyData,
        handleFieldChangeCompany,
        businessAddress,
        handleChangeBusinessAddress,
        partner,
        handleChangePartner,
        bankInfo,
        handleChangeBankInfo,
        moreInfo,
        handleChangeMoreInfo,
        configuracaoPix,
        handleChangeConfiguracaoPix,
        logo,
        handleChangeLogo,
        unitDetails,
        handleChangeUnitDetails,
        addNewPartner,
        correspondenceAddress,
        handleChangeCorrespondenceAddress,
        getSameAdress,
        moreInfoEstablishment,
        handleChangeMoreInfoEstablishment
    };
}

import Stylesheet from "reactjs-stylesheet";
import { Button, Grid, OutlinedInput, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faChevronRight, faSearch } from "@fortawesome/free-solid-svg-icons";


const Header = ({ isMobile, membroSearch, handleChangeMembroSearch }) => {

    return (
        <Grid sx={{
            ...styles.gridHeader,
            alignItems: isMobile ? null : 'center',
            flexDirection: isMobile ? 'column' : 'row',
        }}>
            <Grid>
                <Button onClick={() => history.back()}>
                    <FontAwesomeIcon
                        icon={faArrowLeft}
                        style={{ fontSize: '16px', color: "#999999", cursor: 'pointer', paddingRight: '3px' }}
                    />
                    <Typography style={{ fontSize: '13px', color: '#999999', textTransform: 'none' }}>
                        Voltar
                    </Typography>
                </Button>
                <Grid style={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    alignItems: isMobile ? 'flex-start' : 'flex-end',
                    justifyContent: 'space-between'
                }}>
                    <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Grid sx={{
                            display: 'flex',
                            alignItems: 'self-start',
                            flexDirection: 'column',
                            paddingBottom: isMobile ? '20px' : null
                        }}>
                            <Typography style={{ fontSize: '32px', color: '#142A30', fontWeight: 700 }}>
                                Membros+
                            </Typography>
                            <Grid sx={{
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'row',
                                gap: '5px',
                                backgroundColor: '#9DE200',
                                padding: '5px 10px',
                                borderRadius: '10px'
                            }}>
                                <Typography style={{ fontSize: '10px', color: '#142A30' }}>
                                    Marketing
                                </Typography>
                                <FontAwesomeIcon
                                    icon={faChevronRight}
                                    style={{ fontSize: '10px', color: "#142A30", cursor: 'pointer', paddingRight: '3px' }}
                                />
                                <Typography style={{ fontSize: '10px', color: '#142A30', fontWeight: 700 }}>
                                    Membros+
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid sx={styles.gridArrow}>
                <Grid sx={styles.gridSearch}>
                    <FontAwesomeIcon icon={faSearch} style={styles.iconSearch} />
                    <OutlinedInput
                        sx={{
                            ...styles.customInputCod,
                            '& .MuiInputBase-input': {
                                color: '#142A30'
                            },
                            '& .MuiInputBase-input::placeholder': {
                                opacity: 1,
                                fontWeight: 600,
                                fontSize: '15px',
                                color: '#142A30',
                            },
                            width: '100%',
                            maxWidth: '280px'
                        }}
                        placeholder="Procurar membro+"
                        value={membroSearch}
                        onChange={handleChangeMembroSearch}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Header;

const styles = Stylesheet.create({
    customInputCod: {
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
        }
    },
    gridHeader: {
        width: '100%',
        display: 'flex',
        paddingBottom: '28px',
        justifyContent: 'space-between'
    },
    gridArrow: {
        gap: '10px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    boxArrow: {
        width: '34px',
        display: 'flex',
        bgcolor: '#142A30',
        borderRadius: '10px',
        justifyContent: 'center'
    },
    arrow: {
        padding: '7px',
        color: "#FFFFFF"
    },
    gridSearch: {
        height: '35px',
        display: 'flex',
        bgcolor: '#FEFDF9',
        padding: '5px 10px',
        borderRadius: '20px',
        flexDirection: 'row',
        alignItems: 'center',
        border: '2px solid #142A30'
    },
    iconSearch: {
        width: '15px',
        height: '15px',
        color: "#142A30"
    }
});
import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Menu from "./componentes/menu";
import MenuMobile from "./componentes/menuMobile";


import Bancoob from "./assets/bancoob.jpg";
// import bXuliz from "./assets/bXuliz.png";
import bXuliz from "./assets/logos/logo.xulis.name.png";
import bElo from "./assets/bElo.png";
import bVisa from "./assets/bVisa.png";
import "./ResumoRecebiveis.css";


import logo1 from './assets/logos/logo.xulis.name.png'; // Tell webpack this JS file uses this image

import visa from './assets/visa.png';
import master from './assets/master.png'; // Tell webpack this JS file uses this image
import elo from './assets/elo_debito.png'; // Tell webpack this JS file uses this image
import hipercard from './assets/hipercard.png'; // Tell webpack this JS file uses this image
import amex from './assets/amex.png'; // Tell webpack this JS file uses this image


import { useEffect } from "react";
import xloja_12 from './assets/xloja_12.png'; // Tell webpack this JS file uses this image
import waiting from './assets/waiting.jpg';
import banco_img from './assets/banco.png';


import xloja from './assets/xloja.png'; // Tell webpack this JS file uses this image


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse, faShoppingBag, faMoneyCheckAlt, faFileAlt, faGavel, faChartPie, faBox, faCircleDollarToSlot, faBullhorn, faQuestionCircle, faCalendar, faUsers, faGear, faSquareXmark, faMobile, faBars, faBookReader, faCalendarDays, faCalendarCheck } from '@fortawesome/free-solid-svg-icons'
import { useState } from "react";
import axios from "axios";
import logo_parceiros from './assets/grupo677.png'; // Tell webpack this JS file uses this image

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button } from "react-bootstrap";


import Calendario from "./Recebiveis/calendario";
import Resumo from "./Recebiveis/Resumo";
import { addDays, format } from "date-fns";


var vendaPaga = false;

const Recebiveis = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Para acessar os dados da navegação
  const { param } = location.state || {};

  const Swal = require('sweetalert2')

  const events = [{
    id: 1,
    color: '#fd3153',
    from: '2019-05-02T18:00:00+00:00',
    to: '2019-05-05T19:00:00+00:00',
    title: 'This is an event'
  }, {
    id: 2,
    color: '#1ccb9e',
    from: '2019-05-01T13:00:00+00:00',
    to: '2019-05-05T14:00:00+00:00',
    title: 'This is another event'
  }, {
    id: 3,
    color: '#3694DF',
    from: '2019-05-05T13:00:00+00:00',
    to: '2019-05-05T20:00:00+00:00',
    title: 'This is also another event'
  }];

  const [emailRef, setEmailRef] = useState('');
  const [value, setValue] = useState('');
  const [urlfotoperfil, setFotoUrl] = useState('');
  const [cashbackConta, setCashback] = useState('3');
  const [cashbackCliente, setCaschbackCliente] = useState('R$ 0,00');
  const [dadosClinte, setDadosCliente] = useState([]);
  const [dadosX, setDadosX] = useState([]);
  const [primeiroNome, setPrimeiroNome] = useState('');
  const [nomeUser, setNome] = useState('');
  const [rows, setRow] = useState([]);

  const [startDate, setStartDate] = useState(param?.dtIni ? addDays(new Date(param?.dtIni.data), 1) : getPrimeiroDiaDoMes());
  const [endDate, setEndDate] = useState(param?.dtEnd ? addDays(new Date(param?.dtEnd.data), 1) : getUltimoDiaDoMes());

  const [showCalendar, setShowCalendar] = useState(false);

  function getPrimeiroDiaDoMes() {
    const dataAtual = new Date();
    return new Date(dataAtual.getFullYear(), dataAtual.getMonth(), 1);
  }

  function getUltimoDiaDoMes() {
    const dataAtual = new Date();
    return new Date(dataAtual.getFullYear(), dataAtual.getMonth() + 1, 0);
  }

  const [dadosREF, setDADOSREF] = useState(null);

  const [total_receber, setTotalReceber] = useState('');
  const [geralTaxas, setTotalTaxas] = useState('R$ 0,00');
  const [recebiveisPagamento, setRecebiveisPagamento] = useState([]);




  const now = new Date();
  const [selectedValue, setSelectedValue] = useState(now.getMonth());
  const [ano, setAno] = useState(now.getFullYear());
  const [dataCalendario, setDataCalendario] = useState([]);
  const [geralDebito, setGeralDebito] = useState(0)
  const [geralCredito, setGeralCredito] = useState(0)
  const [geralCreditoParcelado, setGeralCreditoParcelado] = useState(0)
  const [geralXuliz, setGeralXuliz] = useState(0)
  const [geralPix, setGeralPix] = useState(0)
  const [geralBruto, setGeralBruto] = useState(0)
  const [geralDepositar, setGeralDepositar] = useState(0)


  const [totalPedidos, setTotalPedidos] = useState(0)

  const [dataRef, setDataRef] = useState('')


  const [clickEnviou, setClickEnviou] = useState(false);
  const [tokenEsta, setTokenEstabelecimento] = useState('');
  const [idvenda, setIdvenda] = useState('');
  const [isMobile, setIsMobile] = useState(true);
  const [screenWidth, setScreenWidth] = useState(null);
  const [urlLogo, setUrlLogo] = useState('');

  const [lista, setLista] = useState([]);
  const [menuOpen, setMenuOpen] = useState(true);

  useEffect(() => {
    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => {
      window.removeEventListener('resize', updateDimensions);
    };

  }, []);



  useEffect(() => {

    getDadosSQL()

  }, []);

  const handleDateChange = (dates) => {
    const [start, end] = dates;

    setStartDate((start));
    setEndDate(end);

    if (start && end) {
      // setloading(true);
      VerificaChamar(end, start);
    }
  };

  const clickAplicar = () => {

    if (endDate == null) {
      setEndDate(startDate)
      setShowCalendar(false)
      VerificaChamar(startDate, startDate)
      return false
    }

    setShowCalendar(false)
    VerificaChamar(endDate, startDate)
  }

  const toggleCalendar = () => {


    setShowCalendar(true)


    return false;

  };

  const VerificaChamar = async (endDate, startDate, showCalendar) => {


    await sleep(300)



    // var data = document.getElementById('data_filtro').value;
    // data = data.split(' - ')

    // if (data.length != 2) {
    //   return false;
    // }

    // var inicio = data[0].split('/');
    // inicio = inicio[2] + '-' + inicio[1] + '-' + inicio[0];

    // var fim = data[1].split('/');
    // fim = fim[2] + '-' + fim[1] + '-' + fim[0]


    getDadosSQL(true, format(startDate, 'yyyy-MM-dd'), format(endDate, 'yyyy-MM-dd'))


  }


  function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }


  const getDadosSQL = async (dataSetada, ini, fim) => {

    setRow([])



    await getDados()

    var dados = await localStorage.getItem('login')
    dados = JSON.parse(dados);
    var idestabele = dados.idestabelecimento;




    var getTokenEstabelecimneto = await getTokenEstabelciment(idestabele);



    if (getTokenEstabelecimneto == null) {
      getTokenEstabelecimneto = dados.tokenestabelecimento;
    }


    // var datainicial = await getDataInicial(mesSelecionado)
    // var datafinal = await getDataFinal(mesSelecionado)

    var datainicial = await getDataInicial()
    var datafinal = await getDataFinal()

    // console.log('param', param)

    if (dataSetada == true) {
      datainicial = ini;
      datafinal = fim;
    } else if (param?.dtIni && param?.dtEnd) {
      datainicial = param?.dtIni.data;
      datafinal = param?.dtEnd.data;
    }

    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams({
        idempresa: '1350',
        token: getTokenEstabelecimneto,
        idvendaxuliz: '',
        metodo: 'Lista',
        idestabelecimento: idestabele,
        idvenda: '',
        datainicial: datainicial,
        datafinal: datafinal
        // datainicial: param?.dtIni ? param?.dtIni.data : datainicial,
        // datafinal: param?.dtEnd ? param?.dtEnd.data : datafinal
      })
    };

    fetch('https://www.xulizsistemas.com.br/retaguarda/integracao/estabelecimento/listarecebiveisagrupado.php', options)
      .then(response => response.json())
      .then(response => tradarDados(response))
      .catch(err => console.error(err));

    fetch('https://www.xulizsistemas.com.br/retaguarda/integracao/estabelecimento/listarecebiveis.php', options)
      .then(response => response.json())
      .then(response => {
        tradarDadosVendas(response)
        setLista(response.retorno)
      })
      .catch(err => console.error(err));

  }

  const tradarDados = async (dadosG) => {


    setDADOSREF(dadosG)

    var retorno = dadosG.retorno;
    // console.log('dadosG',dadosG)
    var usuarioFiltrou = false;

    if (dadosG.array_retorno) {
      usuarioFiltrou = true;
      retorno = dadosG.array_retorno

      if (dadosG.array_retorno.length == 0) {
        retorno = dadosG.retorno;
      }
    }

    // var mesREF = getCurrentMonthAndYear(newMes);


    // console.log(mesREF,'mesREF')
    //  console.log('dados_api',retorno);

    var geralLiquido = 0;

    var totalDebitos = 0;
    var totalCredito = 0;
    var totalXuliz = 0;
    // var totalPix= 0;
    var totalTaxas = 0;

    var totalBruto = 0;
    var totalBrutoDepositar = 0;
    var totalLiquido = 0;
    var totalLiquidoDepositar = 0;

    var total_pedido = 0;



    var today = new Date();
    var dia_hoje = today.getDate();
    var mesHoje = today.getMonth() + 1;


    for (var x = 0; x < retorno.length; x++) {

      var data = retorno[x].datavencimento;
      var datavencimento = retorno[x].datavencimento;


      data = data.split('-');

      var diaRef = data[2];
      var mesRef = data[1];





      data = data[0] + '-' + data[1];

      //  if (resumo == null){
      //   continue;
      //  }

      var resumo = retorno[x].resumo


      for (var b = 0; b < resumo.length; b++) {

        // console.log('dados_api2',resumo[b]);
        var bandeira = resumo[b].tipo;
        //  console.log(bandeira)
        if (bandeira.includes("Débito")) {
          var debito = resumo[b].valor
          debito = parseFloat(debito)
          totalDebitos += debito
        }

        if (bandeira.includes("Crédito")) {
          var credito = resumo[b].valor
          credito = parseFloat(credito)
          totalCredito += credito
        }

        if (bandeira.includes("Xuliz APP")) {
          var xuliz = resumo[b].valor
          xuliz = parseFloat(xuliz)
          totalXuliz += xuliz
        }


      }


      // console.log(mesREF+'  ||| '+data+' -->'+diaRef)

      var valor = retorno[x].valor;
      valor = parseFloat(valor);


      geralLiquido = geralLiquido + valor;


      var quantidade = retorno[x].qtde
      quantidade = parseFloat(quantidade)
      total_pedido = total_pedido + quantidade;

      var valorBruto = retorno[x].valorbruto
      valorBruto = parseFloat(valorBruto)

      totalTaxas = totalTaxas + (valorBruto - valor)


      // if(mesRef > mesHoje){
      //   //futuro
      //   totalBrutoDepositar = totalBrutoDepositar+valorBruto
      //   continue
      // }

      // if(diaRef > dia_hoje){
      //   totalBrutoDepositar = totalBrutoDepositar+valorBruto
      //   //futuro
      //   continue
      // }
      if (mesRef > mesHoje) {
        //futuro
        totalLiquidoDepositar = totalLiquidoDepositar + valor
        continue
      }

      if (diaRef > dia_hoje) {
        totalLiquidoDepositar = totalLiquidoDepositar + valor
        //futuro
        continue
      }


      //Valor total depositado
      // totalBruto = totalBruto+valorBruto;


      totalLiquido = totalLiquido + valor





    }


    //  geralLiquido = geralLiquido.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    //  totalDebitos = totalDebitos.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    //  totalCredito = totalCredito.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });


    //  totalBruto = totalBruto.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    //  totalBrutoDepositar = totalBrutoDepositar.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    totalTaxas = totalTaxas.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    totalLiquidoDepositar = totalLiquidoDepositar.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    totalLiquido = totalLiquido.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

    //  setGeralBruto(totalBruto)
    //  setGeralDepositar(totalBrutoDepositar)

    setTotalTaxas(totalTaxas)
    setGeralBruto(totalLiquido)
    setGeralDepositar(totalLiquidoDepositar)

    //   setGeralPix(totalPix)
    //  setTotalReceber(geralLiquido)
    //  setTotalPedidos(total_pedido)


  }


  const bandeiraTipo = (bandeira) => {


    if (bandeira.includes("Visa")) {
      return <img style={{ width: '39px', height: '23px' }} src={visa} />
    }

    if (bandeira.includes("Master")) {
      return <img style={{ width: '35px', height: '23px' }} src={master} />
    }

    if (bandeira.includes("Elo")) {
      return <img style={{ width: '35px', height: '15px' }} src={elo} />
    }

    if (bandeira.includes("Hiper")) {
      return <img style={{ width: '37px', height: '22px' }} src={hipercard} />
    }

    if (bandeira.includes("Amex")) {
      return <img style={{ width: '37px', height: '22px' }} src={amex} />
    }

    if (bandeira.includes("Xuliz")) {
      return <img style={{ width: '47px', height: '22px', padding: 2, backgroundColor: '#142A30' }} src={logo1} />
    }

    if (bandeira.includes("XULIZ")) {
      return <img style={{ width: '37px', height: '22px', margin: 10, borderWidth: 3, }} src={logo1} />
    }


    // if(bandeira.includes("Visa")){
    //   return  <img style={{width:'36px', height:'19px'}} src={bVisa}/>
    // }

    // return  <p> - </p>

    return <img style={{ width: '39px', height: '23px' }} src={visa} />
  }
  const bandeiraTipoMobile = (bandeira) => {


    if (bandeira.includes("Visa")) {
      return <img style={{ width: '12px', height: '10px' }} src={visa} />
    }

    if (bandeira.includes("Master")) {
      return <img style={{ width: '12px', height: '10px' }} src={master} />
    }

    if (bandeira.includes("Elo")) {
      return <img style={{ width: '15px', height: '10px' }} src={elo} />
    }

    if (bandeira.includes("Hiper")) {
      return <img style={{ width: '12px', height: '10px' }} src={hipercard} />
    }

    if (bandeira.includes("Amex")) {
      return <img style={{ width: '12px', height: '10px' }} src={amex} />
    }

    if (bandeira.includes("Xuliz")) {
      return <img style={{ width: '12px', height: '10px' }} src={logo1} />
    }

    if (bandeira.includes("XULIZ")) {
      return <img style={{ width: '12px', height: '10px', margin: 10, borderWidth: 3 }} src={logo1} />
    }



    return <img style={{ width: '12px', height: '10px' }} src={visa} />
  }


  const tradarDadosVendas = async (dadosG) => {

    var dadosZ = dadosG.retorno;
    // console.log('dadosZ', dadosZ);


    var totalcredito = 0;
    var totaldebito = 0
    var totalxuliz = 0





    for (var x = 0; x < dadosZ.length; x++) {
      var item = dadosZ[x];
      // console.log('dadosZ[x]',item);
      var bandeiraget = dadosZ[x].bandeiraxuliz

      var valor = '' + dadosZ[x].valor;
      valor = parseFloat(valor)


      if (bandeiraget.includes('Crédito')) {
        totalcredito = totalcredito + valor
      }

      if (bandeiraget.includes('Débito')) {
        totaldebito = totaldebito + valor
      }
      if (bandeiraget.includes('XULIZ') || bandeiraget.includes('Xuliz')) {
        totalxuliz = totalxuliz + valor
      }
    }



    var groupedByDate = dadosZ.reduce((acc, currentValue) => {
      var date = currentValue.datavencimento;
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(currentValue);
      return acc;
    }, {});




    // console.log('groupedByDate', groupedByDate)
    var valuesArray = Object.values(groupedByDate);

    var SeparadoData = []

    for (var x = 0; x < valuesArray.length; x++) {

      var getDadosDados = valuesArray[x]

      // console.log('ss',getDados)

      var groupedByDatebandeiraxuliz = getDadosDados.reduce((acc, currentValue) => {
        var date = currentValue.bandeiraxuliz;

        if (!acc[date]) {
          acc[date] = [];
          acc['dataRef'] = currentValue.datavencimento;
        }
        acc['dataRef'] = currentValue.datavencimento;
        acc[date].push(currentValue);
        return acc;
      }, {});

      SeparadoData.push(groupedByDatebandeiraxuliz)


    }



    // console.log('valuesArra2y', valuesArray)
    // console.log('SeparadoData', SeparadoData)


    var recebiveis_formatado = []


    for (var x = 0; x < SeparadoData.length; x++) {

      let objeto = SeparadoData[x];
      for (let bandeira in objeto) {

        if (bandeira != 'dataRef') {

          let quantidade = objeto[bandeira].length;

          let somaValoresLiquido = objeto[bandeira].reduce((acumulador, item) => acumulador + parseFloat(item.valor), 0);
          let somaValoresBruto = objeto[bandeira].reduce((acumulador, item) => acumulador + parseFloat(item.valorbruto), 0);

          somaValoresLiquido = somaValoresLiquido.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
          somaValoresBruto = somaValoresBruto.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

          var dataGet = SeparadoData[x].dataRef;
          dataGet = dataGet.split('-');
          dataGet = dataGet[2] + '/' + dataGet[1] + '/' + dataGet[0]



          recebiveis_formatado.push({
            data_ref: dataGet,
            bandeira: bandeira,
            quantidade_transacoes: quantidade,
            liquido: somaValoresLiquido,
            bruto: somaValoresBruto
          })
        }

      }


    }


    recebiveis_formatado.sort((a, b) => {
      let dataA = a.data_ref.split('/').reverse().join('-'); // Convertendo "dd/mm/yyyy" para "yyyy-mm-dd"
      let dataB = b.data_ref.split('/').reverse().join('-');
      return new Date(dataA) - new Date(dataB);
    });


    // console.log('recebiveis_formatado', recebiveis_formatado)



    totalcredito = totalcredito.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    totaldebito = totaldebito.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    totalxuliz = totalxuliz.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });



    setGeralDebito(totaldebito)
    setGeralCredito(totalcredito);
    setGeralXuliz(totalxuliz)
    setRecebiveisPagamento(recebiveis_formatado)


  }


  function getCurrentMonthAndYear(newMes) {
    // Cria um objeto de data para o mês atual
    var currentDate = new Date();

    var selectedValueGet = document.getElementById('selectInput').value;
    selectedValueGet = parseInt(selectedValue)

    if (newMes != null) {
      selectedValueGet = '' + newMes
      selectedValueGet = parseInt(selectedValueGet)
    }
    // Obtém o mês e o ano atual
    var month = selectedValueGet + 1; // Adiciona 1 pois os meses em JavaScript são indexados de 0 a 11
    var year = currentDate.getFullYear();

    // Retorna o mês e o ano formatados
    return `${year}-${(month < 10 ? '0' : '') + month}`;
  }



  function obterUltimoDiaDoMes(mes, ano) {
    var mesInt = parseInt(mes);

    // Criar uma nova data para o primeiro dia do mês seguinte
    var data = new Date(ano, mesInt, 0);

    // O último dia do mês é o último dia do mês anterior ao que definimos
    var ultimoDia = data.getDate();

    return ultimoDia;
  }


  const getDataFinal = async () => {
    var data = new Date();

    var ano = data.getFullYear();
    var mes = String(data.getMonth() + 1).padStart(2, '0');
    var dia = String(data.getDate()).padStart(2, '0');

    return ano + '-' + mes + '-' + dia;
  }

  const getDataInicial = async () => {
    var data = new Date();
    data.setDate(data.getDate() - 3);

    var ano = data.getFullYear();
    var mes = String(data.getMonth() + 1).padStart(2, '0');
    var dia = String(data.getDate()).padStart(2, '0');

    return ano + '-' + mes + '-' + dia;
  }


  const getTokenEstabelciment = async (token) => {
    const form = new FormData();
    form.append("idempresa", "1350");
    form.append("token", "e84d7ad2-f0cc-11eb-b838-568a76eb275e");
    form.append("metodo", "ListaEstabelecimentos");


    const options = {
      method: 'POST',
    };

    options.body = form;

    var rres = await fetch('https://xuliz.com.br/painel/integracao/estabelecimento.php', options).then(response => response.json());

    var arrrayEstabelecimnto = rres.estabelecimentos;

    var tokenEstabelecimento = '';
    for (var x = 0; x < arrrayEstabelecimnto.length; x++) {
      if (arrrayEstabelecimnto[x].idestabelecimento == token) {
        tokenEstabelecimento = arrrayEstabelecimnto[x].token;
        break;
      }
    }

    //  console.log(rres)
    //  console.log(tokenEstabelecimento)
    setTokenEstabelecimento(tokenEstabelecimento)
    return tokenEstabelecimento;


  }


  const updateDimensions = () => {
    setIsMobile(window.innerWidth <= 800); // Defina o limite de largura para o modo mobile conforme necessário
    setScreenWidth(window.innerWidth);
    verificarNiveisLogin(dadosX)

    if (window.innerWidth <= 1400 && menuOpen) {
      setMenuOpen(false)
    }
    if (window.innerWidth > 1400 && menuOpen) {
      setMenuOpen(true)
    }
  };






  const getDados = async () => {
    var dados = await localStorage.getItem('login')

    if (dados == null) {
      navigate("/");

      return false;
    }
    dados = JSON.parse(dados)
    setDadosX(dados)

    setUrlLogo(dados.urllogo)

    //  console.log('dadosX',dados)

    setEmailRef(dados.email)
    //  console.log(dados)




  }


  const verificarNiveisLogin = async () => {
    var idperfil = await localStorage.getItem('tipoPerfil');


    if (idperfil == "7") {
      //financeiro somente relatorios
      location.href = "./minhasvendas";

    }

  }









  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
    tradarDados(dadosREF, event.target.value)
  };

  const Detalhes = ({ minWidth }) => {

    return (
      <>
        <div style={{ display: 'flex', padding: 10, flexDirection: 'column' }}>

          <div id="blockUser" style={{ flexDirection: 'column', display: "flex", height: "100%", padding: 5, flex: 1, minWidth: minWidth, padding: 20 }}>

            <span style={{ fontFamily: 'Montserrat', fontSize: 20, color: '#262626', fontWeight: 'bold' }}>Detalhamento do calendário</span>
            <span style={{ fontFamily: 'Montserrat', fontSize: 12, backgroundColor: '#F2E2C4', width: 200, padding: 5, borderRadius: 5, marginTop: 10 }}>{dataRef}</span>


            <div style={{ backgroundColor: '#F1F1F1', display: 'flex', height: 700, marginTop: 20, borderRadius: 10, flexDirection: 'column', flex: 1 }}>
              <div style={{ display: 'flex', flex: 1 }}>

                <div style={{ flex: 1, justifyContent: 'center', display: 'flex', flexDirection: 'column', padding: 20 }}>
                  <span style={{ fontFamily: 'Montserrat', fontSize: 17, color: '#999999', fontWeight: 'bold' }}>Vendas no total</span>
                  <span style={{ fontFamily: 'Montserrat', fontSize: 13, color: '#999999', fontWeight: 'bold' }}>{totalPedidos} vendas</span>

                </div>

                <div style={{ flex: 1, justifyContent: 'center', display: 'flex', flexDirection: 'column', padding: 20 }}>
                  <span style={{ fontFamily: 'Montserrat', fontSize: 17, color: '#999999', fontWeight: 'bold' }}>{geralBruto}</span>
                </div>
              </div>

              <div style={{ display: 'flex', flex: 6, borderTop: "4px solid #3D3D3D", padding: 20, flexDirection: 'column' }}>

                <span style={{ fontFamily: 'Montserrat', fontSize: 17, color: '#262626', fontWeight: 'bold' }}>Formas de pagamento</span>


                <div style={{ width: '100%', paddingTop: 20 }}>
                  <span style={{ fontFamily: 'Montserrat', fontSize: 13, color: '#262626', paddingBottom: 11 }}>Crédito</span>
                  <span style={{ fontFamily: 'Montserrat', fontSize: 13, color: '#262626', paddingBottom: 11, float: "right" }}>{geralCredito}</span>
                </div>
                <div style={{ borderBottom: "1px solid #3D3D3D", width: '100%' }} />


                <div style={{ width: '100%', paddingTop: 20 }}>
                  <span style={{ fontFamily: 'Montserrat', fontSize: 13, color: '#262626', paddingBottom: 11 }}>Débito</span>
                  <span style={{ fontFamily: 'Montserrat', fontSize: 13, color: '#262626', paddingBottom: 11, float: "right" }}>{geralDebito}</span>
                </div>
                <div style={{ borderBottom: "1px solid #3D3D3D", width: '100%' }} />




                <div style={{ width: '100%', paddingTop: 20 }}>
                  <span style={{ fontFamily: 'Montserrat', fontSize: 13, color: '#262626', paddingBottom: 11 }}>Pix</span>
                  <span style={{ fontFamily: 'Montserrat', fontSize: 13, color: '#262626', paddingBottom: 11, float: "right" }}>{geralPix}</span>
                </div>
                <div style={{ borderBottom: "1px solid #3D3D3D", width: '100%' }} />




                <div style={{ width: '100%', paddingTop: 20 }}>
                  <span style={{ fontFamily: 'Montserrat', fontSize: 13, color: '#262626', paddingBottom: 11 }}>Saldo Xuliz</span>
                  <span style={{ fontFamily: 'Montserrat', fontSize: 13, color: '#262626', paddingBottom: 11, float: "right" }}>{geralXuliz}</span>
                </div>
                <div style={{ borderBottom: "1px solid #3D3D3D", width: '100%' }} />



                <div style={{ width: '100%', paddingTop: 20 }}>
                  <span style={{ fontFamily: 'Montserrat', fontSize: 13, color: '#262626', paddingBottom: 11 }}>Saldo Xuliz + Crédito</span>
                  <span style={{ fontFamily: 'Montserrat', fontSize: 13, color: '#262626', paddingBottom: 11, float: "right" }}>{geralXuliz}</span>
                </div>
                <div style={{ borderBottom: "1px solid #3D3D3D", width: '100%' }} />



                <div style={{ width: '100%', height: 150, textAlign: '-webkit-right', marginTop: 20, placeContent: 'center', flexDirection: 'column', display: 'flex' }}>

                  <span style={{ fontFamily: 'Montserrat', fontSize: 15, color: '#999999' }}>Taxa/tarifa paga:<span style={{ color: 'red' }}>  - {geralTaxas}</span></span>
                  <span style={{ fontFamily: 'Montserrat', fontSize: 15, color: '#999999', marginTop: 10 }}>Cashback: <span style={{ color: '#279C33' }}> {geralXuliz} </span></span>

                  <span style={{ fontFamily: 'Montserrat', fontSize: 20, color: '#999999', marginTop: 50 }}>Total líquido: <span style={{ color: 'black' }}>  {total_receber}</span></span>


                </div>



              </div>
            </div>





            {/* CODE */}

          </div>
        </div>

      </>
    )
  }


  const TableComponent = ({ }) => {
    return (
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th style={{ fontSize: 10, textAlign: '-webkit-center' }}>Data</th>
            <th style={{ fontSize: 10, textAlign: '-webkit-center' }}>Bandeira</th>
            <th style={{ fontSize: 10, textAlign: '-webkit-center' }}>Transações</th>
            <th style={{ fontSize: 10, textAlign: '-webkit-center' }}>Líquido</th>
            {/* <th style={{fontSize:10,textAlign:'-webkit-center'}}>Bruto</th> */}

          </tr>
        </thead>
        <tbody>
          {recebiveisPagamento.map((transaction, index) => (
            <tr key={index} style={{ borderBottom: 'solid 1px #999' }}>
              <td style={{ padding: '8px', textAlign: 'center' }}>
                <p style={{ fontFamily: 'Montserrat', fontSize: '6px', fontWeight: '400', color: '#3D3D3D', margin: '0' }}>
                  {transaction.data_ref}
                </p>
              </td>
              <td style={{ padding: '8px', textAlign: 'center' }}>
                {bandeiraTipoMobile(transaction.bandeira)}
                {/* <p style={{ fontFamily: 'Montserrat', fontSize: '9px', fontWeight: '400', color: '#3D3D3D', margin: '0', whiteSpace: 'nowrap' }}>
                {transaction.bandeira}
              </p> */}
              </td>
              <td style={{ padding: '8px', textAlign: 'center' }}>
                <p style={{ fontFamily: 'Montserrat', fontSize: '9px', fontWeight: '400', color: '#3D3D3D', margin: '0' }}>
                  {transaction.quantidade_transacoes}
                </p>
              </td>
              <td style={{ padding: '8px', textAlign: 'center' }}>
                <p style={{ fontFamily: 'Montserrat', fontSize: '9px', fontWeight: '400', color: '#3D3D3D', margin: '0', whiteSpace: 'nowrap' }}>
                  {transaction.liquido}
                </p>
              </td>
              {/* <td style={{ padding: '8px', textAlign: 'center' }}>
              <p style={{ fontFamily: 'Montserrat', fontSize: '9px', fontWeight: '600', color: '#3D3D3D', margin: '0', whiteSpace: 'nowrap' }}>
                {transaction.bruto}
              </p>
            </td> */}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };




  const TabelaRecebiveisMobile = () => {
    return (
      <>
        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', width: '100%' }}>
          <TableComponent recebiveisPagamento={recebiveisPagamento} bandeiraTipoMobile={bandeiraTipoMobile} />
        </div>
      </>
    )
  }




  if (isMobile == true) {
    return (
      <>
        <MenuMobile page="recebiveis" />

        <div style={{ height: 150, width: '100%', flexDirection: 'row', display: 'flex', padding: 10 }}>
          <div style={{ width: '100%', display: 'flex', flex: 1, margin: 5, borderRadius: 10, justifyContent: 'center', alignItems: 'center', flexDirection: 'column', backgroundColor: '#F1F1F1' }} onClick={() => navigate('/recebiveis')}>
            <FontAwesomeIcon icon={faCalendarDays} style={{ fontSize: 30, color: "#262626" }} />
            <span style={{ textAlign: 'center', fontSize: 14, marginTop: 10, color: "#262626" }}>recebiveis</span>
          </div>
          <div style={{ width: '100%', backgroundColor: '#142A30', display: 'flex', flex: 1, margin: 5, borderRadius: 10, justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }} onClick={() => navigate('/resumo_recebiveis')}>
            <FontAwesomeIcon icon={faCalendarCheck} style={{ fontSize: 30, color: "#FFFFFF" }} />
            <span style={{ textAlign: 'center', fontSize: 14, marginTop: 10, color: "#FFFFFF" }}>Resumo</span>
          </div>
        </div>

        <Resumo
          isMobile={isMobile}
          screenWidth={screenWidth}
          rows={lista}
          startDate={startDate}
          endDate={endDate}
          showCalendar={showCalendar}
          toggleCalendar={toggleCalendar}
          handleDateChange={handleDateChange}
          clickAplicar={clickAplicar}
          param={param}
          getDadosSQL={getDadosSQL}
          getDataInicial={getDataInicial}
          getDataFinal={getDataFinal}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          getPrimeiroDiaDoMes={getPrimeiroDiaDoMes}
          getUltimoDiaDoMes={getUltimoDiaDoMes}
        />
      </>
    )
  }

  const TabelaRecebiveis = () => {

    return (
      <>
        <div style={{ height: 500, overflow: 'scroll' }}>
          {recebiveisPagamento.map((transaction, index) => (
            <div style={{ borderBottom: 'solid 1px #999', margin: '10px' }}>
              <div style={{ marginBottom: '10px' }}>
                <div style={{ display: 'flex', alignItems: 'center', margin: '0', padding: '10px' }}>

                  <div style={{ flex: 1, height: 40, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                    <p style={{ fontFamily: 'Montserrat', fontSize: '12px', fontWeight: '400', color: '#3D3D3D', margin: '0' }}>{transaction.data_ref}</p>
                  </div>

                  <div style={{ flex: 1, height: 40, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                    {bandeiraTipo(transaction.bandeira)}
                  </div>

                  <div style={{ flex: 1, height: 40, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                    <p style={{ fontFamily: 'Montserrat', fontSize: '12px', fontWeight: '400', color: '#3D3D3D', margin: '0', whiteSpace: 'nowrap' }}>{transaction.bandeira}</p>
                  </div>

                  <div style={{ flex: 1, height: 40, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                    <p style={{ fontFamily: 'Montserrat', fontSize: '12px', fontWeight: '400', color: '#3D3D3D', margin: '0' }}>{transaction.quantidade_transacoes}</p>
                  </div>

                  <div style={{ flex: 1, height: 40, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                    <p style={{ fontFamily: 'Montserrat', fontSize: '12px', fontWeight: '400', color: '#3D3D3D', margin: '0', whiteSpace: 'nowrap' }}>{transaction.liquido}</p>
                  </div>

                  <div style={{ flex: 1, height: 40, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                    <p style={{ fontFamily: 'Montserrat', fontSize: '12px', fontWeight: '600', color: '#3D3D3D', margin: '0', whiteSpace: 'nowrap' }}>{transaction.bruto}</p>
                  </div>





                </div>
              </div>
            </div>
          ))}
        </div>
      </>
    )


  }

  // desktop
  return (
    <>

      <div style={{ maxWidth: '1920px', height: '100vh', display: "flex" }}>
        <div style={{ backgroundColor: '#262626' }}>
          <Menu page="recebiveis" menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
        </div>
        <div style={{ overflow: 'auto', width: '100%' }}>
          <Resumo
            isMobile={isMobile}
            screenWidth={screenWidth}
            rows={lista}
            startDate={startDate}
            endDate={endDate}
            showCalendar={showCalendar}
            toggleCalendar={toggleCalendar}
            handleDateChange={handleDateChange}
            clickAplicar={clickAplicar}
            param={param}
            getDadosSQL={getDadosSQL}
            getDataInicial={getDataInicial}
            getDataFinal={getDataFinal}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            getPrimeiroDiaDoMes={getPrimeiroDiaDoMes}
            getUltimoDiaDoMes={getUltimoDiaDoMes}
          />
        </div>
      </div>
    </>

  );

};

export default Recebiveis;

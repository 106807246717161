import { useState } from 'react';
import closeCircle from '../../assets/icons/closeCircle.png';
import { Modal, Grid, Typography, Button, OutlinedInput } from '@mui/material';
import Stylesheet from 'reactjs-stylesheet';

function ModalCashback({ modal, close, file, setFile, fieldName }) {

    const [selectedButton, setSelectedButton] = useState(0);
    const [products, setProducts] = useState(
        file ? file : {
            name: '',
            comicao: '',
            products: []
        }
    );

    const sendFile = () => {
        setFile(fieldName, products);
        close();
    };

    const changeTipe = (tipe) => {
        setSelectedButton(tipe);

        if (tipe == 1) {
            setProducts({
                name: 'Por produto',
                comicao: '',
                products: []
            })
        } else if (tipe == 2) {
            setProducts({
                name: 'Loja inteira',
                comicao: '',
                products: []
            })
        }
    };

    const addEmptyProduct = () => {
        setProducts({
            ...products,
            products: [...products.products, { name: '', cod: '', comicao: '' }] // adiciona um novo produto ao array dentro do objeto
        });
    };

    const handleChange = (index, field, value) => {
        const updatedProductsList = products.products.map((product, i) =>
            i === index ? { ...product, [field]: value } : product
        );
        setProducts({
            ...products,
            products: updatedProductsList // atualiza o array products no objeto principal
        });
    };

    return (
        <Modal open={modal} onClose={close}>
            <Grid
                sx={{
                    width: '100%',
                    maxWidth: '748px',
                    top: '50%',
                    left: '50%',
                    boxShadow: 24,
                    border: 'none',
                    bgcolor: '#F0EFEB',
                    padding: '46px 49px',
                    borderRadius: '20px',
                    position: 'absolute',
                    transform: 'translate(-50%, -50%)'
                }}
            >
                <Grid sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography sx={{ fontSize: '24px', color: '#142A30', fontWeight: 700 }}>
                        Cashback Xulis
                    </Typography>
                    <img
                        src={closeCircle}
                        onClick={close}
                        alt="Close"
                        style={{ width: '30px', height: '30px', cursor: 'pointer' }}
                    />
                </Grid>
                <Grid sx={{
                    width: '50ch',
                    display: 'flex',
                    paddingBottom: '35px',
                    flexDirection: 'column'
                }}>
                    <Typography sx={{ fontSize: '14px', color: '#142A3099' }}>
                        Escolha o tipo de cashback que deseja atribuir a cada venda realizada através do nosso aplicativo Xulis.
                    </Typography>
                </Grid>

                <Grid sx={{
                    gap: '15px',
                    display: 'flex',
                    flexDirection: 'row',
                    paddingBottom: '35px'
                }}>
                    <Button
                        onClick={() => changeTipe(1)}
                        sx={{
                            width: '100%',
                            borderRadius: '30px',
                            bgcolor: selectedButton == 1 ? '#17333A' : '#FEFDF9',
                            border: '1px solid #142A3066',
                            '&:hover': { backgroundColor: selectedButton == 1 ? '#17333A' : '#FEFDF9' },
                            '&:active': { backgroundColor: selectedButton == 1 ? '#17333A' : '#FEFDF9' }
                        }}
                    >
                        <Typography sx={{ color: selectedButton == 1 ? '#FFFFFF' : '#142A3066', fontWeight: 600, textTransform: 'none', padding: '3px' }}>
                            Por produto
                        </Typography>
                    </Button>
                    <Button
                        onClick={() => changeTipe(2)}
                        sx={{
                            width: '100%',
                            borderRadius: '30px',
                            bgcolor: selectedButton == 2 ? '#17333A' : '#FEFDF9',
                            border: '1px solid #142A3066',
                            '&:hover': { backgroundColor: selectedButton == 2 ? '#17333A' : '#FEFDF9' },
                            '&:active': { backgroundColor: selectedButton == 2 ? '#17333A' : '#FEFDF9' }
                        }}
                    >
                        <Typography sx={{ color: selectedButton == 2 ? '#FFFFFF' : '#142A3066', fontWeight: 600, textTransform: 'none', padding: '3px' }}>
                            Loja inteira
                        </Typography>
                    </Button>
                </Grid>

                {selectedButton ? <Grid>
                    <Grid sx={{
                        opacity: '0.7',
                        borderTopStyle: 'dashed',
                        borderTopWidth: '1.5px',
                        borderTopColor: '#142A3066',
                        paddingBottom: '25px',
                    }} />

                    {selectedButton == 1 ?
                        <Grid sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            bgcolor: '#FEFDF9',
                            borderRadius: '30px',
                            marginBottom: '15px'
                        }}>

                            <Grid sx={{
                                gap: '15px',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: '11px 25px 12px 25px',
                            }}>
                                <Typography sx={{ fontSize: '14px', color: '#142A30', fontWeight: 600 }}>
                                    Nome grupo produto
                                </Typography>
                                <Grid sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}>
                                    <Grid sx={{
                                        opacity: '0.7',
                                        borderStyle: 'dashed',
                                        borderWidth: '1.5px',
                                        borderColor: '#142A3066',
                                        paddingBottom: '14px',
                                    }} />
                                    <Typography sx={{ fontSize: '14px', color: '#142A30', fontWeight: 600, padding: '0px 35px' }}>
                                        Cód. Externo
                                    </Typography>
                                    <Grid sx={{
                                        opacity: '0.7',
                                        borderStyle: 'dashed',
                                        borderWidth: '1.5px',
                                        borderColor: '#142A3066',
                                        paddingBottom: '14px',
                                    }} />
                                    <Typography sx={{ fontSize: '14px', color: '#142A30', fontWeight: 600, padding: '0px 0px 0px 35px' }}>
                                        % Comissão Xulis
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid sx={{
                                opacity: '0.7',
                                borderTopStyle: 'solid',
                                borderTopWidth: '1.5px',
                                borderTopColor: '#142A3066',
                                paddingBottom: '17px',
                            }} />


                            <Grid sx={{
                                gap: '15px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                padding: '0px 25px 15px 25px',
                                flexDirection: 'column'
                            }}>

                                {products.products.map((i, index) => (
                                    <Grid key={index} sx={{
                                        gap: '15px',
                                        display: 'flex',
                                        flexDirection: 'row'
                                    }}>
                                        <Grid sx={{ display: 'flex', width: '50%' }}>
                                            <OutlinedInput
                                                sx={{
                                                    ...styles.customInputText,
                                                    flexBasis: '100%',
                                                    maxWidth: '100%',
                                                }}
                                                type="text"
                                                placeholder="*Nome"
                                                value={i.name}
                                                onChange={(e) => handleChange(index, 'name', e.target.value)}
                                            />
                                        </Grid>
                                        <Grid sx={{ gap: '15px', display: 'flex', width: '50%' }}>
                                            <OutlinedInput
                                                sx={{
                                                    ...styles.customInputNumber,
                                                    flexBasis: '5%',
                                                    maxWidth: '50%',
                                                }}
                                                type="text"
                                                placeholder="*Cod"
                                                value={i.cod}
                                                onChange={(e) => handleChange(index, 'cod', e.target.value)}
                                            />
                                            <OutlinedInput
                                                sx={{
                                                    ...styles.customInputNumber,
                                                    flexBasis: '5%',
                                                    maxWidth: '50%',
                                                    textAlign: 'center'
                                                }}
                                                type="text"
                                                placeholder="*Comissão"
                                                value={i.comicao}
                                                onChange={(e) => handleChange(index, 'comicao', e.target.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                ))}

                                <Button
                                    onClick={addEmptyProduct}
                                    sx={{
                                        color: '#142A3099',
                                        bgcolor: '#F0EFEB',
                                        borderRadius: '20px',
                                        textTransform: 'none',
                                        textAlign: 'start',
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        padding: '2px 10px'
                                    }}>
                                    +Adicionar novo grupo de produto
                                </Button>

                            </Grid>

                        </Grid>
                        :
                        <Grid sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            bgcolor: '#FEFDF9',
                            borderRadius: '30px',
                            marginBottom: '15px'
                        }}>

                            <Grid sx={{
                                gap: '15px',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: '11px 25px 12px 25px',
                            }}>
                                <Typography sx={{ fontSize: '14px', color: '#142A30', fontWeight: 600 }}>
                                    Explicação
                                </Typography>
                                <Grid sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}>
                                    <Grid sx={{
                                        opacity: '0.7',
                                        borderStyle: 'dashed',
                                        borderWidth: '1.5px',
                                        borderColor: '#142A3066',
                                        paddingBottom: '14px',
                                    }} />
                                    <Typography sx={{ fontSize: '14px', color: '#142A30', fontWeight: 600, padding: '0px 0px 0px 19px' }}>
                                        % Cashback Xulis
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid sx={{
                                opacity: '0.7',
                                borderTopStyle: 'solid',
                                borderTopWidth: '1.5px',
                                borderTopColor: '#142A3066',
                                paddingBottom: '17px',
                            }} />


                            <Grid sx={{
                                gap: '15px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                padding: '0px 25px 15px 25px',
                                flexDirection: 'column'
                            }}>

                                <Grid sx={{
                                    gap: '10px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between'
                                }}>
                                    <Grid sx={{
                                        width: '76%',
                                        display: 'flex',
                                        bgcolor: '#F0EFEB',
                                        borderRadius: '20px',
                                        padding: '2px 10px'
                                    }}>
                                        <Typography sx={{
                                            fontSize: '14px',
                                            // bgcolor: '#142A30'
                                        }}>
                                            Ao utilizar o app Xulis para efetuar compras na loja, você irá devolver 3% diretamente para a conta do seu cliente.
                                        </Typography>
                                    </Grid>
                                    <Grid sx={{ display: 'flex', width: '23%' }}>
                                        <OutlinedInput
                                            sx={{
                                                ...styles.customInputNumber2,
                                                flexBasis: '100%',
                                                maxWidth: '100%',

                                            }}
                                            type="text"
                                            placeholder="*Cashback"
                                            value={products.comicao}
                                            onChange={(e) => setProducts({
                                                ...products,
                                                comicao: e.target.value,
                                                products: []
                                            })}

                                        />
                                    </Grid>
                                </Grid>

                            </Grid>

                        </Grid>
                    }
                </Grid> : null}

                <Button
                    onClick={sendFile}
                    sx={{
                        width: '100%',
                        borderRadius: '30px',
                        bgcolor: '#17333A',
                        '&:hover': { backgroundColor: '#142A30' },
                        '&:active': { backgroundColor: '#142A30' }
                    }}
                >
                    <Typography sx={{ color: '#FFFFFF', textTransform: 'none', padding: '3px' }}>
                        Enviar
                    </Typography>
                </Button>
            </Grid>
        </Modal >
    );
}

export default ModalCashback;

const styles = Stylesheet.create({
    customInputText: {
        flexGrow: 1,
        flexShrink: 1,
        // minWidth: '340px',
        borderRadius: '15px',
        '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
        },
        '& .MuiOutlinedInput-input': {
            display: 'flex',
            justifyContent: 'center',
            borderRadius: '30px',
            // border: '2px solid #142A3066',
            cursor: 'pointer'
        },
        '& .MuiInputBase-input': {
            color: '#142A30',
            // fontWeight: 'bold',
            fontSize: '14px',
            // fontSize: calcMimMax(12, 16, 100),
            padding: '2px 10px',
            bgcolor: '#F0EFEB',
        },
        '& .MuiInputBase-input::placeholder': {
            fontSize: '14px',
            // fontSize: calcMimMax(12, 16, 100),
            color: '#142A3066',
            opacity: 1,
            // fontWeight: 'bold'
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
    },
    customInputNumber: {
        flexGrow: 1,
        flexShrink: 1,
        // minWidth: '340px',
        borderRadius: '15px',
        '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
        },
        '& .MuiOutlinedInput-input': {
            display: 'flex',
            justifyContent: 'center',
            borderRadius: '30px',
            // border: '2px solid #142A3066',
            cursor: 'pointer',
            textAlign: 'center'
        },
        '& .MuiInputBase-input': {
            color: '#142A30',
            // fontWeight: 'bold',
            fontSize: '14px',
            // fontSize: calcMimMax(12, 16, 100),
            padding: '2px 10px',
            bgcolor: '#F0EFEB',
        },
        '& .MuiInputBase-input::placeholder': {
            fontSize: '14px',
            // fontSize: calcMimMax(12, 16, 100),
            color: '#142A3066',
            opacity: 1,
            // fontWeight: 'bold'
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
    },
    customInputNumber2: {
        flexGrow: 1,
        flexShrink: 1,
        // minWidth: '340px',
        borderRadius: '15px',
        '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
        },
        '& .MuiOutlinedInput-input': {
            display: 'flex',
            justifyContent: 'center',
            borderRadius: '20px',
            // border: '2px solid #142A3066',
            cursor: 'pointer',
            textAlign: 'center',
            height: '54px',
        },
        '& .MuiInputBase-input': {
            color: '#142A30',
            // fontWeight: 'bold',
            fontSize: '14px',
            // fontSize: calcMimMax(12, 16, 100),
            padding: '2px 10px',
            bgcolor: '#F0EFEB',
        },
        '& .MuiInputBase-input::placeholder': {
            fontSize: '14px',
            // fontSize: calcMimMax(12, 16, 100),
            color: '#142A3066',
            opacity: 1,
            // fontWeight: 'bold'
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
    }
})
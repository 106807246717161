import styles from "./LoginAreaparceiros.module.css";
// import img from './assets/fudo_esqueciCopia.png'; // Tell webpack this JS file uses this image
import img from './assets/imgs/bg.padrao.password.png'; // Tell webpack this JS file uses this image
import logo1 from './assets/logos/logo.xulis.name.png'; // Tell webpack this JS file uses this image
// import Input from "./Input";
import grupo667 from './assets/grupo667.png'; // Tell webpack this JS file uses this image
import { useState, useEffect } from "react";
import { Typography } from "@mui/material";

const Swal = require('sweetalert2')

const EsqueciSenha = () => {

  const [pageRef, setPageRef] = useState('sucesso');
  const [email, setEmail] = useState('');
  const [isMobile, setIsMobile] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    updateDimensions();
    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);


  const updateDimensions = () => {
    setIsMobile(window.innerWidth <= 800); // Defina o limite de largura para o modo mobile conforme necessário
  };



  const clickEnviar = async () => {
    var email_id = document.getElementById('email_id').value;

    if (email_id == "") {
      Swal.fire({
        icon: 'warning',
        title: 'Digite seu email',
      })
      return false;
    }

    const form = new FormData();
    form.append("metodo", "ReenviarSenha");
    form.append("idempresa", "1350");
    form.append("token", "e84d7ad2-f0cc-11eb-b838-568a76eb275e");
    form.append("usuario", email_id);

    const options = {
      method: 'POST',
    };

    options.body = form;

    setLoading(true)

    fetch('https://xuliz.com.br/painel/integracao/usuario.php?t=2', options)
      .then(response => response.json())
      .then(response => {
        tratarInfo(response)
      })
      .catch(err => {
        setLoading(false)
        Swal.fire(
          '',
          'Aconteceu um erro e caso o problema persista, favor entrar em contato com suporte@xuliz.com.br',
          'error'
        )
      });
  }

  const clickOK = async () => {
    location.href = './';
  }


  const tratarInfo = async (dados) => {
    console.log(dados)
    setLoading(false)
    try {

      if (dados.erro == "S") {
        Swal.fire(
          '',
          dados.mensagem,
          'warning'
        )

        return false;
      }


      await Swal.fire(
        '',
        dados.mensagem,
        'success'
      ).then(() => {
        location.href = './'
      })





    } catch (error) {
      console.log(error)
      Swal.fire(
        'Ops!',
        'Aconteceu um erro e caso o problema persista, favor entrar em contato com suporte@xuliz.com.br',
        'error'
      )
    }


  }


  //MOBIL SCREEN
  if (isMobile == true) {

    return (
      <div style={{ display: 'flex', backgroundColor: '#17333A', width: '100vw', height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <img src={logo1} style={{ width: 130, marginBottom: 50 }} />

          <form style={{ width: '80%' }}>
            <div class="mb-3" style={{ textAlign: 'center' }}>
              <label htmlFor="exampleInputEmail1" class="form-label"
                style={{ color: '#FFF8EA', fontSize: '1.2rem' }}>
                Recuperar acesso</label>
              <label style={{ fontSize: 12, color: '#FFFFFF99', marginBottom: 30 }}>
                Informe os seguintes dados e clique no botão enviar para que seja enviada o passo a passo, via e-mail.
              </label>
            </div>

            <div class="mb-3">
              <label style={{ color: "#FFFFFF" }} htmlFor="exampleInputPassword1" class="form-label">Usuário</label>
              <input type="text" class="form-control" id="email_id" aria-describedby="emailHelp" />
            </div>

            <div class="d-grid gap-2" style={{ textAlign: 'center' }}>
              {!loading && (
                <button
                  class="btn btn-primary"
                  type="button"
                  style={{ height: 50, backgroundColor: '#FFF8EA', borderColor: '#262626', color: '#17333A', marginTop: 40, fontWeight: '700' }}
                  onClick={() => clickEnviar()}
                >
                  Enviar
                </button>
              )}
              <a
                href="./"
                class="btn btn-primary"
                type="button"
                style={{ alignContent: 'center', height: 50, backgroundColor: '#17333A', borderColor: '#FFF8EA', color: '#FFF8EA', marginTop: 11, fontWeight: '700' }}>
                Voltar
              </a>
            </div>
          </form>
        </div>
      </div>
    )

    // return (
    //   <div style={{ display: 'flex', backgroundColor: '#f1e2c4', width: '100vw', height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
    //     <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
    //       <img src={logo1} style={{ width: 100, marginBottom: 50 }} />
    //       <form style={{ maxWidth: 300 }}>
    //         <div class="mb-3">
    //           <label for="exampleInputEmail1" class="form-label" style={{ width: '100%', textAlign: '-webkit-center' }}>Recuperar acesso</label>
    //           <label for="exampleInputEmail1" class="form-label" style={{ width: '100%', textAlign: '-webkit-center', fontSize: 12, color: '#606060', marginBottom: 30 }}>Informe os seguintes dados e clique no botão enviar para que seja enviada o passo a passo, via e-mail.</label>
    //         </div>
    //         <div class="mb-3">
    //           <label for="exampleInputPassword1" class="form-label">Usuário</label>
    //           <input type="text" class="form-control" id="email_id" />
    //         </div>
    //         <label for="exampleInputEmail1" class="form-label" style={{ width: '100%', textAlign: '-webkit-center' }}>
    //           <img src={grupo667} style={{ height: 15, marginBottom: 20, marginTop: 30 }} ></img>
    //         </label>
    //         <div class="d-grid gap-2" style={{ textAlign: '-webkit-center' }}>
    //           {loading == false && (
    //             <button class="btn btn-primary" type="button" style={{ height: 50, backgroundColor: '#f1e2c4', borderColor: '#262626', color: '#262626', marginTop: 40 }}
    //               onClick={() => clickEnviar()}>
    //               Enviar
    //             </button>)}
    //           <a href="./" style={{ color: '#262626' }}>Voltar</a>
    //         </div>
    //       </form>
    //     </div>
    //   </div>
    // )
  }


  //DESKTOP SCREEN
  if (isMobile == false) {

    return (
      <div style={{ flex: 1, display: 'flex', backgroundColor: '#17333A', height: '100vh' }}>
        <div style={{ width: '70%' }} id="home2">
          <img src={img} style={{ objectFit: 'cover', width: '100%', height: '100%' }} />
        </div>

        <div style={{ width: '30%', backgroundColor: '#17333A', display: "flex", padding: 50 }} id="home3">
          <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'center' }}>

            <div style={{ marginBottom: 40 }}>
              <img src={logo1} style={{ width: '130px', marginBottom: 50 }} />
              <Typography
                style={{
                  fontSize: 24,
                  color: '#FFFFFF',
                  fontWeight: 'bold',
                }}>
                Recupere seu acesso!
              </Typography>
              <Typography
                style={{
                  fontSize: 16,
                  color: '#FFFFFF99'
                }}>
                Fique tranquilo, vamos ajudá-lo a recuperar sua
                senha. É simples e rápido! Digite seu usuário e
                enviamos todo o passo a passo para seu e-mail.
              </Typography>
            </div>

            <form style={{ width: '100%', borderTop: "1px solid #9DE200" }}>
              <div className="mb-3 mt-5">
                <input style={{
                  borderRadius: 15,
                  height: 48
                }}
                  type="email"
                  className="form-control"
                  id="email_id"
                  aria-describedby="emailHelp"
                  placeholder="Digite seu usuário"
                />
              </div>
              <div className="d-grid gap-2" style={{ textAlign: 'center' }}>
                <button
                  className="btn btn-primary"
                  type="button"
                  style={{
                    height: 50,
                    marginTop: 40,
                    borderRadius: 15,
                    color: '#17333A',
                    fontWeight: '700',
                    borderColor: '#FFF8EA',
                    backgroundColor: '#FFF8EA',
                  }}
                  onClick={clickEnviar}
                >
                  Enviar
                </button>
                <a
                  href="./"
                  className="btn btn-primary"
                  type="button"
                  style={{
                    height: 50,
                    marginTop: 11,
                    borderRadius: 15,
                    color: '#FFF8EA',
                    fontWeight: '700',
                    alignContent: 'center',
                    borderColor: '#FFF8EA',
                    backgroundColor: '#17333A',
                  }}
                >
                  Voltar
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
    );

    // return (
    //   <div className={styles.home}>
    //     <div style={{ backgroundColor: '#f1e2c4', display: "flex" }} id="home2">
    //       <img src={img} style={{ objectFit: 'cover' }} />
    //     </div>
    //     <div style={{ backgroundColor: '#f1e2c4', display: "flex", flex: 2, padding: 50, maxWidth: 1000 }} id="home3">
    //       <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
    //         <img src={logo1} style={{ width: 100, marginBottom: 50 }} />
    //         <form style={{ maxWidth: 300 }}>
    //           <div class="mb-3">
    //             <label for="exampleInputEmail1" class="form-label" style={{ width: '100%', textAlign: '-webkit-center' }}>Recuperar acesso</label>
    //             <label for="exampleInputEmail1" class="form-label" style={{ width: '100%', textAlign: '-webkit-center', fontSize: 12, color: '#606060', marginBottom: 30 }}>Informe os seguintes dados e clique no botão enviar para que seja enviada o passo a passo, via e-mail.</label>
    //           </div>
    //           <div class="mb-3">
    //             <label for="exampleInputPassword1" class="form-label">Usuário</label>
    //             <input type="text" class="form-control" id="email_id" />
    //           </div>
    //           <label for="exampleInputEmail1" class="form-label" style={{ width: '100%', textAlign: '-webkit-center' }}>
    //             <img src={grupo667} style={{ height: 15, marginBottom: 20, marginTop: 30 }} ></img>
    //           </label>
    //           <div class="d-grid gap-2" style={{ textAlign: '-webkit-center' }}>
    //             {loading == false && (<button class="btn btn-primary" type="button" style={{ height: 50, backgroundColor: '#f1e2c4', borderColor: '#262626', color: '#262626', marginTop: 40 }} onClick={() => clickEnviar()}>Enviar</button>)}
    //             <a href="./" style={{ color: '#262626' }}>Voltar</a>
    //           </div>
    //         </form>
    //       </div>
    //     </div>
    //   </div>
    // );
  };
}
// DESKTOP SCREEN

export default EsqueciSenha;

import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import bagOn from "../assets/icons/bagOn.png"
import bagOff from "../assets/icons/bagOff.png"
import marketingOn from "../assets/icons/funnelOn.png"
import marketingOff from "../assets/icons/funnelOff.png"
import dolarOn from "../assets/icons/dolarOn.png"
import dolarOff from "../assets/icons/dolarOff.png"
import arrow from "../assets/icons/arrowRigthWhite.png"

import sair from "../assets/icons/exit.png"
import configuracoes from "../assets/icons/config.png"
import logo_parceiros from '../assets/logos/logo.parceiros.png';
import logo_xulis from '../assets/logos/logo.xulis.name.png';
import estabelecimentoIcon from '../assets/icons/estabelecimento.png';
import doubleArrow from '../assets/icons/doubleArrow.png';
import closeIcon from '../assets/icons/closeIcon.png';
import logo from '../assets/logos/logo.png';
import MenuOpen from "./MenuOpen";
import MenuClose from "./MenuClose";
import { Button, Grid, Modal, OutlinedInput, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faSearch } from "@fortawesome/free-solid-svg-icons";
import Stylesheet from "reactjs-stylesheet";
import axios from "axios";


const MenuMobile = ({ page, menuOpen, setMenuOpen }) => {
    const navigate = useNavigate();
    const Swal = require('sweetalert2')

    const [pageRef, setPageRef] = useState(page);
    const [nome, setNome] = useState('');
    const [usuario, setUsuario] = useState('');
    const [urlLogo, setUrlLogo] = useState('');
    const [estabelecimento, setEstabelecimento] = useState({})
    const [estabelecimentos, setEstabelecimentos] = useState([])
    const [modal, setModal] = useState(false)
    const [isAtBottom, setIsAtBottom] = useState(false);
    const [filtro, setFiltro] = useState(''); // Estado para o valor da pesquisa
    const [resultadosFiltrados, setResultadosFiltrados] = useState([]);

    // Função para lidar com a entrada do campo de pesquisa
    const handleSearch = (e) => {
        const valor = e.target.value || ''; // Garante que seja uma string
        setFiltro(valor);
    };

    // Atualiza a lista de resultados filtrados sempre que `filtro` mudar
    useEffect(() => {
        // Verifica se há uma correspondência exata
        let resultados = estabelecimentos.filter(
            (estabelecimento) =>
                estabelecimento.idestabelecimento.toString().includes(filtro) || // Comparação exata com o id
                estabelecimento.estabelecimento?.toLowerCase() === filtro.toLowerCase() || // Nome exato
                estabelecimento.cnpj === filtro // CNPJ exato
        );

        // Se não encontrar nada, faz a busca parcial
        if (resultados.length === 0) {
            resultados = estabelecimentos.filter(
                (estabelecimento) =>
                    estabelecimento.idestabelecimento.toString().includes(filtro) || // Busca parcial no id
                    estabelecimento.estabelecimento?.toLowerCase().includes(filtro.toLowerCase()) || // Nome parcial
                    estabelecimento.cnpj.includes(filtro) // CNPJ parcial
            );
        }

        // Ordena os resultados de forma crescente por `idestabelecimento`
        resultados.sort((a, b) => a.idestabelecimento - b.idestabelecimento);

        setResultadosFiltrados(resultados);
    }, [filtro, estabelecimentos]);


    const handleScroll = (e) => {
        const { scrollTop, scrollHeight, clientHeight } = e.target;
        if (scrollTop + clientHeight >= scrollHeight) {
            setIsAtBottom(true)
        } else {
            setIsAtBottom(false)
        }
    };

    const onGroupContainer13Click = useCallback(() => {
        localStorage.clear()
        navigate("/");
    }, [navigate]);

    useEffect(() => {
        getDados();
    }, []);

    const getDados = async () => {
        var dados = await localStorage.getItem('login')

        if (dados == null) {
            return false;
        }

        dados = JSON.parse(dados)
        var dadosX = dados;

        setNome(dadosX.nome)
        setUsuario(dadosX.empresa)
        setUrlLogo(dadosX.urllogo)
        verificarCDL(dadosX)
        // getTokenEstabelciment(dadosX.idestabelecimento);
    }

    const verificarCDL = (dadosX) => {
        var idparceiro = dadosX.parceiros;

        if (idparceiro == null) {
            document.getElementById('menu_marketing').style.display = 'none'
            return false;
        }

        if (idparceiro.length == 0) {
            document.getElementById('menu_marketing').style.display = 'none'
            return false;
        }

        if (idparceiro[0] == null) {
            document.getElementById('menu_marketing').style.display = 'none'
            return false;
        }
    }

    const irPage = (page) => {
        location.href = './' + page
    }

    const getTokenEstabelciment = async (id) => {
 
        const options = {
            method: 'POST',
            headers: {
                'consumerkey': '64df72043ca4b',
                'consumersecret': '64df72043ca4f',
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({
                idestabelecimento: '',
                token: '',
                idempresagrupo: ''
            }),
            mode: 'no-cors',
        };

        fetch('https://www.xuliz.com.br/painel/integracao/estabelecimento/listar', options)
            .then(response => response.json())
            .then(response => console.log(response))
            .catch(err => console.error(err));

        // const form = new FormData();
        // form.append("idempresa", "1350");
        // form.append("token", "e84d7ad2-f0cc-11eb-b838-568a76eb275e");
        // form.append("metodo", "ListaEstabelecimentos");

        // const options = { method: 'POST' };
        // options.body = form;
        // var rres = await fetch('https://xuliz.com.br/painel/integracao/estabelecimento.php', options).then(response => response.json());
        // var arrayEstabelecimnto = rres.estabelecimentos;

        // // console.log('idsss', id)
        // // console.log('arrayEstabelecimnto', arrayEstabelecimnto)

        // var estabelecimento = [];
        // for (var x = 0; x < arrayEstabelecimnto.length; x++) {
        //     if (arrayEstabelecimnto[x].idestabelecimento == id) {
        //         estabelecimento = arrayEstabelecimnto[x];
        //         setEstabelecimento(estabelecimento);
        //         break;
        //     }
        // }

        // setEstabelecimentos(arrayEstabelecimnto)
        // return arrayEstabelecimnto;
    }


    const Estabelecimentos = () => {
        return (
            <Grid>
                <Grid sx={{
                    gap: '12px',
                    display: 'flex',
                    flexDirection: 'column',
                    bgcolor: '#2F4650',
                    padding: '20px',
                    borderTopLeftRadius: '15px',
                    borderTopRightRadius: '15px'
                }}>
                    <Grid sx={{
                        width: '30px',
                        height: '30px',
                        display: 'flex',
                        bgcolor: '#0D2128',
                        borderRadius: '11px',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <img src={estabelecimentoIcon} style={{ width: 14, height: 14 }} />
                    </Grid>
                    <Grid>
                        <Typography sx={{ color: '#FFFFFF', fontSize: '12px' }}>{estabelecimento.estabelecimento}</Typography>
                        <Typography sx={{ color: '#7998A3', fontSize: '11px' }}>{estabelecimento.cnpj}</Typography>
                    </Grid>
                </Grid>
                <Grid sx={{
                    padding: '5px 14px',
                    border: '1px solid #2F4650',
                    borderBottomLeftRadius: '15px',
                    borderBottomRightRadius: '15px'
                }}>
                    <Button
                        onClick={() => setModal(true)}
                        sx={{
                            gap: '9px',
                            display: 'flex',
                            flexDirection: 'row',
                            textTransform: 'none',
                            justifyContent: 'center',
                        }}>
                        <img src={doubleArrow} style={{ width: 10, height: 10 }} />
                        <Grid>
                            <Typography sx={{ color: '#FFFFFF', fontSize: '11px' }}>Trocar de estabelecimento</Typography>
                        </Grid>
                    </Button>
                </Grid>

                {/* Modal para trocar de estabelecimento */}
                <Modal open={modal} onClose={() => { setModal(false); setIsAtBottom(false); }}>
                    <Grid
                        sx={{
                            width: '462px',
                            top: '50%',
                            left: '50%',
                            boxShadow: 24,
                            border: 'none',
                            bgcolor: 'red',
                            borderRadius: '20px',
                            position: 'absolute',
                            transform: 'translate(-50%, -50%)'
                        }}
                    >
                        <Grid sx={{
                            bgcolor: '#142A30',
                            borderTopLeftRadius: '20px',
                            borderTopRightRadius: '20px'
                        }}>
                            <Grid sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: '35px 30px 0px 30px'
                            }}>
                                <img src={logo_xulis} style={{ width: 95, height: 'auto' }} />
                                <img onClick={() => { setModal(false); setIsAtBottom(false); }} src={closeIcon} style={{ width: 25, height: 25, cursor: 'pointer' }} />
                            </Grid>
                            <Grid sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                alignItems: 'flex-start',
                                padding: '40px 30px 35px 30px'
                            }}>
                                <Typography sx={{ color: '#FFFFFF', fontSize: '20px', fontWeight: 500 }}>{nome}</Typography>
                                <Grid sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                                    <Typography sx={{ color: '#FFFFFF', fontSize: '12px', fontWeight: 100 }}>
                                        <span style={{ fontWeight: 500 }}>Você está em:</span> {estabelecimento.estabelecimento}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid sx={{
                            bgcolor: '#FFFFFF',
                            borderBottomLeftRadius: '20px',
                            borderBottomRightRadius: '20px',
                        }}>
                            <Grid sx={{ width: '100%', display: 'flex', justifyContent: 'center', bgcolor: '#142A3066', padding: '13px 30px' }}>
                                <Grid sx={styles.gridSearch}>
                                    <OutlinedInput
                                        sx={{
                                            ...styles.customInputCod,
                                            '& .MuiInputBase-input': {
                                                color: '#142A30'
                                            },
                                            '& .MuiInputBase-input::placeholder': {
                                                opacity: 1,
                                                fontSize: '15px',
                                                color: '#142A3099',
                                            },
                                            width: '100%',
                                            maxWidth: '480px'
                                        }}
                                        placeholder="Pesquisar unidade"
                                        value={filtro}
                                        onChange={handleSearch}
                                    />
                                    <FontAwesomeIcon icon={faSearch} style={styles.iconSearch} />
                                </Grid>
                            </Grid>

                            <Grid
                                onScroll={handleScroll}
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    height: 280,
                                    overflowY: 'auto',
                                    direction: 'rtl', // Mantém a barra de rolagem à esquerda
                                    '&::-webkit-scrollbar': {
                                        width: '20px', // Largura da barra de rolagem
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                        height: '42px',
                                        backgroundColor: '#17333A', // Cor da barra
                                        borderBottomLeftRadius: isAtBottom ? '20px' : '0px',
                                        border: '1px solid trasparent', // Espaço para adicionar efeito de "padding"
                                        backgroundClip: 'content-box', // Para manter o border transparente
                                    },
                                    '&::-webkit-scrollbar-track': {
                                        backgroundColor: '#F0EFEB', // Cor do track (fundo da barra)
                                        borderBottomLeftRadius: '20px',
                                    },
                                    '&::-webkit-scrollbar-thumb:hover': {
                                        backgroundColor: '#17333A', // Cor ao passar o mouse sobre a barra
                                    },
                                }}
                            >
                                <Grid sx={{
                                    width: '100%',
                                    direction: 'ltr',
                                    paddingTop: '20px'
                                }}>
                                    {(resultadosFiltrados.length > 0 ? resultadosFiltrados : estabelecimentos).map((i, index) => (
                                        <Grid sx={{
                                            height: '80px'
                                        }}>
                                            <Grid sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                bgcolor: '#FFFFFF',
                                                margin: '0px 30px 0px 0px',
                                                cursor: 'pointer',
                                                '&:active': {
                                                    backgroundColor: '#F0EFEB'
                                                },
                                            }}>
                                                <Grid>
                                                    <Typography sx={{ marginLeft: '35px', fontSize: '14px', color: '#142A30', fontWeight: 700 }}>
                                                        {i.idestabelecimento}. {i.estabelecimento}
                                                    </Typography>
                                                    <Typography sx={{ marginLeft: '35px', fontSize: '12px', color: '#142A3099' }}>
                                                        {i.cnpj}
                                                    </Typography>
                                                </Grid>
                                                <FontAwesomeIcon
                                                    icon={faChevronRight}
                                                    style={{ color: '#142A30', width: '10px', height: '15px' }}
                                                />
                                            </Grid>
                                            <Grid sx={{ marginInline: '20px', width: '90%', paddingTop: '15px', borderBottom: '1px solid #142A3099' }} />
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Modal >
            </Grid >
        );
    };

    return (
        <>

            {menuOpen ?
                <MenuOpen
                    setMenuOpen={setMenuOpen}
                    logo_parceiros={logo_parceiros}
                    bagOn={bagOn}
                    bagOff={bagOff}
                    marketingOn={marketingOn}
                    marketingOff={marketingOff}
                    dolarOn={dolarOn}
                    dolarOff={dolarOff}
                    sair={sair}
                    configuracoes={configuracoes}
                    arrow={arrow}
                    pageRef={pageRef}
                    nome={nome}
                    usuario={usuario}
                    urlLogo={urlLogo}
                    irPage={irPage}
                    onGroupContainer13Click={onGroupContainer13Click}
                    Estabelecimentos={Estabelecimentos}
                />
                :
                <MenuClose
                    setMenuOpen={setMenuOpen}
                    logo={logo}
                    bagOn={bagOn}
                    bagOff={bagOff}
                    marketingOn={marketingOn}
                    marketingOff={marketingOff}
                    dolarOn={dolarOn}
                    dolarOff={dolarOff}
                    sair={sair}
                    configuracoes={configuracoes}
                    arrow={arrow}
                    pageRef={pageRef}
                    usuario={usuario}
                    urlLogo={urlLogo}
                    irPage={irPage}
                    onGroupContainer13Click={onGroupContainer13Click}
                    Estabelecimentos={Estabelecimentos}
                />
            }
        </>
    )
}

export default MenuMobile;

const styles = Stylesheet.create({
    customInputCod: {
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
        }
    },
    gridHeader: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        paddingBottom: '28px',
        alignItems: 'flex-end',
        justifyContent: 'space-between'
    },
    gridArrow: {
        gap: '10px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    boxArrow: {
        width: '34px',
        display: 'flex',
        bgcolor: '#142A30',
        borderRadius: '10px',
        justifyContent: 'center'
    },
    arrow: {
        padding: '7px',
        color: "#FFFFFF"
    },
    gridSearch: {
        width: '100%',
        height: '35px',
        display: 'flex',
        justifyContent: 'space-between',
        bgcolor: '#FEFDF9',
        padding: '5px 10px',
        borderRadius: '20px',
        flexDirection: 'row',
        alignItems: 'center',
        border: '2px solid #142A3099'
    },
    iconSearch: {
        width: '15px',
        height: '15px',
        color: "#142A3099"
    }
});
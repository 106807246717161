import Stylesheet from "reactjs-stylesheet";
import {
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import waiting from '../assets/waiting.jpg';
import visa from '../assets/visa.png';
import master from '../assets/master.png';
import elo from '../assets/elo_debito.png';
import hipercard from '../assets/hipercard.png';
import Pix from '../assets/Pix.png';
import amex from '../assets/amex.png';

import flagXulis from '../assets/flags/flagXulis.png';
import { format } from "date-fns";

const Tabela = ({ loading, rows }) => {

    const columns = [
        { status: 'status', label: 'Status' },
        { valor_venda: 'valor_venda', label: 'Valor venda' },
        { bandeira: 'bandeira', label: 'Bandeira' },
        { dataHora: 'dataHora', label: 'Hora' },
        { id: 'id', label: 'ID#' },
    ];

    const verDetalhes = async (dados) => {
        var data = dados.dataHora;
        data = data.split(" ")
        data = data[0]
        location.href = './detalhesvendas?id=' + dados.id + '&data=' + data
    }

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        }).format(parseFloat(value.replace(/[^\d.-]/g, '')));
    };

    return (
        <Paper xs={12} elevation={0} sx={{ width: '100%', overflow: 'hidden' }} >
            <TableContainer xs={6} sx={{
                maxHeight: 720,
                overflowY: 'auto',
                direction: 'rtl', // Mantém a barra de rolagem à esquerda
                '&::-webkit-scrollbar': {
                    width: '6px', // Largura da barra de rolagem
                    height: '6px', // Largura da barra de rolagem
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#5A8292', // Cor da barra
                    borderRadius: '10px', // Borda arredondada
                    border: '1px solid trasparent', // Espaço para adicionar efeito de "padding"
                    backgroundClip: 'content-box', // Para manter o border transparente
                },
                '&::-webkit-scrollbar-track': {
                    backgroundColor: '#F0EFEB', // Cor do track (fundo da barra)
                    borderRadius: '10px',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                    backgroundColor: '#555', // Cor ao passar o mouse sobre a barra
                },
            }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead >
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    sx={{ fontSize: '15px', color: '#142A3099' }}
                                    key={column.id}
                                    align={column.align}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => {
                            return (
                                <TableRow key={row.id}>
                                    <TableCell component="th" scope="row">
                                        <Grid sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
                                            {/* <Typography sx={styles.actionTable} onClick={() => { verDetalhes(row) }}>⋮</Typography> */}
                                            <Typography sx={styles.statusTable}>{row.status}</Typography>
                                        </Grid>
                                    </TableCell>
                                    <TableCell component="th" sx={styles.textTable}>
                                        {formatCurrency(row.valor_venda)}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.bandeira ? (
                                            <>
                                                {row.bandeira === 'XULIZ' || row.bandeira === 'Xuliz' ? (
                                                    <img src={flagXulis} style={{ width: 50, borderRadius: 4 }} alt="Bandeira XULIZ" />
                                                ) : row.bandeira === 'Visa Crédito' || row.bandeira === "VISA" || row.bandeira === "VISA ELECTRON" || row.bandeira === 'Visa Débito' ? (
                                                    <img src={visa} style={{ width: 50, borderRadius: 4 }} alt="Visa" />
                                                ) : row.bandeira === 'Master Crédito' || row.bandeira === "MASTERCARD" || row.bandeira === "MAESTRO" || row.bandeira === 'Master Débito' ? (
                                                    <img src={master} style={{ width: 50, borderRadius: 4 }} alt="MasterCard" />
                                                ) : row.bandeira === 'Elo Crédito' || row.bandeira === 'ELO DEBITO' || row.bandeira === 'Elo Débito' ? (
                                                    <img src={elo} style={{ width: 50, borderRadius: 4 }} alt="Elo" />
                                                ) : row.bandeira === 'Amex Crédito' || row.bandeira === 'Amex Débito' ? (
                                                    <img src={amex} style={{ width: 50, borderRadius: 4 }} alt="American Express" />
                                                ) : row.bandeira === 'Hipercard Crédito' || row.bandeira === 'Hipercard Débito' || row.bandeira === 'Hiper Crédito' || row.bandeira === 'Hiper Débito' ? (
                                                    <img src={hipercard} style={{ width: 50, borderRadius: 4 }} alt="Hipercard" />
                                                ) : row.bandeira === '-' ? (
                                                    <>
                                                        <img src={Pix} style={{ width: 50, borderRadius: 4 }} alt="Pix" />
                                                    </>
                                                ) : (
                                                    <p style={{ marginBottom: 0 }}>{row.bandeira}</p>
                                                )}
                                            </>
                                        ) : null}
                                    </TableCell>
                                    <TableCell component="th" scope="row" sx={styles.textTable}>
                                        {format(row.dataHora, "HH:mm")}
                                    </TableCell>
                                    <TableCell component="th" scope="row" sx={styles.textTable}>
                                        {row.id}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
                {loading ?
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: 30 }} id="loading">
                        <img src={waiting} style={{ width: 50, height: 50 }} />
                    </div> : ''}

            </TableContainer>
        </Paper >
    );
};

export default Tabela;

const styles = Stylesheet.create({
    textTable: {
        color: '#142A30',
        fontSize: '15px',
        fontWeight: 700,
        textAlign: 'end',
        marginBottom: 0,
    },
    taxaTable: {
        color: '#E95454',
        fontSize: '15px',
        fontWeight: 700,
        textAlign: 'end',
        marginBottom: 0,
    },
    statusTable: {
        color: '#7FA800',
        fontSize: '15px',
        fontWeight: 700,
        textAlign: 'end',
        marginBottom: 0,
    },
    actionTable: {
        cursor: 'pointer',
        color: '#5A8292',
        fontSize: '15px',
        fontWeight: 700,
        marginBottom: 0,
        marginLeft: '48px',
        fontSize: 17,
        fontWeight: 'bold'
    }
})
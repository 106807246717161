import Header from "./Header";
import { Grid } from "@mui/material";
import ContentOne from "./ContentOne";
import Stylesheet from "reactjs-stylesheet";

const Marketing = ({
    isMobile,
    screenWidth,
    membros,
    subMembros,
    cashbackPlus,
    setCashbackPlusVenda,
    alterarCash
}) => {
    return (
        <Grid item xs sx={screenWidth <= 1400 ? styles.contentSmallScreen : isMobile ? styles.contentMobile : styles.content}>
            <Grid container spacing={0}>
                <Header />
                <ContentOne
                    isMobile={isMobile}
                    membros={membros}
                    subMembros={subMembros}
                    cashbackPlus={cashbackPlus}
                    setCashbackPlusVenda={setCashbackPlusVenda}
                    alterarCash={alterarCash}
                />
            </Grid>
        </Grid >
    );
};

export default Marketing;

const styles = Stylesheet.create({
    content: {
        margin: '40px 50px 16px 70px'
    },
    contentSmallScreen: {
        margin: '45px 25px'
    },
    contentMobile: {
        margin: '20px 10px'
    }
});
const MenuOpen = ({
    setMenuOpen,
    logo_parceiros,
    bagOn,
    bagOff,
    marketingOn,
    marketingOff,
    dolarOn,
    dolarOff,
    sair,
    configuracoes,
    arrow,
    pageRef,
    emailRef,
    usuario,
    urlLogo,
    irPage,
    onGroupContainer13Click,
    Estabelecimentos
}) => {


    return (
        <>
            <div style={{ width: 319, backgroundColor: "#142A30", display: "flex", flexDirection: 'column', padding: "20px 25px 20px 25px", height: '100vh' }}>

                <div style={{ display: "flex", width: '100%', marginLeft: 38, paddingTop: 30, position: 'relative', alignItems: 'center' }}>

                    <div>
                        <img src={logo_parceiros} style={{ width: 150, height: 'auto', zIndex: 2 }} />
                    </div>

                    <div style={{
                        backgroundColor: '#5A8292',
                        width: '20px',
                        position: 'absolute',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginLeft: '255px',
                        borderTopRightRadius: '10px',
                        borderBottomRightRadius: '10px',
                        cursor: 'pointer'
                    }}
                        onClick={() => setMenuOpen(false)}>
                        <img
                            src={arrow}
                            style={{
                                width: 7,
                                color: '#FFFFFF',
                                margin: '7px 6px 7px 18px',
                                transform: 'scaleX(-1)'
                            }}
                        />
                    </div>

                </div>

                <div style={{ display: "flex", flex: 5, width: '100%', flexDirection: 'column', marginTop: 60, marginLeft: 38 }}>

                    <div style={{ display: "flex", width: '100%', height: 15, justifyContent: 'left', alignItems: 'center', cursor: "pointer", marginBottom: 18 }} onClick={() => irPage('xloja')}>
                        <img
                            src={pageRef == "xloja" ? bagOn : bagOff}
                            style={{
                                borderLeft: pageRef == "xloja" ? "2px solid #9DE200" : "0px",
                                paddingLeft: 13,
                                width: 32
                            }}
                        />
                        <span style={{ paddingLeft: 15, fontWeight: '600', color: pageRef == "xloja" ? "#FFFFFF" : '#5A829259' }}>
                            XLoja
                        </span>
                    </div>

                    <div style={{ display: "flex", width: '100%', height: 15, justifyContent: 'left', alignItems: 'center', cursor: "pointer", marginBottom: 18 }} onClick={() => irPage('marketing')} id="menu_marketing">
                        <img
                            src={pageRef == "marketing" ? marketingOn : marketingOff}
                            style={{
                                borderLeft: pageRef == "marketing" ? "2px solid #9DE200" : "0px",
                                paddingLeft: 13,
                                width: 32
                            }} />
                        <span style={{ paddingLeft: 15, fontWeight: '600', color: pageRef == "marketing" ? "#fff" : '#5A829259' }}>
                            Marketing
                        </span>
                    </div>

                    <div style={{ display: "flex", width: '100%', height: 18, justifyContent: 'left', alignItems: 'center', cursor: "pointer", marginBottom: 18 }} onClick={() => irPage('recebiveis')} id="menu_recebiveis">
                        <img
                            src={pageRef == "recebiveis" ? dolarOn : dolarOff}
                            style={{
                                borderLeft: pageRef == "recebiveis" ? "2px solid #9DE200" : "0px",
                                paddingLeft: 13,
                                width: 32
                            }} />
                        <span style={{ paddingLeft: 15, fontWeight: '600', color: pageRef == "recebiveis" ? "#fff" : '#5A829259' }}>
                            Recebíveis
                        </span>
                    </div>

                    {/* <div style={{ display: "flex", width: '100%', height: 18, justifyContent: 'left', alignItems: 'center', cursor: "pointer", marginBottom: 18 }} onClick={() => irPage('primeiroAcesso')} id="menu_recebiveis">
                        <img
                            src={pageRef == "primeiroAcesso" ? dolarOn : dolarOff}
                            style={{
                                borderLeft: pageRef == "primeiroAcesso" ? "2px solid #9DE200" : "0px",
                                paddingLeft: 13,
                                width: 32
                            }} />
                        <span style={{ paddingLeft: 15, fontWeight: '600', color: pageRef == "primeiroAcesso" ? "#fff" : '#5A829259' }}>
                            primeiroAcesso
                        </span>
                    </div> */}

                </div>

                {/* <div style={{ display: "flex", width: 205, marginLeft: 38 }}>
                    {Estabelecimentos()}
                </div> */}

                <div style={{ display: "flex", width: 205, flexDirection: 'column', marginTop: 30, marginLeft: 38 }}>

                    {/* <div style={{ display: "flex", width: '100%', height: 15, justifyContent: 'left', alignItems: 'center', cursor: "pointer", marginBottom: 15 }} id="menu_configuracoes" onClick={() => irPage('configuracoes')}>
                        <img src={configuracoes} style={{ width: 15, color: "#f1e2c4" }} />
                        <span style={{ paddingLeft: 10, color: "#fff", fontSize: 12, fontWeight: 600 }}>
                            Configurações
                        </span>
                    </div> */}

                    <div style={{ display: "flex", width: '100%', height: 15, justifyContent: 'left', alignItems: 'center', cursor: "pointer", marginBottom: 10 }} onClick={onGroupContainer13Click}>
                        <img src={sair} style={{ width: 15 }} /> <span style={{ paddingLeft: 10, color: '#fff', fontSize: 12, fontWeight: 600 }}>
                            Sair
                        </span>
                    </div>

                    <div style={{ borderTop: "1px solid #5A8292", width: 190, marginTop: 15 }}></div>

                </div>

                <div style={{ display: "flex", width: '205px', padding: "30px 12px 20px 0px", alignItems: 'center', marginLeft: 38 }}>

                    <div style={{ display: "flex", flex: 1, width: '100%', borderRadius: 50, width: 50, height: 50 }}>
                        <img src={urlLogo} style={{ width: "auto", width: 60, height: "auto", objectFit: "cover", borderRadius: 30 }} />
                    </div>

                    <div style={{ display: "flex", flex: 3, width: '100%', flexDirection: 'column', marginLeft: 10 }}>
                        <p style={{ marginBottom: 1, fontSize: 15, color: '#ffffff', fontWeight: 700 }}>{usuario}</p>
                        <p style={{ marginBottom: 1, fontSize: 13, color: '#ffffff8f' }}>{emailRef}</p>
                    </div>

                </div>
            </div>
        </>
    )
}

export default MenuOpen;
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Menu from "./componentes/menu";
import MenuMobile from "./componentes/menuMobile";

import { useEffect } from "react";
import more from './assets/more.png'; // Tell webpack this JS file uses this image
import waiting from './assets/waiting.jpg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse, faShoppingBag, faChartPie, faBox, faCircleDollarToSlot, faBullhorn, faQuestionCircle, faUsers, faGear, faSquareXmark, faMobile, faCalendar, faChevronDown, faCircleCheck, faEllipsisVertical } from '@fortawesome/free-solid-svg-icons'
import { useState } from "react";

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import logo1 from './assets/xuliz_logo2.png'; // Tell webpack this JS file uses this image

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import * as XLSX from 'xlsx';
import { Button } from "react-bootstrap";


import { saveAs } from 'file-saver';


import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const Configuracoes = () => {
    const navigate = useNavigate();

    const [emailRef, setEmailRef] = useState('');
    const [value, setValue] = useState('');
    const [urlfotoperfil, setFotoUrl] = useState('');
    const [cashbackConta, setCashback] = useState('3');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);

    const [dadosX, setDadosX] = useState([]);
    const [rows, setRow] = useState([]);
    const [tokenEsta, setTokenEstabelecimento] = useState('');

    const [quantidadeVendas, setQuantidadeVendas] = useState('0');
    const [valorTotal, setValorTotal] = useState('0');
    const [valorLiquido, setValorLiquido] = useState('0');

    const [nomeCompleto, setNomeCompleto] = useState('-');
    const [emailEditar, setEmailEditar] = useState('-');
    const [celular, setCelular] = useState('-');


    const [cpf, setCPF] = useState('-');
    const [dataNascimento, setDataNascimento] = useState('-');




    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [showCalendar, setShowCalendar] = useState(false);

    const [vendaInicio, setVendaInicio] = useState('');
    const [vendaFinal, setVendaFinal] = useState('');

    const [usuario, setUsuario] = useState('');
    const [parte, setParte] = useState('dados_cadastrais'); //dados_cadastrais

    const Swal = require('sweetalert2')
    const [menuOpen, setMenuOpen] = useState(true);
    const [screenWidth, setScreenWidth] = useState(null);
    const [isMobile, setIsMobile] = useState(true);
    
    useEffect(() => {
        getDados()
    }, []);

    useEffect(() => {
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () => {
            window.removeEventListener('resize', updateDimensions);
        };

    }, []);

    const updateDimensions = () => {
        setIsMobile(window.innerWidth <= 800);
        setScreenWidth(window.innerWidth);

        if (window.innerWidth <= 1400 && menuOpen) {
            setMenuOpen(false)
        }
        if (window.innerWidth > 1400 && menuOpen) {
            setMenuOpen(true)
        }
    };


    const getDados = async () => {
        var dados = await localStorage.getItem('login')


        if (dados == null) {
            return false;
        }


        dados = JSON.parse(dados)
        var dadosX = dados;

        console.log('dadosX', dadosX)
        setNomeCompleto(dadosX.nome)
        setEmailEditar(dadosX.email)
        setCelular(dadosX.celular)

        setUsuario(dadosX.empresa)
        setCPF(dadosX.cpf)
        setDataNascimento(dadosX.datanascimento)
        setUsuario(dadosX.usuario)
        setDadosX(dadosX)

    }

    const clickSalvar = async () => {

        if (celular.length < 10) {
            alert('Dados do celular inválido')
            return false;
        }

        if (nomeCompleto.length < 2) {
            alert("Nome incorreto")
            return false;
        }

        if (emailEditar.length < 3) {
            alert("Email incorreto")
            return false;
        }

        const form = new FormData();
        form.append("metodo", "EditarContato");
        form.append("idempresa", "1350");
        form.append("token", dadosX.tokenestabelecimento);
        form.append("idestabelecimento", dadosX.idestabelecimento);
        form.append("email", emailEditar);
        form.append("celular", celular);
        form.append("nome", nomeCompleto);
        form.append("idusuario", dadosX.idusuario);


        const options = {
            method: 'POST',
        };

        options.body = form;

        fetch('https://xuliz.com.br/painel/integracao/estabelecimento.php', options)
            .then(response => response.json())
            .then(response => {


                if (response.erro == "S") {
                    alert("" + resposta.mensagem)
                    return false;
                }

                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: '' + response.mensagem,
                    showConfirmButton: true,
                })

                return false;
            })
            .catch((err) => {
                console.log(err)
                alert("Encontramos um problema tente mais tarde")
            });

    }

    const resultado = (result) => {

        if (result == null || result == '') {
            return '-';
        }

        return result
    }



    if (parte == "contas") {

        return (
            <>

                <div style={{ height: '100vh', display: "flex", backgroundColor: '#262626' }}>
                    <div style={{ backgroundColor: '#262626' }}>
                        <Menu page="configuracoes" />
                    </div>


                    <div style={{ height: '100vh', display: "flex", width: "100%", overflow: 'auto' }}>
                        <div style={{ display: "flex", width: "100%", margin: 40, flexDirection: 'column', backgroundColor: 'white' }}>

                            <div style={{ width: '100%', flexDirection: 'column', display: "flex", height: 50, flexDirection: 'row' }}>

                                <div style={{ flex: 1, backgroundColor: '#262626', width: '100%', borderBottom: '4px solid #262626', textAlign: '-webkit-center', cursor: "pointer" }} onClick={() => { setParte('dados_cadastrais') }}>
                                    <div style={{ width: '100%', flex: 1, height: '100%' }}>
                                        <p style={{ color: 'white', justifyContent: 'center', paddingTop: 17, fontSize: 12 }} >Dados cadastrais</p>
                                    </div>
                                </div>

                                {/* <div style={{flex:1,backgroundColor:'#262626',width:'100%',borderBottom:'4px solid #262626',textAlign:'-webkit-center',cursor:"pointer"}} onClick={()=>{setParte('senha')}}>
                      <div style={{width:'100%',flex:1,height:'100%'}}>
                          <p style={{color:'white',justifyContent:'center',paddingTop:17,fontSize:12}} >Senha</p>
                      </div>
                  </div>
                   */}
                                <div style={{ flex: 1, backgroundColor: '#262626', width: '100%', borderBottom: '4px solid #262626', textAlign: '-webkit-center', cursor: "pointer" }} onClick={() => { setParte('dados_contato') }}>
                                    <div style={{ width: '100%', flex: 1, height: '100%' }}>
                                        <p style={{ color: 'white', justifyContent: 'center', paddingTop: 17, fontSize: 12 }}>Dados de contato</p>
                                    </div>
                                </div>

                                <div style={{ flex: 1, backgroundColor: '#262626', width: '100%', borderBottom: '4px solid #262626', textAlign: '-webkit-center', cursor: "pointer" }} onClick={() => { setParte('dados_bancarios') }}>
                                    <div style={{ width: '100%', flex: 1, height: '100%' }}>
                                        <p style={{ color: 'white', justifyContent: 'center', paddingTop: 17, fontSize: 12 }}>Dados bancários</p>
                                    </div>
                                </div>

                                {/* <div style={{flex:1,backgroundColor:'#262626',width:'100%',borderBottom:'4px solid #f1e2c4',textAlign:'-webkit-center',cursor:"pointer"}} onClick={()=>{setParte('contas')}}>
                      <div style={{width:'100%',flex:1,height:'100%'}}>
                          <p style={{color:'white',justifyContent:'center',paddingTop:17,fontSize:12}}>Contas</p>
                      </div>
                  </div> */}

                            </div>


                            <div style={{ width: '100%', padding: 20, overflow: 'auto' }}>



                                <div style={{ display: 'flex', flex: 1 }}>
                                    <div style={{ display: 'flex', flex: 1 }}>
                                        <p style={{ paddingTop: 40, fontSize: 25, fontFamily: 'Montserrat' }}>Contas</p>

                                    </div>

                                    <div style={{ display: 'flex', flex: 1, justifyContent: 'right', marginTop: 15 }}>
                                        <div style={{ width: 160, height: 30, backgroundColor: '#262626', borderRadius: 10, marginTop: 30, borderColor: '#262626', border: '2px solid #262626', textAlign: 'center', marginRight: 20 }}>
                                            <p style={{ color: '#f1e2c4', paddingTop: 3 }}>Adicionar conta</p>
                                        </div>
                                    </div>
                                </div>


                                <div style={{ width: '100%', backgroundColor: '#8a8686', height: 1 }}></div>






                                <div style={{ display: 'flex' }}>



                                </div>



                                <table style={{ marginTop: 20, width: '100%' }}>
                                    <tr style={{ backgroundColor: '#f1f1f1', height: 50, fontSize: 12, color: '#554f4f' }}>
                                        <th>Nome</th>
                                        <th>Usuário</th>
                                        <th>Função</th>
                                        <th>Data de cadastro</th>
                                        <th>Abrir</th>
                                    </tr>
                                    <tr style={{ color: 'black' }}>
                                        <td style={{ paddingTop: 20 }}>
                                            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                                <div style={{ width: 50, height: 50, backgroundColor: '#262626', borderRadius: 10, justifyContent: 'center', display: 'flex', alignItems: 'center' }}><span style={{ color: 'white' }}>LS</span></div>

                                                <div style={{ display: 'flex', flexDirection: 'column', fontSize: 10 }}>
                                                    <span style={{ paddingLeft: 10, color: 'black' }}>Rubens Schueng Neto</span>
                                                    <span style={{ paddingLeft: 10, color: '#4c4a4a' }}>nettto.scheng@gmail.com.br</span>

                                                </div>


                                            </div>
                                        </td>


                                        <td style={{ paddingTop: 20 }}>
                                            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>

                                                <div style={{ display: 'flex', flexDirection: 'column', fontSize: 15 }}>
                                                    <span style={{ color: 'black' }}>rubens.scheng</span>
                                                </div>


                                            </div>
                                        </td>


                                        <td style={{ paddingTop: 20 }}>
                                            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>

                                                <div style={{ display: 'flex', flexDirection: 'column', fontSize: 12 }}>
                                                    <span style={{ color: '#575656' }}>Gerente de Marketing</span>
                                                </div>


                                            </div>
                                        </td>



                                        <td style={{ paddingTop: 20 }}>
                                            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>

                                                <div style={{ display: 'flex', flexDirection: 'column', fontSize: 12 }}>
                                                    <span style={{ color: '#575656' }}>Entrou: 31 out 2022</span>
                                                </div>


                                            </div>
                                        </td>

                                        <td style={{ paddingTop: 20 }}>
                                            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>

                                                <div style={{ display: 'flex', flexDirection: 'column', fontSize: 12 }}>
                                                    <FontAwesomeIcon icon={faEllipsisVertical} style={{ fontSize: 15, color: "#8c8c8c", justifyContent: 'center' }} />
                                                </div>


                                            </div>
                                        </td>
                                    </tr>

                                </table>


                            </div>
                        </div>

                    </div>



                </div>



            </>

        )

    }




    if (parte == "dados_bancarios") {

        return (
            <>

                <div style={{ height: '100vh', display: "flex", backgroundColor: '#262626' }}>
                    <div style={{ backgroundColor: '#262626' }}>
                        <Menu page="configuracoes" />
                    </div>


                    <div style={{ height: '100vh', display: "flex", width: "100%", overflow: 'auto' }}>
                        <div style={{ display: "flex", width: "100%", margin: 40, flexDirection: 'column', backgroundColor: 'white' }}>

                            <div style={{ width: '100%', flexDirection: 'column', display: "flex", height: 50, flexDirection: 'row' }}>

                                <div style={{ flex: 1, backgroundColor: '#262626', width: '100%', borderBottom: '4px solid #262626', textAlign: '-webkit-center', cursor: "pointer" }} onClick={() => { setParte('dados_cadastrais') }}>
                                    <div style={{ width: '100%', flex: 1, height: '100%' }}>
                                        <p style={{ color: 'white', justifyContent: 'center', paddingTop: 17, fontSize: 12 }}>Dados cadastrais</p>
                                    </div>
                                </div>

                                {/* <div style={{flex:1,backgroundColor:'#262626',width:'100%',borderBottom:'4px solid #262626',textAlign:'-webkit-center',cursor:"pointer"}} onClick={()=>{setParte('senha')}}>
                      <div style={{width:'100%',flex:1,height:'100%'}}>
                          <p style={{color:'white',justifyContent:'center',paddingTop:17,fontSize:12}}>Senha</p>
                      </div>
                  </div> */}

                                <div style={{ flex: 1, backgroundColor: '#262626', width: '100%', borderBottom: '4px solid #262626', textAlign: '-webkit-center', cursor: "pointer" }} onClick={() => { setParte('dados_contato') }}>
                                    <div style={{ width: '100%', flex: 1, height: '100%' }}>
                                        <p style={{ color: 'white', justifyContent: 'center', paddingTop: 17, fontSize: 12 }}>Dados de contato</p>
                                    </div>
                                </div>

                                <div style={{ flex: 1, backgroundColor: '#262626', width: '100%', borderBottom: '4px solid #9DE200', textAlign: '-webkit-center', cursor: "pointer" }} onClick={() => { setParte('dados_bancarios') }}>
                                    <div style={{ width: '100%', flex: 1, height: '100%' }}>
                                        <p style={{ color: 'white', justifyContent: 'center', paddingTop: 17, fontSize: 12 }}>Dados bancários</p>
                                    </div>
                                </div>

                                {/* <div style={{flex:1,backgroundColor:'#262626',width:'100%',borderBottom:'4px solid #262626',textAlign:'-webkit-center',cursor:"pointer"}} onClick={()=>{setParte('contas')}}>
                      <div style={{width:'100%',flex:1,height:'100%'}}>
                          <p style={{color:'white',justifyContent:'center',paddingTop:17,fontSize:12}}>Contas</p>
                      </div>
                  </div> */}

                            </div>


                            <div style={{ width: '100%', padding: 20, overflow: 'auto' }}>
                                <span style={{ fontFamily: 'Montserrat' }}>{usuario}, seus dados estão seguros aqui.</span>

                                <div style={{ display: 'flex', flex: 1 }}>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                                        <p style={{ paddingTop: 40, fontSize: 25, fontFamily: 'Montserrat' }}>Informações do negócio</p>
                                        <p style={{ paddingTop: 10, fontSize: 14, fontFamily: 'Montserrat' }}>Os dados abaixo não podem ser editados, caso necessite alterar algum deles é necessário que abra um chamado na <a href="https://xuliz.com.br/">Centra de Ajuda</a></p>

                                    </div>


                                </div>


                                <div style={{ width: '100%', backgroundColor: '#8a8686', height: 1 }}></div>

                                <div style={{ flex: 1, width: '100%', height: 500, flexDirection: 'row', display: 'flex' }}>
                                    <div style={{ flex: 1, flex: 1.5, marginTop: 20, display: 'flex', flexDirection: 'column' }}>

                                        <div style={{ flex: 1, flexDirection: 'row', display: "flex" }}>

                                            <div style={{ width: '100%', backgroundColor: '#f1f1f1', height: 50, borderRadius: 10, margin: 10, padding: 5 }}>
                                                <span style={{ fontSize: 9, color: '#797979' }}>CNPJ:</span>
                                                <p style={{ fontSize: 9, margin: 0 }}>{dadosX.cnpj}</p>
                                            </div>

                                            <div style={{ width: '100%', backgroundColor: '#f1f1f1', height: 50, borderRadius: 10, margin: 10, padding: 5 }}>
                                                <span style={{ fontSize: 9, color: '#797979' }}>Nome dono/sócio administrativo:</span>
                                                <p style={{ fontSize: 9, margin: 0 }}>{resultado(nomeCompleto)}</p>
                                            </div>
                                        </div>


                                        <div style={{ flex: 1, flexDirection: 'row', display: "flex" }}>

                                            <div style={{ width: '100%', backgroundColor: '#f1f1f1', height: 50, borderRadius: 10, margin: 10, padding: 5 }}>
                                                <span style={{ fontSize: 9, color: '#797979' }}>CPF dono/sócio administrativo:</span>
                                                <p style={{ fontSize: 9, margin: 0 }}>{dadosX.cpf}</p>
                                            </div>

                                            <div style={{ width: '100%', backgroundColor: '#f1f1f1', height: 50, borderRadius: 10, margin: 10, padding: 5 }}>
                                                <span style={{ fontSize: 9, color: '#797979' }}>Email:</span>
                                                <p style={{ fontSize: 9, margin: 0 }}>{resultado(emailEditar)}</p>
                                            </div>
                                        </div>



                                        <div style={{ flex: 1, flexDirection: 'row', display: "flex" }}>

                                            <div style={{ width: '100%', height: 50, borderRadius: 10, margin: 10, padding: 5, marginBottom: 20 }}>
                                                <p style={{ paddingTop: 17, fontSize: 16, fontFamily: 'Montserrat' }}>Antecipação</p>
                                                <div style={{ width: '100%', backgroundColor: '#8a8686', height: 1 }}></div>

                                            </div>

                                            <div style={{ width: '100%', height: 50, borderRadius: 10, margin: 10, padding: 5, marginBottom: 20 }}>
                                                <p style={{ paddingTop: 17, fontSize: 16, fontFamily: 'Montserrat' }}>Parcelamento</p>
                                                <div style={{ width: '100%', backgroundColor: '#8a8686', height: 1 }}></div>

                                            </div>
                                        </div>


                                        <div style={{ flex: 1, flexDirection: 'row', display: "flex" }}>


                                            <div style={{ width: '100%', backgroundColor: '#f1f1f1', height: 50, borderRadius: 10, margin: 10, padding: 5 }}>
                                                <span style={{ fontSize: 9, color: '#797979' }}>Antecipação:</span>
                                                <p style={{ fontSize: 9, margin: 0 }}>-</p>
                                            </div>

                                            <div style={{ width: '100%', backgroundColor: '#f1f1f1', height: 50, borderRadius: 10, margin: 10, padding: 5 }}>
                                                <span style={{ fontSize: 9, color: '#797979' }}>Parcelas:</span>
                                                <p style={{ fontSize: 9, margin: 0 }}>-</p>
                                            </div>
                                        </div>



                                        <div style={{ flex: 1, flexDirection: 'row', display: "flex" }}>

                                            <div style={{ width: '100%', backgroundColor: '#f1f1f1', height: 50, borderRadius: 10, margin: 10, padding: 5 }}>
                                                <span style={{ fontSize: 9, color: '#797979' }}>Prazo de pagamento:</span>
                                                <p style={{ fontSize: 9, margin: 0 }}>-</p>
                                            </div>

                                            <div style={{ width: '100%', backgroundColor: '#f1f1f1', height: 50, borderRadius: 10, margin: 10, padding: 5 }}>
                                                <span style={{ fontSize: 9, color: '#797979' }}>Número máximo:</span>
                                                <p style={{ fontSize: 9, margin: 0 }}>-</p>
                                            </div>
                                        </div>





                                    </div>

                                    <div style={{ flex: 1, backgroundColor: '#f1f1f1', flex: 1, borderRadius: 10, margin: 20 }}>

                                        <div style={{ flex: 1, flexDirection: 'row', display: "flex", backgroundColor: '#262626', height: 200, margin: 20, flexDirection: 'column', borderRadius: 10, padding: 10, overflow: 'auto' }}>
                                            <span style={{ color: 'white', fontSize: 10, fontWeight: '400' }}>• Certifique-se que sua conta bancária está registrada sob o<span style={{ color: '#9DE200' }}> mesmo nome comercial e CNPJ listados ao lado.</span></span>

                                            <span style={{ color: 'white', fontSize: 10, fontWeight: '400', paddingTop: 10 }}>• Certifique-se que sua conta bancária está registrada sob o mesmo nome comercial e CNPJ listados ao lado.</span>

                                            <span style={{ color: 'white', fontSize: 10, fontWeight: '400', paddingTop: 10 }}>• Os fundos serão enviados de forma automática <span style={{ color: '#9DE200' }}>(de acordo com o período acordado) </span>para a conta registrada abaixo. </span>


                                            <span style={{ color: 'white', fontSize: 10, fontWeight: '400', paddingTop: 10 }}>• A conta só será aprovada se todos os dados estiverem <span style={{ color: '#9DE200' }}>100% corretos</span>.</span>
                                            <span style={{ color: 'white', fontSize: 10, fontWeight: '400', paddingTop: 10 }}>• Caso tenha alguma dúvida, nossa equipe está a disposição para ajudar. <span style={{ color: '#9DE200' }}>Entre em contato abrindo um chamado na central de ajuda. </span></span>

                                        </div>


                                        <div style={{ flex: 1, flexDirection: 'row', display: "flex" }}>

                                            <div style={{ width: '100%', backgroundColor: 'white', height: 50, borderRadius: 10, margin: 10, padding: 5 }}>
                                                <span style={{ fontSize: 9, color: '#797979' }}>Código do banco:</span>
                                                <p style={{ fontSize: 9, margin: 0 }}>-</p>
                                            </div>

                                            <div style={{ width: '100%', backgroundColor: 'white', height: 50, borderRadius: 10, margin: 10, padding: 5 }}>
                                                <span style={{ fontSize: 9, color: '#797979' }}>Tipo de conta:</span>
                                                <p style={{ fontSize: 9, margin: 0 }}>-</p>
                                            </div>
                                        </div>


                                        <div style={{ flex: 1, flexDirection: 'row', display: "flex" }}>

                                            <div style={{ display: 'flex', flex: 1, backgroundColor: 'white', height: 50, borderRadius: 10, margin: 10, padding: 5, flexDirection: 'column' }}>
                                                <span style={{ fontSize: 9, color: '#797979' }}>Agência:</span>
                                                <p style={{ fontSize: 9, margin: 0 }}>-</p>
                                            </div>

                                            <div style={{ display: 'flex', flex: 1, backgroundColor: 'white', height: 50, borderRadius: 10, margin: 10, padding: 5, flexDirection: 'column' }}>
                                                <span style={{ fontSize: 9, color: '#797979' }}>Conta sem digito:</span>
                                                <p style={{ fontSize: 9, margin: 0 }}>-</p>
                                            </div>

                                            <div style={{ display: 'flex', flex: 0.3, backgroundColor: 'white', height: 50, borderRadius: 10, margin: 10, padding: 5, flexDirection: 'column' }}>
                                                <span style={{ fontSize: 9, color: '#797979' }}>Dígito:</span>
                                                <p style={{ fontSize: 9, margin: 0 }}>-</p>
                                            </div>
                                        </div>



                                        <div style={{ flex: 1, flexDirection: 'row', display: "flex" }}>

                                            <div style={{ display: 'flex', flex: 1, backgroundColor: 'white', height: 50, borderRadius: 10, margin: 10, padding: 5, flexDirection: 'column' }}>
                                                <span style={{ fontSize: 9, color: '#797979' }}>Chave PIX da conta:</span>
                                                <p style={{ fontSize: 9, margin: 0 }}>-</p>
                                            </div>

                                            <div style={{ display: 'flex', flex: 0.6, backgroundColor: '#142A30', height: 50, borderRadius: 10, margin: 10, padding: 5, flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                                <span style={{ fontSize: 15, color: '#FFFFFF' }}>Salvar</span>
                                            </div>

                                            <div style={{ display: 'flex', flex: 1.5, backgroundColor: '#279C33', height: 50, borderRadius: 10, margin: 10, padding: 5, flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                                <span style={{ fontSize: 15, color: 'white' }}>Conta Aprovada</span>
                                            </div>

                                        </div>


                                    </div>
                                </div>






                                {/* <div style={{display:'flex'}}>
  
                       <div style={{width:200,height:300,backgroundColor:'#f1f1f1',marginTop:20,borderRadius:5,display:'flex',alignItems:'center',justifyContent:'center'}}>
                            <img src={more} style={{width:45,height:45}}/>
                       </div>
                  
                       <div style={{width:200,height:300,backgroundColor:'#f1f1f1',marginTop:20,borderRadius:5,display:'flex',alignItems:'center',justifyContent:'center',marginLeft:20,flexDirection:'column',padding:20}}>
                            <div style={{width:'100%',display:'flex',textAlign:'-webkit-center',justifyContent:'center'}}>

                                <div style={{display:'flex',flex:1,justifyContent:'center'}}>
                                    <img src='https://www.paranacooperativo.coop.br/ppc/images/Comunicacao/2023/noticias/04/06/sicoob/sicoob_06_04_2023.JPG' style={{width:70,height:70,borderRadius:10,textAlign:'-webkit-center'}}/>
                                </div>

                                <div style={{display:'flex',flex:1,flexDirection:'column',marginTop:6,marginLeft:10}}>
                                        <div style={{width:70,height:20,backgroundColor:'orange',borderRadius:6}}></div>
                                        <div style={{width:70,height:20,fontSize:16,textAlign:'-webkit-left'}}><span>Em análise</span></div>
                                        
                                </div>
                            </div>

                            <div style={{height:'70%',width:'100%',padding:15}}>
                                <span style={{fontSize:12,color:'#5d5d5d'}}>Banco:</span>
                                <p style={{fontSize:12}}>756 - BANCO S.A</p>

                                <div style={{display:'flex',flex:1}}>
                                    <div style={{display:'flex',flex:1,flexDirection:'column'}}>
                                        <span style={{fontSize:12,color:'#5d5d5d'}}>Agência:</span>
                                        <p style={{fontSize:12}}>3007</p>
                                    </div>

                                    <div style={{display:'flex',flex:1,flexDirection:'column'}}>
                                        <span style={{fontSize:12,color:'#5d5d5d'}}>Conta:</span>
                                        <p style={{fontSize:12}}>393233-8</p>
                                    </div>
                                   
                                </div>


                                <span style={{fontSize:12,color:'#5d5d5d'}}>Tipo de conta:</span>
                                <p style={{fontSize:12}}>Conta corrente</p>

                                <span style={{fontSize:12,color:'#5d5d5d'}}>Pix:</span>
                                <p style={{fontSize:12}}>Chave aleatória</p>
                               
                            </div>
                       </div>




                       
  
              </div> */}





                                {/* <div style={{display:'flex',flex:1}}>
                        <div style={{display:'flex',flex:1,flexDirection:'column'}}>
                        <p style={{paddingTop:40,fontSize:25,fontFamily: 'Montserrat'}}>Conta principal</p>
                        <p style={{fontSize:10,fontFamily: 'Montserrat'}}>A conta principal será a que todos os seus fundos serão enviados de acordo com os agendamentos automáticos. Por meio do PIX (sem cadastrado) ou TED.</p>



                        </div>

                        
                    </div> */}


                                {/* <div style={{width:'100%',backgroundColor:'#8a8686',height:1}}></div>
   */}


                                {/* <div class="mb-3" style={{marginTop:30}}>
                    <label for="exampleInputEmail1" class="form-label">Conta principal</label>
                    <input type="email" class="form-control" id="email_id" aria-describedby="emailHelp" value="3007/390234-2" style={{width:300,backgroundColor:'#dddddd'}}/>
                   </div> */}



                            </div>
                        </div>

                    </div>



                </div>



            </>

        )

    }






    if (parte == "dados_contato") {

        return (
            <>

                <div style={{ height: '100vh', display: "flex", backgroundColor: '#262626' }}>
                    <div style={{ backgroundColor: '#262626' }}>
                        <Menu page="configuracoes" />
                    </div>


                    <div style={{ height: '100vh', display: "flex", width: "100%", overflow: 'auto' }}>
                        <div style={{ display: "flex", width: "100%", margin: 40, flexDirection: 'column', backgroundColor: 'white' }}>

                            <div style={{ width: '100%', flexDirection: 'column', display: "flex", height: 50, flexDirection: 'row' }}>

                                <div style={{ flex: 1, backgroundColor: '#262626', width: '100%', borderBottom: '4px solid #262626', textAlign: '-webkit-center', cursor: "pointer" }} onClick={() => { setParte('dados_cadastrais') }}>
                                    <div style={{ width: '100%', flex: 1, height: '100%' }}>
                                        <p style={{ color: 'white', justifyContent: 'center', paddingTop: 17, fontSize: 12 }}>Dados cadastrais</p>
                                    </div>
                                </div>

                                {/* <div style={{flex:1,backgroundColor:'#262626',width:'100%',borderBottom:'4px solid #262626',textAlign:'-webkit-center',cursor:"pointer"}} onClick={()=>{setParte('senha')}}>
                      <div style={{width:'100%',flex:1,height:'100%'}}>
                          <p style={{color:'white',justifyContent:'center',paddingTop:17,fontSize:12}} >Senha</p>
                      </div>
                  </div> */}

                                <div style={{ flex: 1, backgroundColor: '#262626', width: '100%', borderBottom: '4px solid #9DE200', textAlign: '-webkit-center', cursor: "pointer" }} onClick={() => { setParte('dados_contato') }}>
                                    <div style={{ width: '100%', flex: 1, height: '100%' }}>
                                        <p style={{ color: 'white', justifyContent: 'center', paddingTop: 17, fontSize: 12 }}>Dados de contato</p>
                                    </div>
                                </div>

                                <div style={{ flex: 1, backgroundColor: '#262626', width: '100%', borderBottom: '4px solid #262626', textAlign: '-webkit-center', cursor: "pointer" }} onClick={() => { setParte('dados_bancarios') }}>
                                    <div style={{ width: '100%', flex: 1, height: '100%' }}>
                                        <p style={{ color: 'white', justifyContent: 'center', paddingTop: 17, fontSize: 12 }}>Dados bancários</p>
                                    </div>
                                </div>

                                {/* <div style={{flex:1,backgroundColor:'#262626',width:'100%',borderBottom:'4px solid #262626',textAlign:'-webkit-center',cursor:"pointer"}} onClick={()=>{setParte('contas')}}>
                      <div style={{width:'100%',flex:1,height:'100%'}}>
                          <p style={{color:'white',justifyContent:'center',paddingTop:17,fontSize:12}}>Contas</p>
                      </div>
                  </div> */}

                            </div>


                            <div style={{ width: '100%', padding: 20, overflow: 'auto' }}>
                                <span style={{ fontFamily: 'Montserrat' }}>{usuario}, seus dados estão seguros aqui.</span>

                                <div style={{ display: 'flex', flex: 1 }}>
                                    <div style={{ display: 'flex', flex: 1 }}>
                                        <p style={{ paddingTop: 40, fontSize: 25, fontFamily: 'Montserrat' }}>Dados de contato</p>

                                    </div>

                                    <div style={{ display: 'flex', flex: 1, justifyContent: 'right', marginTop: 15 }}>
                                        <div style={{ width: 120, height: 30, backgroundColor: '#142A30', borderRadius: 10, marginTop: 30, borderColor: '#262626', border: '2px solid #262626', textAlign: 'center', marginRight: 20, cursor: 'pointer' }} onClick={() => { clickSalvar() }}>
                                            <p style={{ color: '#FFFFFF', paddingTop: 3 }}>Salvar</p>
                                        </div>
                                    </div>
                                </div>


                                <div style={{ width: '100%', backgroundColor: '#8a8686', height: 1 }}></div>


                                <div style={{ display: 'flex' }}>

                                    <div style={{ width: '100%', height: 70, flexDirection: 'column', flex: 1, display: 'flex' }}>
                                        <div style={{ width: '100%', height: 70, marginTop: 20, flexDirection: 'row', flex: 1, display: 'flex' }}>

                                            <div style={{ width: '100%', backgroundColor: '#f1f1f1', height: 50, borderRadius: 10, margin: 10, padding: 5 }}>
                                                <span style={{ fontSize: 9, color: '#797979' }}>Nome:</span>
                                                {/* <p style={{fontSize:9,margin:0}}>{nomeCompleto}</p>   */}
                                                <input type="text" class="form-control" id="nome_editar" aria-describedby="emailHelp" value={nomeCompleto} onChange={e => { setNomeCompleto(e.target.value) }} style={{ backgroundColor: '#f1f1f1', fontSize: 9, borderWidth: 0, padding: 0 }} />

                                            </div>

                                            <div style={{ width: '100%', backgroundColor: '#f1f1f1', height: 50, borderRadius: 10, margin: 10, padding: 5 }}>
                                                <span style={{ fontSize: 9, color: '#797979' }}>E-mail:</span>
                                                {/* <p style={{fontSize:9,margin:0}}>{dadosX.email}</p>    */}
                                                <input type="text" class="form-control" id="nome_editar" aria-describedby="emailHelp" value={emailEditar} onChange={e => { setEmailEditar(e.target.value) }} style={{ backgroundColor: '#f1f1f1', fontSize: 9, borderWidth: 0, padding: 0 }} />

                                            </div>


                                            <div style={{ width: '100%', backgroundColor: '#f1f1f1', height: 50, borderRadius: 10, margin: 10, padding: 5 }}>
                                                <span style={{ fontSize: 9, color: '#797979' }}>Nº Celular:</span>
                                                <input type="text" class="form-control" id="nome_editar" aria-describedby="emailHelp" value={celular} onChange={e => { setCelular(e.target.value) }} style={{ backgroundColor: '#f1f1f1', fontSize: 9, borderWidth: 0, padding: 0 }} />

                                            </div>

                                        </div>






                                    </div>






                                </div>





                            </div>
                        </div>

                    </div>



                </div>



            </>

        )

    }






    if (parte == "senha") {

        return (
            <>

                <div style={{ height: '100vh', display: "flex", backgroundColor: '#262626' }}>
                    <div style={{ backgroundColor: '#262626' }}>
                        <Menu page="configuracoes" />
                    </div>


                    <div style={{ height: '100vh', display: "flex", width: "100%", overflow: 'auto' }}>
                        <div style={{ display: "flex", width: "100%", margin: 40, flexDirection: 'column', backgroundColor: 'white' }}>

                            <div style={{ width: '100%', flexDirection: 'column', display: "flex", height: 50, flexDirection: 'row' }}>

                                <div style={{ flex: 1, backgroundColor: '#262626', width: '100%', borderBottom: '4px solid #262626', textAlign: '-webkit-center', cursor: "pointer" }} onClick={() => { setParte('dados_cadastrais') }}>
                                    <div style={{ width: '100%', flex: 1, height: '100%' }}>
                                        <p style={{ color: 'white', justifyContent: 'center', paddingTop: 17, fontSize: 12 }}>Dados cadastrais</p>
                                    </div>
                                </div>

                                {/* <div style={{flex:1,backgroundColor:'#262626',width:'100%',borderBottom:'4px solid #f1e2c4',textAlign:'-webkit-center',cursor:"pointer"}} onClick={()=>{setParte('senha')}}>
                    <div style={{width:'100%',flex:1,height:'100%'}}>
                        <p style={{color:'white',justifyContent:'center',paddingTop:17,fontSize:12}}>Senha</p>
                    </div>
                </div> */}

                                <div style={{ flex: 1, backgroundColor: '#262626', width: '100%', borderBottom: '4px solid #262626', textAlign: '-webkit-center', cursor: "pointer" }} onClick={() => { setParte('dados_contato') }}>
                                    <div style={{ width: '100%', flex: 1, height: '100%' }}>
                                        <p style={{ color: 'white', justifyContent: 'center', paddingTop: 17, fontSize: 12 }}>Dados de contato</p>
                                    </div>
                                </div>

                                <div style={{ flex: 1, backgroundColor: '#262626', width: '100%', borderBottom: '4px solid #262626', textAlign: '-webkit-center', cursor: "pointer" }} onClick={() => { setParte('dados_bancarios') }}>
                                    <div style={{ width: '100%', flex: 1, height: '100%' }}>
                                        <p style={{ color: 'white', justifyContent: 'center', paddingTop: 17, fontSize: 12 }}>Dados bancários</p>
                                    </div>
                                </div>

                                {/* <div style={{flex:1,backgroundColor:'#262626',width:'100%',borderBottom:'4px solid #262626',textAlign:'-webkit-center',cursor:"pointer"}} onClick={()=>{setParte('contas')}}>
                    <div style={{width:'100%',flex:1,height:'100%'}}>
                        <p style={{color:'white',justifyContent:'center',paddingTop:17,fontSize:12}}>Contas</p>
                    </div>
                </div> */}

                            </div>


                            <div style={{ width: '100%', padding: 20, overflow: 'auto' }}>
                                <span style={{ fontFamily: 'Montserrat' }}>{usuario}, seus dados estão seguros aqui.</span>

                                <p style={{ paddingTop: 40, fontSize: 25, fontFamily: 'Montserrat' }}>Troca de senha</p>
                                <div style={{ width: '100%', backgroundColor: '#8a8686', height: 1 }}></div>


                                <div style={{ display: 'flex' }}>

                                    <div style={{ width: '100%', height: 70, flexDirection: 'column', flex: 1, display: 'flex' }}>
                                        <div style={{ width: '100%', height: 70, marginTop: 20, flexDirection: 'row', flex: 1, display: 'flex' }}>

                                            <div style={{ width: '100%', backgroundColor: '#f1f1f1', height: 50, borderRadius: 10, margin: 10, padding: 5 }}>
                                                <span style={{ fontSize: 9, color: '#797979' }}>Nome completo:</span>
                                                <p style={{ fontSize: 9, margin: 0 }}>{resultado(nomeCompleto)}</p>
                                            </div>

                                            <div style={{ width: '100%', backgroundColor: '#f1f1f1', height: 50, borderRadius: 10, margin: 10, padding: 5 }}>
                                                <span style={{ fontSize: 9, color: '#797979' }}>CPF:</span>
                                                <p style={{ fontSize: 9, margin: 0 }}>{resultado(cpf)}</p>
                                            </div>


                                            <div style={{ width: '100%', backgroundColor: '#f1f1f1', height: 50, borderRadius: 10, margin: 10, padding: 5 }}>
                                                <span style={{ fontSize: 9, color: '#797979' }}>Data de Nascimento:</span>
                                                <p style={{ fontSize: 9, margin: 0 }}>{resultado(dataNascimento)}</p>
                                            </div>

                                        </div>

                                        <div style={{ width: '100%', height: 120, marginTop: 20, flexDirection: 'column', flex: 1, display: 'flex', marginLeft: 10 }}>
                                            <span style={{ fontSize: 12, fontFamily: 'Montserrat' }}>Tudo certo com as informações acima? Quando terminar, clique em 'Confirmar' para salvar as alterações ou clique em 'Cancelar' caso queira apagar o que foi feito.</span>




                                            <div style={{ flexDirection: 'row', display: 'flex' }}>
                                                <div style={{ width: 150, height: 40, backgroundColor: 'white', borderRadius: 10, marginTop: 30, borderColor: '#262626', border: '2px solid #262626', textAlign: 'center', marginRight: 20 }}>
                                                    <p style={{ color: '#262626', paddingTop: 7 }}>Cancelar</p>
                                                </div>

                                                <div style={{ width: 150, height: 40, backgroundColor: '#262626', borderRadius: 10, marginTop: 30, borderColor: '#262626', border: '2px solid #262626', textAlign: 'center' }}>
                                                    <p style={{ color: '#262626', paddingTop: 7, color: '#f1e2c4' }}>Confirmar</p>
                                                </div>

                                            </div>



                                        </div>




                                    </div>




                                    <div style={{ width: '100%', height: 300, marginTop: 20, flexDirection: 'column', flex: 0.4, display: 'flex', marginLeft: 40 }}>

                                        <span style={{ fontFamily: 'Montserrat', fontSize: 17, marginTop: 15, color: '#797979' }}>Dicas para uma boa senha</span>

                                        <p style={{ paddingTop: 20, fontSize: 9, fontFamily: 'Montserrat', margin: 0 }}><span style={{ fontWeight: 'bold' }}>Comprimento mínimo:</span> Pelo menos 8 caracteres</p>
                                        <p style={{ paddingTop: 20, fontSize: 9, fontFamily: 'Montserrat', padding: 0, paddingTop: 7 }}><span style={{ fontWeight: 'bold' }}>Diversidade de caracteres:</span> Use letras maiúsculas, minúsculas, números e caracteres especiais.</p>
                                        <p style={{ paddingTop: 20, fontSize: 9, fontFamily: 'Montserrat', padding: 0, paddingTop: 7 }}><span style={{ fontWeight: 'bold' }}>Evite informações pessoais:</span> Não utilize dados pessoais óbvios.</p>
                                        <p style={{ paddingTop: 20, fontSize: 9, fontFamily: 'Montserrat', padding: 0, paddingTop: 7 }}><span style={{ fontWeight: 'bold' }}>Evite sequências óbvias:</span> Evite sequências simples ou repetitivas.</p>
                                        <p style={{ paddingTop: 20, fontSize: 9, fontFamily: 'Montserrat', padding: 0, paddingTop: 7 }}><span style={{ fontWeight: 'bold' }}>Não use palavras comuns:</span> Evite palavras encontradas em dicionários.</p>
                                        <p style={{ paddingTop: 20, fontSize: 9, fontFamily: 'Montserrat', padding: 0, paddingTop: 7 }}><span style={{ fontWeight: 'bold' }}>Senhas únicas:</span> Use senhas diferentes para cada conta.</p>
                                        <p style={{ paddingTop: 20, fontSize: 9, fontFamily: 'Montserrat', padding: 0, paddingTop: 7 }}><span style={{ fontWeight: 'bold' }}>Atualização regular:</span> Mude as senhas periodicamente.</p>


                                    </div>

                                </div>





                            </div>
                        </div>

                    </div>



                </div>



            </>

        )

    }




    return (
        <>

            <div style={{ height: '100vh', display: "flex", backgroundColor: '#262626' }}>
                <div style={{ backgroundColor: '#262626' }}>
                    <Menu page="configuracoes" menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
                </div>


                <div style={{ height: '100vh', display: "flex", width: "100%", overflow: 'auto' }}>
                    <div style={{ display: "flex", width: "100%", margin: 40, flexDirection: 'column', backgroundColor: 'white' }}>

                        <div style={{ width: '100%', flexDirection: 'column', display: "flex", height: 50, flexDirection: 'row' }}>

                            <div style={{ flex: 1, backgroundColor: '#262626', width: '100%', borderBottom: '4px solid #9DE200', textAlign: '-webkit-center', cursor: "pointer" }} onClick={() => { setParte('dados_cadastrais') }}>
                                <div style={{ width: '100%', flex: 1, height: '100%' }}>
                                    <p style={{ color: 'white', justifyContent: 'center', paddingTop: 17, fontSize: 12 }} >Dados cadastrais</p>
                                </div>
                            </div>

                            {/* <div style={{flex:1,backgroundColor:'#262626',width:'100%',borderBottom:'4px solid #262626',textAlign:'-webkit-center',cursor:"pointer"}} onClick={()=>{setParte('senha')}}>
                    <div style={{width:'100%',flex:1,height:'100%'}}>
                        <p style={{color:'white',justifyContent:'center',paddingTop:17,fontSize:12}} >Senha</p>
                    </div>
                </div> */}

                            <div style={{ flex: 1, backgroundColor: '#262626', width: '100%', borderBottom: '4px solid #262626', textAlign: '-webkit-center', cursor: "pointer" }} onClick={() => { setParte('dados_contato') }}>
                                <div style={{ width: '100%', flex: 1, height: '100%' }}>
                                    <p style={{ color: 'white', justifyContent: 'center', paddingTop: 17, fontSize: 12 }}>Dados de contato</p>
                                </div>
                            </div>

                            <div style={{ flex: 1, backgroundColor: '#262626', width: '100%', borderBottom: '4px solid #262626', textAlign: '-webkit-center', cursor: "pointer" }} onClick={() => { setParte('dados_bancarios') }}>
                                <div style={{ width: '100%', flex: 1, height: '100%' }}>
                                    <p style={{ color: 'white', justifyContent: 'center', paddingTop: 17, fontSize: 12 }}>Dados bancários</p>
                                </div>
                            </div>
                            {/* 
                <div style={{flex:1,backgroundColor:'#262626',width:'100%',borderBottom:'4px solid #262626',textAlign:'-webkit-center',cursor:"pointer"}} onClick={()=>{setParte('contas')}}>
                    <div style={{width:'100%',flex:1,height:'100%'}}>
                        <p style={{color:'white',justifyContent:'center',paddingTop:17,fontSize:12}}>Contas</p>
                    </div>
                </div> */}

                        </div>


                        <div style={{ width: '100%', padding: 20, overflow: 'auto' }}>
                            <span style={{ fontFamily: 'Montserrat' }}>{usuario}, seus dados estão seguros aqui.</span>

                            <p style={{ paddingTop: 40, fontSize: 25, fontFamily: 'Montserrat' }}>Dados pessoais</p>
                            <div style={{ width: '100%', backgroundColor: '#8a8686', height: 1 }}></div>


                            <div style={{ width: '100%', height: 70, marginTop: 20, flexDirection: 'row', flex: 1, display: 'flex' }}>

                                <div style={{ width: '100%', backgroundColor: '#f1f1f1', height: 50, borderRadius: 10, margin: 10, padding: 5 }}>
                                    <span style={{ fontSize: 9, color: '#797979' }}>Nome completo:</span>
                                    <p style={{ fontSize: 9, margin: 0 }}>{resultado(nomeCompleto)}</p>
                                </div>

                                <div style={{ width: '100%', backgroundColor: '#f1f1f1', height: 50, borderRadius: 10, margin: 10, padding: 5 }}>
                                    <span style={{ fontSize: 9, color: '#797979' }}>CPF:</span>
                                    <p style={{ fontSize: 9, margin: 0 }}>{resultado(cpf)}</p>
                                </div>


                                <div style={{ width: '100%', backgroundColor: '#f1f1f1', height: 50, borderRadius: 10, margin: 10, padding: 5 }}>
                                    <span style={{ fontSize: 9, color: '#797979' }}>Data de Nascimento:</span>
                                    <p style={{ fontSize: 9, margin: 0 }}>{resultado(dataNascimento)}</p>
                                </div>

                                <div style={{ width: '100%', backgroundColor: '#f1f1f1', height: 50, borderRadius: 10, margin: 10, padding: 5 }}>
                                    <span style={{ fontSize: 9, color: '#797979' }}>Gênero:</span>
                                    <p style={{ fontSize: 9, margin: 0 }}>{resultado(dadosX.sexo)}</p>
                                </div>

                            </div>


                            <div style={{ width: '100%', display: 'flex', height: 300, marginTop: 30 }}>
                                <div style={{ width: '100%', display: 'flex', flex: 2, flexDirection: 'column' }}>
                                    <span style={{ fontSize: 19, fontFamily: 'Montserrat' }}>Perfil e funcionalidades</span>

                                    <p style={{ fontSize: 14, marginTop: 20, fontFamily: 'Montserrat', marginBottom: 15 }}>O perfil atribuido ao seu usuário é o Proprietario, você tem acesso aos seguintes dados e funcionalidades do Xuliz Parceiros.</p>


                                    <div style={{ display: 'flex', marginTop: 20 }}>
                                        <div>
                                            <div style={{ color: '#262626', fontSize: 12, fontFamily: 'Montserrat', fontWeight: '400', wordWrap: 'break-word', marginBottom: 20 }}>  <FontAwesomeIcon icon={faCircleCheck} style={{ fontSize: 15, color: "green" }} /> Área de relatórios</div>
                                            <div style={{ color: '#262626', fontSize: 12, fontFamily: 'Montserrat', fontWeight: '400', wordWrap: 'break-word', marginBottom: 20 }}>  <FontAwesomeIcon icon={faCircleCheck} style={{ fontSize: 15, color: "green" }} /> Área XLoja</div>
                                            <div style={{ color: '#262626', fontSize: 12, fontFamily: 'Montserrat', fontWeight: '400', wordWrap: 'break-word', marginBottom: 20 }}>  <FontAwesomeIcon icon={faCircleCheck} style={{ fontSize: 15, color: "green" }} /> Home</div>
                                            <div style={{ color: '#262626', fontSize: 12, fontFamily: 'Montserrat', fontWeight: '400', wordWrap: 'break-word', marginBottom: 20 }}>  <FontAwesomeIcon icon={faCircleCheck} style={{ fontSize: 15, color: "green" }} /> Solicitações de suprimentos</div>
                                            <div style={{ color: '#262626', fontSize: 12, fontFamily: 'Montserrat', fontWeight: '400', wordWrap: 'break-word', marginBottom: 20 }}>  <FontAwesomeIcon icon={faCircleCheck} style={{ fontSize: 15, color: "green" }} /> Manutenção de maquininhas</div>

                                        </div>

                                        <div style={{ marginLeft: 40 }}>
                                            <div style={{ color: '#262626', fontSize: 12, fontFamily: 'Montserrat', fontWeight: '400', wordWrap: 'break-word', marginBottom: 20 }}>  <FontAwesomeIcon icon={faCircleCheck} style={{ fontSize: 15, color: "green" }} /> Área de produtos</div>
                                            <div style={{ color: '#262626', fontSize: 12, fontFamily: 'Montserrat', fontWeight: '400', wordWrap: 'break-word', marginBottom: 20 }}>  <FontAwesomeIcon icon={faCircleCheck} style={{ fontSize: 15, color: "green" }} /> Acessas Saques</div>
                                            <div style={{ color: '#262626', fontSize: 12, fontFamily: 'Montserrat', fontWeight: '400', wordWrap: 'break-word', marginBottom: 20 }}>  <FontAwesomeIcon icon={faCircleCheck} style={{ fontSize: 15, color: "green" }} /> Todas as ações de Marketing</div>
                                            <div style={{ color: '#262626', fontSize: 12, fontFamily: 'Montserrat', fontWeight: '400', wordWrap: 'break-word', marginBottom: 20 }}>  <FontAwesomeIcon icon={faCircleCheck} style={{ fontSize: 15, color: "green" }} /> Adicionar novas contas</div>

                                        </div>
                                    </div>



                                </div>
                                <div style={{ width: '100%', display: 'flex', flex: 0.3, flexDirection: 'column' }}>
                                </div>
                                <div style={{ width: '100%', display: 'flex', flex: 1, flexDirection: 'column' }}>

                                    <span style={{ fontSize: 19, fontFamily: 'Montserrat' }}>Informações de acesso</span>

                                    <p style={{ fontSize: 14, marginTop: 20, fontFamily: 'Montserrat', marginBottom: 15 }}>A seguir você irá ver informações para você consiga acessar sua área do Xuliz Parceiro, da empresa {usuario}</p>


                                    <p style={{ fontSize: 14, marginTop: 20, fontFamily: 'Montserrat', marginBottom: 5 }}>Login:</p>
                                    <span style={{ fontSize: 12, fontFamily: 'Montserrat', marginBottom: 15, fontWeight: 'bold' }}>{usuario}</span>


                                    <p style={{ fontSize: 14, marginTop: 10, fontFamily: 'Montserrat', marginBottom: 5 }}>Senha:</p>
                                    <span style={{ fontSize: 12, fontFamily: 'Montserrat', marginBottom: 15, fontWeight: 'bold' }}>******</span>

                                    <u style={{ fontSize: 14, marginTop: 4, fontFamily: 'Montserrat', marginBottom: 5 }}>Alterar senha</u>

                                </div>


                            </div>



                            {/* <div style={{width:'100%',height:70,marginTop:20,flexDirection:'row',flex:1,display:'flex'}}>

                </div> */}

                            <p style={{ paddingTop: 40, fontSize: 25, fontFamily: 'Montserrat' }}>Dados empresa</p>
                            <div style={{ width: '100%', backgroundColor: '#8a8686', height: 1 }}></div>




                            <div style={{ width: '100%', height: 70, marginTop: 20, flexDirection: 'row', flex: 1, display: 'flex' }}>

                                <div style={{ width: '100%', backgroundColor: '#f1f1f1', height: 50, borderRadius: 10, margin: 10, padding: 5 }}>
                                    <span style={{ fontSize: 9, color: '#797979' }}>Razão social:</span>
                                    <p style={{ fontSize: 9, margin: 0 }}>{resultado(dadosX.razaosocial)}</p>
                                </div>

                                <div style={{ width: '100%', backgroundColor: '#f1f1f1', height: 50, borderRadius: 10, margin: 10, padding: 5 }}>
                                    <span style={{ fontSize: 9, color: '#797979' }}>Nome fantasia:</span>
                                    <p style={{ fontSize: 9, margin: 0 }}>{dadosX.empresa}</p>
                                </div>


                                <div style={{ width: '100%', backgroundColor: '#f1f1f1', height: 50, borderRadius: 10, margin: 10, padding: 5 }}>
                                    <span style={{ fontSize: 9, color: '#797979' }}>CNPJ:</span>
                                    <p style={{ fontSize: 9, margin: 0 }}>{dadosX.cpf}</p>
                                </div>

                                <div style={{ width: '100%', backgroundColor: '#f1f1f1', height: 50, borderRadius: 10, margin: 10, padding: 5 }}>
                                    <span style={{ fontSize: 9, color: '#797979' }}>Inscrição estadual:</span>
                                    <p style={{ fontSize: 9, margin: 0 }}>{resultado(dadosX.inscricaoestadual)}</p>
                                </div>

                            </div>



                            <p style={{ paddingTop: 40, fontSize: 25, fontFamily: 'Montserrat' }}>Documentos cadastrados</p>
                            <div style={{ width: '100%', backgroundColor: '#8a8686', height: 1 }}></div>



                            <p style={{ fontSize: 14, color: '#797979', margin: 10, marginTop: 20 }}>Pessoal</p>

                            <div style={{ width: '100%', height: 70, marginTop: 10, flexDirection: 'row', flex: 1, display: 'flex' }}>

                                <div style={{ width: '100%', backgroundColor: '#f1f1f1', height: 50, borderRadius: 10, margin: 10, padding: 5 }}>
                                    <span style={{ fontSize: 9, color: '#797979' }}>Tipo de documento:</span>
                                    <p style={{ fontSize: 9, margin: 0 }}>CNH</p>
                                </div>

                                <div style={{ width: '100%', backgroundColor: '#f1f1f1', height: 50, borderRadius: 10, margin: 10, padding: 5 }}>
                                    <span style={{ fontSize: 9, color: '#797979' }}>Número documento:</span>
                                    <p style={{ fontSize: 9, margin: 0 }}>{resultado(dadosX.cnh)}</p>
                                </div>


                                <div style={{ width: '100%', backgroundColor: '#f1f1f1', height: 50, borderRadius: 10, margin: 10, padding: 5 }}>
                                    <span style={{ fontSize: 9, color: '#797979' }}>Data de emissão:</span>
                                    <p style={{ fontSize: 9, margin: 0 }}>24/05/2022</p>
                                </div>

                                <div style={{ width: '100%', backgroundColor: '#f1f1f1', height: 50, borderRadius: 10, margin: 10, padding: 5 }}>
                                    <span style={{ fontSize: 9, color: '#797979' }}>Órgão Emissor:</span>
                                    <p style={{ fontSize: 9, margin: 0 }}>SSP - SECR. DE Seguranca Publ.</p>
                                </div>

                            </div>



                            <div style={{ width: '100%', height: 70, marginTop: 10, flexDirection: 'row', flex: 1, display: 'flex' }}>

                                <div style={{ width: '100%', backgroundColor: '#f1f1f1', height: 50, borderRadius: 10, margin: 10, padding: 5 }}>
                                    <span style={{ fontSize: 9, color: '#797979' }}>Nacionalidade:</span>
                                    <p style={{ fontSize: 9, margin: 0 }}>BRA</p>
                                </div>

                                <div style={{ width: '100%', backgroundColor: '#f1f1f1', height: 50, borderRadius: 10, margin: 10, padding: 5 }}>
                                    <span style={{ fontSize: 9, color: '#797979' }}>Estado nascimento:</span>
                                    <p style={{ fontSize: 9, margin: 0 }}>Espirito Santo</p>
                                </div>


                                <div style={{ width: '100%', backgroundColor: '#f1f1f1', height: 50, borderRadius: 10, margin: 10, padding: 5 }}>
                                    <span style={{ fontSize: 9, color: '#797979' }}>Nome da mãe:</span>
                                    <p style={{ fontSize: 9, margin: 0 }}>Alessandra Santos</p>
                                </div>

                                <div style={{ width: '100%', backgroundColor: '#f1f1f1', height: 50, borderRadius: 10, margin: 10, padding: 5 }}>
                                    <span style={{ fontSize: 9, color: '#797979' }}>Nome do pai:</span>
                                    <p style={{ fontSize: 9, margin: 0 }}>Matiel Santana</p>
                                </div>

                            </div>


                            <p style={{ fontSize: 14, color: '#797979', margin: 10, marginTop: 20 }}>Empresa</p>

                            <div style={{ width: '100%', height: 70, marginTop: 10, flexDirection: 'row', flex: 1, display: 'flex' }}>

                                <div style={{ width: '100%', backgroundColor: '#f1f1f1', height: 50, borderRadius: 10, margin: 10, padding: 5 }}>
                                    <span style={{ fontSize: 9, color: '#797979' }}>Porte:</span>
                                    <p style={{ fontSize: 9, margin: 0 }}>ME</p>
                                </div>

                                <div style={{ width: '100%', backgroundColor: '#f1f1f1', height: 50, borderRadius: 10, margin: 10, padding: 5 }}>
                                    <span style={{ fontSize: 9, color: '#797979' }}>Documento Anexado:</span>
                                    <p style={{ fontSize: 9, margin: 0 }}>Certificado MEI</p>
                                </div>


                                <div style={{ width: '100%', backgroundColor: '#f1f1f1', height: 50, borderRadius: 10, margin: 10, padding: 5 }}>
                                    <span style={{ fontSize: 9, color: '#797979' }}>Data abertura:</span>
                                    <p style={{ fontSize: 9, margin: 0 }}>30/12/2022</p>
                                </div>

                                <div style={{ width: '100%', backgroundColor: 'white', height: 50, borderRadius: 10, margin: 10, padding: 5 }}>

                                </div>

                            </div>






                            <p style={{ paddingTop: 40, fontSize: 25, fontFamily: 'Montserrat' }}>Endereço</p>
                            <div style={{ width: '100%', backgroundColor: '#8a8686', height: 1 }}></div>




                            <div style={{ width: '100%', height: 70, marginTop: 10, flexDirection: 'row', flex: 1, display: 'flex' }}>

                                <div style={{ width: '100%', backgroundColor: '#f1f1f1', height: 50, borderRadius: 10, margin: 10, padding: 5 }}>
                                    <span style={{ fontSize: 9, color: '#797979' }}>CEP:</span>
                                    <p style={{ fontSize: 9, margin: 0 }}>{resultado(dadosX.cep)}</p>
                                </div>

                                <div style={{ width: '100%', backgroundColor: '#f1f1f1', height: 50, borderRadius: 10, margin: 10, padding: 5 }}>
                                    <span style={{ fontSize: 9, color: '#797979' }}>Endereço:</span>
                                    <p style={{ fontSize: 9, margin: 0 }}>{resultado(dadosX.endereco)}</p>
                                </div>


                                <div style={{ width: '100%', backgroundColor: '#f1f1f1', height: 50, borderRadius: 10, margin: 10, padding: 5 }}>
                                    <span style={{ fontSize: 9, color: '#797979' }}>Número:</span>
                                    <p style={{ fontSize: 9, margin: 0 }}>{resultado(dadosX.numero)}</p>
                                </div>

                                <div style={{ width: '100%', backgroundColor: '#f1f1f1', height: 50, borderRadius: 10, margin: 10, padding: 5 }}>
                                    <span style={{ fontSize: 9, color: '#797979' }}>Complemento:</span>
                                    <p style={{ fontSize: 9, margin: 0 }}>{resultado(dadosX.complemento)}</p>
                                </div>

                            </div>



                            <div style={{ width: '100%', height: 70, marginTop: 10, flexDirection: 'row', flex: 1, display: 'flex' }}>

                                <div style={{ width: '100%', backgroundColor: '#f1f1f1', height: 50, borderRadius: 10, margin: 10, padding: 5 }}>
                                    <span style={{ fontSize: 9, color: '#797979' }}>Estado:</span>
                                    <p style={{ fontSize: 9, margin: 0 }}>{resultado(dadosX.uf)}</p>
                                </div>

                                <div style={{ width: '100%', backgroundColor: '#f1f1f1', height: 50, borderRadius: 10, margin: 10, padding: 5 }}>
                                    <span style={{ fontSize: 9, color: '#797979' }}>Cidade:</span>
                                    <p style={{ fontSize: 9, margin: 0 }}>{resultado(dadosX.cidade)}</p>
                                </div>


                                <div style={{ width: '100%', backgroundColor: '#f1f1f1', height: 50, borderRadius: 10, margin: 10, padding: 5 }}>
                                    <span style={{ fontSize: 9, color: '#797979' }}>Bairro:</span>
                                    <p style={{ fontSize: 9, margin: 0 }}>{resultado(dadosX.bairro)}</p>
                                </div>

                                <div style={{ width: '100%', backgroundColor: 'white', height: 50, borderRadius: 10, margin: 10, padding: 5 }}>

                                </div>

                            </div>



                            <p style={{ paddingTop: 40, fontSize: 16, fontFamily: 'Montserrat', fontWeight: '600' }}>Como atualizar os campos do meu cadastro?</p>

                            <p style={{ fontSize: 12, fontFamily: 'Montserrat' }}>Para garantir a segurança dos seus dados, os campos acima não estão disponíveis para alteração ou edição diretamente na sua área logada do Xuliz parceiro.</p>

                            <p style={{ fontSize: 12, fontFamily: 'Montserrat' }}>Caso precise atualizar alguma informação, pedimos que abra um chamado na aba Central de ajuda. Nosso time de cadastro analisará sua solicitação e a concluirá a alteração ou entrará em contato em até 1 dia útil.</p>

                            <p style={{ fontSize: 12, fontFamily: 'Montserrat' }}>É importante ressaltar que o CPF/CNPJ cadastrado em sua conta Xuliz não pode ser alterado.</p>




                            <div style={{ width: '100%', height: 100, marginTop: 10, flexDirection: 'row', flex: 1, display: 'flex' }}>
                            </div>







                        </div>
                    </div>

                </div>



            </div>



        </>

    )

};

export default Configuracoes;

import { useState } from 'react';
import bigPlus from '../../assets/icons/bigPlus.png';
import closeCircle from '../../assets/icons/closeCircle.png';
import fileIconCircle from '../../assets/icons/fileIconCircle.png';
import { Modal, Grid, Typography, Button } from '@mui/material';

function ModalUploadFile({ modal, close, file, setFile, fieldName, useId, id }) {

    const [temporariFile, setTemporariFile] = useState(file ? file : {});

    const sendFile = () => {
        if (useId) {
            setFile(id, fieldName, temporariFile);
            close();
        } else {
            setFile(fieldName, temporariFile);
            close();
        }
    }

    // Manipulador para selecionar o arquivo via clique
    const handleFileSelect = (event) => {
        setTemporariFile(event.target.files[0]);
    };

    // dlimpa o campo
    const handleFileSelectClear = () => {
        setTemporariFile({});
    };

    // Manipulador para arrastar e soltar o arquivo
    const handleDrop = (event) => {
        event.preventDefault();
        if (event.dataTransfer.files && event.dataTransfer.files[0]) {
            setTemporariFile(event.dataTransfer.files[0]);
        }
    };

    // Previne o comportamento padrão ao arrastar o arquivo sobre a área
    const handleDragOver = (event) => {
        event.preventDefault();
    };

    // Função para abrir o seletor de arquivos ao clicar
    const openFileDialog = () => {
        document.getElementById('fileInput').click();
    };

    return (
        <Modal open={modal} onClose={close}>
            <Grid
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                sx={{
                    width: '100%',
                    maxWidth: '748px',
                    top: '50%',
                    left: '50%',
                    boxShadow: 24,
                    border: 'none',
                    bgcolor: '#F0EFEB',
                    padding: '46px 49px',
                    borderRadius: '20px',
                    position: 'absolute',
                    transform: 'translate(-50%, -50%)'
                }}
            >
                <Grid sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '35px' }}>
                    <Typography sx={{ fontSize: '24px', color: '#142A30', fontWeight: 700 }}>
                        Faça o upload do arquivo
                    </Typography>
                    <img
                        src={closeCircle}
                        onClick={close}
                        alt="Close"
                        style={{ width: '30px', height: '30px', cursor: 'pointer' }}
                    />
                </Grid>
                {temporariFile?.name ?
                    < Grid sx={{
                        display: 'flex',
                        padding: '20px 30px',
                        flexDirection: 'row',
                        alignItems: 'center',
                        borderRadius: '30px',
                        bgcolor: '#142A301A',
                        justifyContent: 'space-between',
                        marginBottom: '30px'
                    }}
                    >
                        <Grid sx={{
                            gap: '15px',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <img
                                src={fileIconCircle}
                                onClick={close}
                                alt="Close"
                                style={{ width: '30px', height: '30px', cursor: 'pointer' }}
                            />
                            <Typography sx={{ fontSize: '15px', color: '#142A30', fontWeight: 600 }}>
                                {temporariFile.name}
                            </Typography>
                            <Typography sx={{ fontSize: '15px', color: '#142A3099' }}>
                                {temporariFile.size}Mb
                            </Typography>
                        </Grid>

                        <Button
                            onClick={handleFileSelectClear}
                            sx={{
                                borderRadius: '30px',
                                '&:hover': { backgroundColor: 'none' },
                                '&:active': { backgroundColor: 'none' }
                            }}
                        >
                            <Typography sx={{ fontWeight: 600, color: '#9E4848', textTransform: 'none', padding: '3px' }}>
                                Excluir
                            </Typography>
                        </Button>
                    </Grid>
                    :
                    <Grid
                        onClick={openFileDialog}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '10px',
                            borderStyle: 'dashed',
                            borderWidth: '1.5px',
                            borderColor: '#142A3066',
                            borderRadius: '30px',
                            marginBottom: '35px',
                            cursor: 'pointer'
                        }}
                    >
                        <Grid sx={{ gap: '15px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={bigPlus} alt="Plus" style={{ width: '30px', height: '30px' }} />
                            <Grid>
                                <Typography sx={{ fontSize: '15px', color: '#142A30', fontWeight: 700 }}>
                                    Arraste seu arquivo ou
                                </Typography>
                                <Typography sx={{ fontSize: '15px', color: '#7FA800', fontWeight: 700 }}>
                                    clique para procurar
                                </Typography>
                            </Grid>
                        </Grid>
                        <input
                            type="file"
                            id="fileInput"
                            style={{ display: 'none' }}
                            onChange={handleFileSelect}
                        />
                    </Grid>
                }

                <Button
                    onClick={sendFile}
                    sx={{
                        width: '100%',
                        borderRadius: '30px',
                        bgcolor: '#17333A',
                        '&:hover': { backgroundColor: '#142A30' },
                        '&:active': { backgroundColor: '#142A30' }
                    }}
                >
                    <Typography sx={{ color: '#FFFFFF', textTransform: 'none', padding: '3px' }}>
                        Enviar
                    </Typography>
                </Button>
            </Grid>
        </Modal >
    );
}

export default ModalUploadFile;

import React from 'react';
import { Grid, Typography } from '@mui/material';
import { format, getDate, parseISO } from 'date-fns';

const Calendario = ({ hoje, agora, screenWidth, allDays, toggleValue, formatCurrency, selectedValues }) => {

  const week = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];
  const today = new Date();

  const getStatus = (i) => {
    if (i.status == '') {
      return 'Sem venda';
    } else if (i.data == format(today, 'yyyy-MM-dd') && agora < '15:10') {
      return 'A receber'
    } else {
      const dataDate = new Date(i.data);
      return dataDate <= today ? 'Pago' : 'A receber';
    }
  };

  function calcMimMax(min, max, divide) {
    const calculated = screenWidth / divide;

    if (calculated > min && calculated < max) {
      return calculated;
    } else if (calculated < min) {
      return min;
    } else if (calculated > max) {
      return max;
    }

    return 11;
  }

  return (
    <Grid container direction="column"
      sx={{
        // height: allDays.length >= 36 ? '893px' : '763px',
        border: '1px solid #999999',
        borderRadius: '15px',
        display: 'flex',
        justifyContent: 'space-between',

      }}>
      <Grid container sx={{
        // height: '5%',
        bgcolor: '#17333A',
        // height: '45px',
        borderTopRightRadius: '10px',
        borderTopLeftRadius: '10px',
        display: 'flex',
        justifyContent: 'space-around'
      }}>
        {week.map((dayName, index) => (
          <Grid item key={index}>
            <Typography align="center" sx={{ fontSize: '15px', color: '#9DE200', height: '45px', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>{dayName}</Typography>
          </Grid>
        ))}
      </Grid>
      <Grid container sx={{
        display: 'flex',
        borderRadius: '10px',
        justifyContent: 'flex-start',
        // height: '100%',
        height: allDays.length >= 36 ? '846px' : screenWidth <= 1050 ? '630px' : '696px'
        // height: allDays.length >= 36 ? '846px' : screenWidth <= 1050 ? '630px' : '716px'
      }}>
        {allDays.map((i, index) => (
          <Grid item key={index}
            onClick={() => toggleValue(i)}
            sx={{
              width: `${100 / 7}%`,
              height: `${100 / (allDays.length >= 36 ? 6 : 5)}%`,
              cursor: 'pointer',
              padding: screenWidth <= 1050 ? '5px' : '19px 16px 18px 12px',
              border: '1px solid #999999',
              bgcolor: selectedValues.find(item => item.data === i.data && item.day === i.day) ? '#FFF8EA' : '',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between'
            }}
          >
            {i.empty ? null :
              <>
                <Grid sx={{
                  display: 'flex',
                  flexDirection: screenWidth <= 1760 ? 'column' : 'row',
                  justifyContent: 'space-between',
                  paddingBottom: screenWidth <= 1667 ? '5px' : '30px'
                }}>
                  <Typography sx={{ fontSize: calcMimMax(10, 13, 147), color: '#5A8292', fontWeight: 700 }}>
                    {i.day || getDate(parseISO(i.data))}
                  </Typography>
                  <Typography
                    sx={{
                      width: getStatus(i) == 'Pago' ? '5ch' : getStatus(i) == 'A receber' ? '8ch' : getStatus(i) == 'Sem venda' ? '9ch' : null,
                      padding: '3px',
                      fontWeight: 700,
                      fontSize: '10px',
                      color: getStatus(i) == 'Sem venda' ? '#FFFFFF' : '#142A30',
                      bgcolor: getStatus(i) == 'Pago' ? '#9DE200' : getStatus(i) == 'A receber' ? '#FFB74D' : '#E57373',
                      borderRadius: '7px'
                    }}>
                    {getStatus(i)}
                  </Typography>
                </Grid>
                <Grid sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                  <Typography sx={{ fontSize: calcMimMax(7, 15, 137), fontWeight: 700, color: '#142A30' }}>
                    {i.data == hoje ? formatCurrency(i.total - i.pix) || 'R$ 0,00' : formatCurrency(i.total) || 'R$ 0,00'}
                  </Typography>
                  <Typography sx={{ fontSize: '10px' }}>
                    {i.totalPedidos} vendas
                  </Typography>
                </Grid>
              </>
            }
          </Grid>
        ))}
      </Grid>
    </Grid >
  );
};

export default Calendario;

import { useEffect } from "react";
import { Button } from "react-bootstrap";
import Stylesheet from "reactjs-stylesheet";
import { Grid, OutlinedInput, TextField, Typography } from "@mui/material"

import ResumoVendasHoje from "./ResumoVendasHoje";
import CustomSeparator from "../componentes/Breadcrumb";

import question from "../assets/icons/question.png";
import user from "../assets/icons/user.png";
import arrowRight from "../assets/icons/arrowRigth.png";
import arrowUpRigth from "../assets/icons/arrowUpRigth.png";
import arrowRigthUpMini from "../assets/icons/arrowRigthUpMini.png";
import phone from "../assets/icons/phone-dark.png";
import check from "../assets/icons/check-dark.png";
import InputMask from 'react-input-mask';

const RealizarVenda = ({
    menuOpen,
    screenWidth,
    dadosClinte,
    access,
    value,
    handleChange,
    identificarNumero,
    setUserForm,
    userForm,
    clickEnviar,
    clickEnviou,
    isMobile,
    cashbackConta,
    cashbackCliente,
    cashbackplusvenda,
    infoValore,
    setInfoValore
}) => {

    const reduceName = require('../globalFunctions/reduceName');

    useEffect(() => {
        setInfoValore(1)
    }, [dadosClinte])

    function calcMimMax(min, max, divide) {
        const calculated = screenWidth / divide;

        if (calculated > min && calculated < max) {
            return calculated;
        } else if (calculated < min) {
            return min;
        } else if (calculated > max) {
            return max;
        }

        return 16;
    }

    const nextInfo = () => {
        clickEnviar();
    }

    const infoValores = () => {
        switch (infoValore) {
            case 1:
                return (
                    <>
                        <Grid>
                            <Grid>
                                <Typography sx={{ ...styles.chargedTitleTotal, fontSize: calcMimMax(12, 16, 100) }}>
                                    Total cobrado:
                                </Typography>
                                <Typography sx={[styles.chargedPriceTotal, {
                                    fontSize: calcMimMax(32, 48, 39 + value.length)
                                }
                                ]}>
                                    {value ? value : 'R$ 0,00'}
                                </Typography>
                            </Grid>

                            <Grid>
                                <Typography sx={{ ...styles.chargedCashbackTotalTitle, fontSize: calcMimMax(12, 16, 100) }}>
                                    Cashback total cliente:
                                </Typography>
                                <Typography sx={[styles.chargedCashbackTotalPrice, {
                                    fontSize: calcMimMax(32, 48, 39 + value.length)
                                }
                                ]}>
                                    {cashbackCliente}
                                    <img src={arrowUpRigth}
                                        style={{
                                            width: value.length <= 7 ? '25px' : `${35 - (value.length)}px`,
                                            height: value.length <= 7 ? '25px' : `${35 - (value.length)}px`
                                        }}
                                        alt="Usuário" />
                                </Typography>
                            </Grid>
                        </Grid>

                        <Button disabled={
                            clickEnviou || !value || !userForm.chavecliente || !userForm.chavetipo || !dadosClinte
                        }
                            style={{ ...styles.chargedCashbackTotaButton, width: calcMimMax(147, 210, 9) }} onClick={() => nextInfo()}>
                            <Typography sx={{ ...styles.chargedCashbackTotaButtonText, fontSize: calcMimMax(14, 20, 100) }}>
                                Enviar venda
                            </Typography>
                            <img src={arrowRight} style={{
                                width: '8px', height: "13px", display: 'flex', alignItems: 'center', justifyContent: 'center'
                            }} alt="Usuário" />
                        </Button>
                    </>
                );
            case 2:
                return (
                    <>
                        <Grid sx={{ width: '82px', height: '82px', backgroundColor: '#FFB74D', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '20px' }} onClick={() => setInfoValore(3)}>
                            <img src={phone} style={{ width: '24px', height: '35px' }} />
                        </Grid>

                        <Grid sx={{ paddingTop: '45px' }}>
                            <Typography sx={{ maxWidth: '12ch', fontSize: '20px', color: '#FFFFFF', fontWeight: 600 }} >
                                A notificação foi enviada para,
                            </Typography>
                            <Typography sx={{ paddingTop: '10px', maxWidth: '15ch', fontSize: '60px', color: '#9DE200', fontWeight: 600 }} >
                                {dadosClinte?.nome?.split(' ')[0]}
                            </Typography>
                            <Grid sx={{ bgcolor: '#FFB74D', maxWidth: '155px', padding: '5px', borderRadius: '60px', textAlign: 'center' }}>
                                <Typography sx={{ fontSize: '13px', fontWeight: 600, color: '#142A30' }}>
                                    Espere a confirmação
                                </Typography>
                            </Grid>
                        </Grid>
                    </>
                );
            case 3:
                return (
                    <>
                        <Grid sx={{ width: '82px', height: '82px', backgroundColor: '#81C784', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '20px' }} onClick={() => setInfoValore(1)}>
                            <img src={check} style={{ width: '37px', height: '25px' }} />
                        </Grid>

                        <Grid sx={{ paddingTop: '45px' }}>
                            <Typography sx={{ maxWidth: '14ch', fontSize: '20px', color: '#FFFFFF', fontWeight: 600 }} >
                                O pagamento foi bem-sucedido por,
                            </Typography>
                            <Typography sx={{ paddingTop: '10px', maxWidth: '15ch', fontSize: '60px', color: '#9DE200', fontWeight: 600 }} >
                                {dadosClinte?.nome?.split(' ')[0]}
                            </Typography>
                            <Grid sx={{ bgcolor: '#81C784', maxWidth: '100px', padding: '5px', borderRadius: '60px', textAlign: 'center' }}>
                                <Typography sx={{ fontSize: '13px', fontWeight: 600, color: '#142A30' }}>
                                    Aprovado
                                </Typography>
                            </Grid>
                        </Grid>
                    </>
                );
            default:
                return null;
        }
    };



    return (

        <Grid item xs sx={screenWidth <= 1400 ? styles.contentSmallScreen : isMobile ? styles.contentMobile : styles.content}>

            {isMobile ? null :
                <Grid sx={[screenWidth <= 1400 ? styles.customSeparatorSmallScreen : styles.customSeparator, { flexDirection: screenWidth <= 800 ? 'column' : 'row' }]}>
                    <CustomSeparator menuOption={'Xloja'} item={0} />
                    <div style={{ height: 50, display: "flex", width: "30%", alignItems: 'center', justifyContent: 'flex-end' }}>
                        <span style={{ marginLeft: 10, fontSize: 20, marginLeft: 30, fontWeight: "bold" }} id="horas"></span>
                    </div>
                </Grid>
            }

            <Grid container spacing={0}
                sx={[styles.gridContet,
                {
                    width: '100%',
                    padding: screenWidth <= 1400 ? '25px' : '40px 35px 40px 50px',
                }
                ]}
            >
                <Grid item
                    sx={[styles.dataSection,
                    {
                        maxWidth: screenWidth <= 1015 ? '100%' : menuOpen && screenWidth <= 1300 ? '100%' : '40%'
                    }]}>

                    <Grid container sx={styles.dataTitleHelp}>
                        <Typography sx={{
                            ...styles.dataTitle,
                            fontSize: calcMimMax(24, 32, 42)
                        }}>
                            Realizar venda
                        </Typography>
                        {isMobile || screenWidth <= 970 ? null :
                            <Grid item sx={styles.gridHelp}>
                                <img src={question} style={styles.gridImg} alt="Ícone de ajuda" />
                                <Typography sx={{ ...styles.textHelp, fontSize: calcMimMax(11, 14, 100) }}>
                                    Central de ajuda
                                </Typography>
                            </Grid>
                        }
                    </Grid>
                    <Grid container sx={styles.gridInputs}>
                        <InputMask
                            maskChar={null}
                            mask={userForm.chavetipo == 0 ? "99999999999" : userForm.chavetipo == 'cpf' ? "999.999.999-99" : "(99) 99999-9999"}
                            onChange={(i) => identificarNumero(i.target.value)}
                        >
                            {() => (
                                <TextField
                                    placeholder="*Digite a chave"
                                    type="text"
                                    sx={{
                                        ...styles.customInputNumber,
                                        '& .MuiInputBase-input': {
                                            color: '#142A30',
                                            fontWeight: 'bold',
                                            fontSize: calcMimMax(12, 16, 100),
                                        },
                                        '& .MuiInputBase-input::placeholder': {
                                            fontSize: calcMimMax(12, 16, 100),
                                            color: '#142A30', // Define a cor do placeholder
                                            opacity: 1, // Opacidade do placeholder
                                            fontWeight: 'bold', // Deixa o placeholder em negrito
                                        },
                                        width: '37%',
                                        // maxWidth: calcMimMax(139, 190, 10)
                                        // maxWidth: screenWidth <= 1024 ? '139px' : '190px'
                                    }}
                                />
                            )}
                        </InputMask>
                        <OutlinedInput
                            sx={{
                                ...styles.customInputNumber,
                                '& .MuiInputBase-input': {
                                    color: '#142A30',
                                    fontWeight: 'bold',
                                    fontSize: calcMimMax(12, 16, 100),
                                },
                                '& .MuiInputBase-input::placeholder': {
                                    fontSize: calcMimMax(12, 16, 100),
                                    color: '#142A30', // Define a cor do placeholder
                                    opacity: 1, // Opacidade do placeholder
                                    fontWeight: 'bold', // Deixa o placeholder em negrito
                                },
                                width: '60%',
                                // maxWidth: calcMimMax(193, 400, screenWidth <= 1400 ? 5.4 : 4)
                                // maxWidth: screenWidth <= 1024 ? '193px' : '400px'
                            }}
                            type="text"
                            placeholder="O nome vai aparecer..."
                            value={dadosClinte?.nome ? dadosClinte.nome : ''}
                        />
                    </Grid>

                    <Grid container
                        sx={[styles.gridPrice, {
                            maxWidth: '100%'
                        }]}>
                        <Grid>
                            <Typography sx={{ ...styles.titlePrice, fontSize: calcMimMax(12, 20, 100) }}>
                                Digite o valor de cobrança:
                            </Typography>
                            <OutlinedInput
                                sx={[styles.customInputBigPrice, {
                                    '& .MuiInputBase-input': {
                                        fontSize: calcMimMax(40, 64, 40)
                                    },
                                }]}
                                placeholder="R$ 0,00"
                                onChange={handleChange}
                                value={value} />
                            <Grid sx={styles.gridCashback}>
                                <Typography sx={{ ...styles.titleCashback, fontSize: calcMimMax(12, 14, 100) }}>
                                    -{access && cashbackplusvenda > '0' ? cashbackplusvenda : cashbackConta},00% cashback
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid sx={{ marginTop: 4 }}>
                            <Typography sx={{ ...styles.titleCod, fontSize: calcMimMax(12, 16, 100) }}>
                                Digite um código de venda abaixo, caso deseje:
                            </Typography>
                            <OutlinedInput
                                sx={{
                                    ...styles.customInputCod,
                                    '& .MuiInputBase-input': {
                                        color: '#142A30', // Cor do texto
                                        fontWeight: 'bold', // Define a fonte como bold
                                        fontSize: calcMimMax(13, 16, 100)
                                    },
                                    '& .MuiInputBase-input::placeholder': {
                                        color: '#142A30', // Define a cor do placeholder
                                        opacity: 1, // Opacidade do placeholder
                                        fontWeight: 'bold', // Negrito
                                        fontSize: calcMimMax(13, 16, 100)
                                    },
                                    width: '100%',
                                    maxWidth: '280px'
                                }}
                                placeholder="Código de venda"
                                value={userForm.codVenda}
                                onChange={(i) => setUserForm({ ...userForm, codVenda: i.target.value })}
                            />
                        </Grid>
                    </Grid>

                </Grid>


                <Grid item
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: screenWidth <= 1015 ? '100%' : menuOpen && screenWidth <= 1300 ? '100%' : '59%',
                        flexDirection: screenWidth <= 940 ? 'column-reverse' : menuOpen && screenWidth <= 1300 ? 'column-reverse' : 'row'
                    }}>

                    <Grid
                        sx={[screenWidth <= 1400 ? styles.collectionSummarySmallScreen : styles.collectionSummary, {
                            width: screenWidth <= 940 ? '100%' : menuOpen && screenWidth <= 1300 ? '100%' : '50%',
                            marginRight: screenWidth <= 1600 ? '10px' : '15px',
                            marginTop: screenWidth <= 1015 ? '20px' : menuOpen && screenWidth <= 1300 ? '20px' : null,
                        }]}>
                        <Typography sx={{ ...styles.titleSummary, fontSize: calcMimMax(16, 24, 100) }}>
                            Resumo de cobrança
                        </Typography>

                        <Grid sx={styles.customerGrid}>
                            {dadosClinte?.urlfotoperfil ? (
                                <img src={dadosClinte?.urlfotoperfil} style={styles.customerImg} alt="Perfil" />
                            ) : (
                                <Grid sx={styles.defaultImgGrid}>
                                    <img src={user} style={styles.defaultImg} alt="Usuário" />
                                </Grid>
                            )}
                            <Typography sx={{ ...styles.defaultName, fontSize: calcMimMax(13, 16, 100) }}>
                                {dadosClinte?.nome ? reduceName(dadosClinte.nome) : 'Nome do cliente'}
                            </Typography>
                        </Grid>

                        <Grid sx={styles.chargedGrid}>
                            <Typography sx={{ ...styles.chargedTitle, fontSize: calcMimMax(14, 16, 100) }}>
                                Total cobrado:
                            </Typography>
                            <Typography sx={{ ...styles.chargedPrice, fontSize: calcMimMax(15, 16, 100) }}>
                                {value ? value : 'R$ 0,00'}
                            </Typography>
                        </Grid>

                        <Grid sx={{ paddingTop: '15px' }}>
                            <Typography sx={{ ...styles.chargedTitle, fontSize: calcMimMax(14, 16, 100) }}>
                                Cashback cliente:
                            </Typography>
                            <Grid sx={styles.gridCashbackPercent}>
                                <Typography sx={[styles.chargedPrice, { fontSize: calcMimMax(15, 16, 100), flexWrap: 'wrap', paddingRight: '5px' }]}>
                                    {access && cashbackplusvenda > '0' ? cashbackplusvenda : cashbackConta},00% - {cashbackCliente}
                                    <img src={arrowRigthUpMini} style={{ width: "10px", height: "10px" }} alt="Usuário" />
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid sx={{ paddingTop: '15px' }}>
                            <Typography sx={{ fontSize: calcMimMax(14, 16, 100), color: '#142A30' }}>
                                Cashback+
                            </Typography>

                            {access ?
                                <Grid sx={{ bgcolor: '#7FA800', padding: '5px 5px', borderRadius: '60px' }}>
                                    <Typography sx={{ fontSize: calcMimMax(12, 13, 100), textAlign: 'center', fontWeight: 600, color: '#FFFFFF' }}>
                                        +{cashbackCliente}
                                    </Typography>
                                </Grid>
                                :
                                <Grid sx={{ bgcolor: '#142A3066', maxWidth: '193px', padding: '5px 5px', borderRadius: '60px' }}>
                                    <Typography sx={{ fontSize: calcMimMax(12, 13, 100), fontWeight: 700, color: '#FFFFFF' }}>
                                        {cashbackplusvenda > 0 ? 'Empresa disponibiliza' : 'Empresa não disponibiliza'}
                                    </Typography>
                                </Grid>
                            }

                        </Grid>

                    </Grid>

                    <Grid sx={[
                        screenWidth <= 1400 ? styles.chargedGridTotalSmallScreen : styles.chargedGridTotal, {
                            width: screenWidth <= 940 ? '100%' : menuOpen && screenWidth <= 1300 ? '100%' : '50%',
                            marginTop: screenWidth <= 1015 ? '20px' : menuOpen && screenWidth <= 1300 ? '20px' : null,
                        }]}>
                        {infoValores()}
                    </Grid>

                </Grid>

            </Grid>

            <ResumoVendasHoje screenWidth={screenWidth} />

        </Grid >

    );
};

export default RealizarVenda;

const styles = Stylesheet.create({
    content: {
        margin: '40px 50px 16px 70px',
    },
    contentSmallScreen: {
        margin: '30px 25px'
    },
    contentMobile: {
        margin: '0px 10px'
    },
    customSeparator: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '43px'
    },
    customSeparatorSmallScreen: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-around',
        marginBottom: '20px'
    },
    gridContet: {
        bgcolor: '#F0EFEB',
        borderRadius: '30px',
        height: 'auto',
        display: 'flex',
        justifyContent: 'space-between',
    },
    dataSection: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    dataTitleHelp: {
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'space-between'
    },
    dataTitle: {
        fontWeight: 700,
        color: '#262626'
    },
    gridHelp: {
        display: 'flex',
        alignItems: 'center',
    },
    gridImg: {
        paddingRight: 6,
        width: 25
    },
    textHelp: {
        fontWeight: 700,
        color: '#142A3099'
    },
    gridInputs: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '21px',
        justifyContent: 'space-between'
    },
    gridPrice: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        bgcolor: '#FEFDF9',
        borderRadius: '20px',
        marginTop: 2,
        padding: '28px 28px',
    },
    titlePrice: {
        color: '#142A3099'
    },
    gridCashback: {
        bgcolor: '#9DE200',
        maxWidth: '150px',
        padding: '5px 10px',
        borderRadius: '60px',
        gap: '10px'
    },
    titleCashback: {
        fontWeight: 700,
        color: '#142A30'
    },
    titleCod: {
        color: '#142A3099'
    },
    collectionSummary: {
        bgcolor: '#FEFDF9',
        borderRadius: '20px',
        padding: "40px 0px 40px 26px",
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    collectionSummarySmallScreen: {
        bgcolor: '#FEFDF9',
        borderRadius: '20px',
        padding: "30px 0px 30px 30px",
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    titleSummary: {
        width: '10ch',
        fontWeight: 700,
        color: '#142A30'
    },
    customerGrid: {
        display: 'flex',
        paddingTop: '27px'
    },
    customerImg: {
        width: 60,
        height: 60,
        objectFit: "cover",
        borderRadius: 30
    },
    defaultImgGrid: {
        width: '60px',
        height: "60px",
        bgcolor: '#F1F1F1',
        borderRadius: '50px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    defaultImg: {
        width: '25px',
        height: "25px"
    },
    defaultName: {
        width: '13ch',
        fontWeight: 700,
        color: '#142A30',
        paddingLeft: '15px',
        alignContent: 'center'
    },
    chargedGrid: {
        paddingTop: '27px'
    },
    chargedTitle: {
        color: '#142A30'
    },
    chargedPrice: {
        fontWeight: 700,
        color: '#142A30'
    },
    gridCashbackPercent: {
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center'
    },
    chargedGridTotal: {
        bgcolor: '#17333A',
        borderRadius: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        padding: '60px 60px',
    },
    chargedGridTotalSmallScreen: {
        bgcolor: '#17333A',
        borderRadius: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        padding: '30px 30px',
    },
    chargedTitleTotal: {
        color: '#FEFDF9',
        maxWidth: '135px',
        border: "1px solid #9DE200",
        borderRadius: '60px',
        padding: '5px',
        textAlign: 'center'
    },
    chargedPriceTotal: {
        fontWeight: 700,
        color: '#FFFFFF',
    },
    chargedCashbackTotalTitle: {
        color: '#FEFDF9',
        maxWidth: '200px',
        border: "1px solid #9DE200",
        borderRadius: '60px',
        padding: '5px',
        textAlign: 'center',
        marginTop: '15px'
    },
    chargedCashbackTotalPrice: {
        fontWeight: 700,
        color: '#9DE200',
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap'
    },
    chargedCashbackTotaButton: {
        borderRadius: '15px',
        backgroundColor: '#9DE200',
        border: 'none',
        marginTop: '40px',
        display: 'flex',
        alignItems: 'center',
        padding: '7px 15px',
        justifyContent: 'space-between'
    },
    chargedCashbackTotaButtonText: {
        color: '#17333A',
        fontWeight: 700,
        display: 'flex',
        alignItems: 'center'
    },
    customInputNumber: {
        bgcolor: '#FEFDF9',
        borderRadius: '15px',
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
        },

        '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
        },
    },
    customInput: {
        bgcolor: '#FEFDF9',
        borderRadius: '15px',
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
        },
        '& .MuiInputBase-input': {
            color: '#142A30', // Cor do texto
            fontWeight: 'bold', // Define a fonte como bold
        },
        '& .MuiInputBase-input::placeholder': {
            color: '#142A30', // Define a cor do placeholder
            opacity: 1, // Opacidade do placeholder
            fontWeight: 'bold', // Negrito
        },
    },
    customInputBigPrice: {
        bgcolor: '#FEFDF9',
        borderRadius: '15px',
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
        },
        '& .MuiInputBase-input': {
            color: '#142A30', // Cor do texto
            fontWeight: 'bold', // Define a fonte como bold
            padding: 0
        },
        '& .MuiInputBase-input::placeholder': {
            color: '#142A30', // Define a cor do placeholder
            opacity: 1, // Opacidade do placeholder
            fontWeight: 'bold', // Negrito
        },
    },
    customInputCod: {
        bgcolor: '#F0EFEB',
        borderRadius: '15px',
        marginTop: '10px',
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
        }
    },
});
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Menu from "./componentes/menu";
import MenuMobile from "./componentes/menuMobile";


import { useEffect } from "react";
import xloja_12 from './assets/xloja_12.png'; // Tell webpack this JS file uses this image
import waiting from './assets/waiting.jpg';
import xloja from './assets/xloja.png'; // Tell webpack this JS file uses this image
import visa from './assets/visa.png';
import master from './assets/master.png'; // Tell webpack this JS file uses this image
import elo from './assets/elo_debito.png'; // Tell webpack this JS file uses this image
import hipercard from './assets/hipercard.png'; // Tell webpack this JS file uses this image
import amex from './assets/amex.png'; // Tell webpack this JS file uses this image
import print from './assets/print.png';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faX, faArrowUpRightFromSquare, faHouse, faShoppingBag, faChartPie, faBox, faCircleDollarToSlot, faBullhorn, faQuestionCircle, faUsers, faGear, faSquareXmark, faMobile, faCalendar, faChevronDown, faBookReader } from '@fortawesome/free-solid-svg-icons'
import { useState } from "react";

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import logo1 from './assets/xuliz_logo2.png'; // Tell webpack this JS file uses this image

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import * as XLSX from 'xlsx';
import { Button } from "react-bootstrap";


import { saveAs } from 'file-saver';


import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import CustomSeparator from "./componentes/Breadcrumb";
import Tabela from "./VendasHoje/Tabela";
import { Grid, OutlinedInput, Typography } from "@mui/material";
import Stylesheet from "reactjs-stylesheet";
import VendasHoje from "./VendasHoje/VendasHoje";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const TelaXloja01Areaparceiros = () => {
  const navigate = useNavigate();

  const [emailRef, setEmailRef] = useState('');
  const [value, setValue] = useState('');
  const [urlfotoperfil, setFotoUrl] = useState('');
  const [cashbackConta, setCashback] = useState('3');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const [total_credito, setTotalCredito] = useState('R$ 0,00');
  const [total_debito, setTotalDebito] = useState('R$ 0,00');
  const [total_Xuliz, setTotalXuliz] = useState('R$ 0,00');

  const [dadosX, setDadosX] = useState([]);
  const [rows, setRow] = useState([]);
  const [tokenEsta, setTokenEstabelecimento] = useState('');

  const [quantidadeVendas, setQuantidadeVendas] = useState('0');
  const [valorTotal, setValorTotal] = useState('0');
  const [valorLiquido, setValorLiquido] = useState('0');

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);

  const [vendaInicio, setVendaInicio] = useState('');
  const [vendaFinal, setVendaFinal] = useState('');

  const [isMobile, setIsMobile] = useState(false);

  const [filters, setFilters] = useState([
    { type: 'Bandeira', value: 'bandeira' }
  ]);

  const [screenWidth, setScreenWidth] = useState(null);
  const [loading, setloading] = useState(false);
  const [menuOpen, setMenuOpen] = useState(true);

  useEffect(() => {
    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  const updateDimensions = () => {
    setIsMobile(window.innerWidth <= 800); // Defina o limite de largura para o modo mobile conforme necessário
    verificarNiveisLogin(dadosX);
    setScreenWidth(window.innerWidth);

    if (window.innerWidth <= 1400 && menuOpen) {
      setMenuOpen(false)
    }
    if (window.innerWidth > 1400 && menuOpen) {
      setMenuOpen(true)
    }
  };

  const handleDownload = async () => {

    var dados = [];

    for (var x = 0; x < rows.length; x++) {
      var linha = [
        rows[x].id,
        rows[x].data_hora,
        rows[x].bandeira,
        rows[x].valor_venda
      ];
      dados.push(linha);
    }

    var dados_empresa = await localStorage.getItem('login')
    dados_empresa = JSON.parse(dados_empresa);
    var empresa = dados_empresa.empresa;

    const docDefinition = {
      pageSize: 'A4',
      pageOrientation: 'landscape', // Para orientação paisagem
      pageMargins: [40, 60, 40, 60], // Margens da página
      content: [
        { text: empresa + ' | Minhas vendas - Xloja', fontSize: 16, bold: true, margin: [0, 0, 0, 10] },
        {
          table: {
            headerRows: 1,
            widths: ['25%', '25%', '25%', '25%'],
            body: [
              [
                { text: 'ID', bold: true, fontSize: 10 },
                { text: 'Data/Hora', bold: true, fontSize: 10 },
                { text: 'Bandeira', bold: true, fontSize: 10 },
                { text: 'Valor venda', bold: true, fontSize: 10 },
              ],
              ...dados.map(row => row.map(cell => ({ text: cell, fontSize: 8 })))
            ]
          }
        }
      ]
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    pdfDocGenerator.getBlob((blob) => {
      saveAs(blob, 'minhas_vendas.pdf');
    });
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const clickAplicar = () => {
    setShowCalendar(false)
    VerificaChamar(endDate, startDate)
  };

  const toggleCalendar = () => {
    setShowCalendar(true)
    return false;
  };

  const VerificaChamar = async (endDate, startDate, showCalendar) => {
    await sleep(300)
    getDadosSQL()
  };

  const columns = [
    { id: 'id', label: 'ID' },
    { data_hora: 'data_hora', label: 'Data/hora' },
    { bandeira: 'bandeira', label: 'Bandeira' },
    { forma_pagamento: 'forma_pagamento', label: 'Forma de Pagamento' },
    { valor_venda: 'valor_venda', label: 'Valor venda' },
    { valor_venda: 'status', label: 'Status' }
  ];

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setarHora()
    getDadosSQL()
  }, []);

  const Swal = require('sweetalert2')

  const setarHora = async () => {
    localStorage.setItem('setValorFinalFloat', '');
    localStorage.setItem('setValorInicialFloat', '');
    var getDa = await getData();
    document.getElementById("horas").innerHTML = getDa;
  };

  const real = (valor) => {

    if (valor == '-') {
      return '-'
    }

    valor = valor.replace('R$', '')
    valor = parseFloat(valor)

    const options = {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2
    };

    return valor.toLocaleString('pt-BR', options);
  };

  const exportToExcel = () => {
    // var data = [
    //   ["Nome", "Idade", "País"],
    //   ["Maria", 25, "Brasil"],
    //   ["John", 32, "Estados Unidos"],
    //   ["Hans", 28, "Alemanha"]
    // ];
    const array = Object.entries(rows);

    var data = []
    data.push([
      'id',
      'data_hora',
      'bandeira',
      'valor'
    ])

    for (var x = 0; x < rows.length; x++) {
      // console.log(rows[x])
      data.push([
        rows[x].id,
        rows[x].data_hora,
        rows[x].bandeira,
        rows[x].valor_venda
      ])
    }

    // console.log(data)

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, "Planilha1");
    XLSX.writeFile(wb, "dados.xlsx");
  };

  const getData = async () => {
    var today = new Date();
    var day = today.getDate() + "";
    var month = (today.getMonth() + 1) + "";
    var year = today.getFullYear() + "";
    var hour = today.getHours() + "";
    var minutes = today.getMinutes() + "";

    day = checkZero(day);
    month = checkZero(month);
    year = checkZero(year);
    hour = checkZero(hour);
    minutes = checkZero(minutes);

    var data = day + "/" + month + "/" + year + " " + hour + ":" + minutes;

    return data;
    function checkZero(data) {
      if (data.length == 1) {
        data = "0" + data;
      }
      return data;
    }
  };

  const getDataInicial = async () => {
    var data = new Date();
    // data.setDate(data.getDate() - 1);

    var ano = data.getFullYear();
    var mes = String(data.getMonth() + 1).padStart(2, '0');
    var dia = String(data.getDate()).padStart(2, '0');

    return ano + '-' + mes + '-' + dia;
  };

  const getDataFinal = async () => {
    var data = new Date();

    var ano = data.getFullYear();
    var mes = String(data.getMonth() + 1).padStart(2, '0');
    var dia = String(data.getDate()).padStart(2, '0');

    return ano + '-' + mes + '-' + dia;
  };

  const getTokenEstabelciment = async (token) => {
    const form = new FormData();
    form.append("idempresa", "1350");
    form.append("token", "e84d7ad2-f0cc-11eb-b838-568a76eb275e");
    form.append("metodo", "ListaEstabelecimentos");

    const options = {
      method: 'POST',
    };

    options.body = form;

    var rres = await fetch('https://xuliz.com.br/painel/integracao/estabelecimento.php', options).then(response => response.json());

    var arrrayEstabelecimnto = rres.estabelecimentos;

    var tokenEstabelecimento = '';
    for (var x = 0; x < arrrayEstabelecimnto.length; x++) {
      if (arrrayEstabelecimnto[x].idestabelecimento == token) {
        tokenEstabelecimento = arrrayEstabelecimnto[x].token;
        break;
      }
    }

    //  console.log(rres)
    //  console.log(tokenEstabelecimento)
    setTokenEstabelecimento(tokenEstabelecimento)
    return tokenEstabelecimento;
  };

  const getDadosSQL = async (dataSetada, ini, fim) => {

    setRow([])
    setloading(true);
    // document.getElementById('loading').style.display = 'flex';
    // document.getElementById('exportCSV').style.display = 'none';

    await getDados()

    var dados = await localStorage.getItem('login')
    dados = JSON.parse(dados);
    var idestabele = dados.idestabelecimento;

    // var getTokenEstabelecimneto = await getTokenEstabelciment(idestabele);
    var getTokenEstabelecimneto = dadosX.token;

    if (getTokenEstabelecimneto == null) {
      getTokenEstabelecimneto = dados.tokenestabelecimento;
    }

    var datainicial = await getDataInicial()
    var datafinal = await getDataFinal()

    if (dataSetada == undefined) {

      var data = new Date();
      var data2 = new Date();

      data.setDate(data.getDate());

      setStartDate(data)
      setEndDate(data2)
    }

    if (dataSetada == true) {
      datainicial = ini;
      datafinal = fim;
    }

    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams({
        idempresa: '1350',
        token: getTokenEstabelecimneto,
        idvendaxuliz: '',
        metodo: 'Lista',
        idestabelecimento: idestabele,
        idvenda: '',
        datainicial: datainicial,
        datafinal: datafinal
      })
    };

    fetch('https://xuliz.com.br/painel/integracao/vendaxuliz.php', options)
      // fetch('https://www.xulizsistemas.com.br/retaguarda/integracao/venda/listarvendageral.php', options)
      .then(response => response.json())
      .then(response => tradarDados(response, idestabele))
      .catch(err => console.error(err));
  };

  const handleChangeInicio = (event) => {

    var rawValue2 = event.value.replace(/\D/g, '');

    var valor2 = parseFloat(rawValue2);
    valor2 = rawValue2 / 100;

    localStorage.setItem('setValorInicialFloat', '' + valor2)

    setVendaInicio(formatCurrency(event.value));
    VerificaChamar()
  };

  const handleChangeFinal = (event) => {
    // console.log('event.value', event.value)
    var rawValue2 = event.value.replace(/\D/g, '');

    var valor2 = parseFloat(rawValue2);
    valor2 = rawValue2 / 100;

    localStorage.setItem('setValorFinalFloat', '' + valor2)

    setVendaFinal(formatCurrency(event.value));

    VerificaChamar()
  };

  const tradarDados = async (dadosG, idestabele) => {

    // console.log('dadosX', dadosX)
    // console.log('filters', filters)

    if (dadosG.erro == "S") {
      alert('Encontramos um problema', dadosG.msg)
      return false;
    }

    setRow([])

    // var dadosZ = dadosG.lista;
    var dadosZ = dadosG.lista;

    // console.log('dadosZ', dadosZ)
    var pedidos = []

    var valorVendaTotal = 0;
    var valorLiquidoX = 0;

    //FILTROS
    // var bandeira = document.getElementById('qual_bandeira').value;
    const bandeira = filters.find(filter => filter.type === 'Bandeira')?.value;

    var filtrarBandeira = false;
    if (bandeira != '' && bandeira != 'bandeira') {
      filtrarBandeira = true
    }

    // var qual_forma = document.getElementById('qual_forma').value;

    // var filtrarForma = false;
    // if (qual_forma != '' && qual_forma != 'forma_pga') {
    //   filtrarForma = true
    // }

    // var filtroVendaInicio = false;

    // var valorInicio = await localStorage.getItem('setValorInicialFloat');
    // if (valorInicio == null || valorInicio == '') {
    //   valorInicio = '0';
    // }

    // valorInicio = parseFloat(valorInicio);

    // if (valorInicio > 0) {
    //   filtroVendaInicio = true;
    // }

    // var filtroVendaFinal = false;

    // var valorFinal = await localStorage.getItem('setValorFinalFloat');
    // if (valorFinal == null || valorFinal == '') {
    //   valorFinal = '0';
    // }

    // valorFinal = parseFloat(valorFinal);

    // if (valorFinal > 0) {
    //   filtroVendaFinal = true;
    // }

    // if(vendaInicio != '' && vendaInicio != '')

    var totalCredito = 0;
    var totalDebito = 0;
    var totalXuliz = 0;

    for (var x = 0; x < dadosZ.length; x++) {
      var getBandeira = '-';

      var formaPagamento = '-';

      if (dadosZ[x].transacao && dadosZ[x].transacao[0]) {
        getBandeira = dadosZ[x].transacao[0].bandeira
        formaPagamento = dadosZ[x].transacao[0].bandeira

        dadosZ[x].bandeira = getBandeira

        if (getBandeira == "MASTERCARD") {
          formaPagamento = "Master Crédito"
          getBandeira = "Master Crédito"
        }

        if (getBandeira == "MAESTRO") {
          formaPagamento = "Maestro Débito"
          getBandeira = "Master Débito"
        }

        if (getBandeira.trim() == "MAESTRO") {
          formaPagamento = "Maestro Débito"
          getBandeira = "Master Débito"
        }

        if (getBandeira.trim() == "MASTERCARD") {
          formaPagamento = "Maestro Débito"
          getBandeira = "Master Débito"
        }

        if (getBandeira == "ELO DEBITO") {
          formaPagamento = "Elo Débito"
          getBandeira = "Elo Débito"
        }

        if (getBandeira == "ELO CREDITO") {
          formaPagamento = "Elo Crédito"
          getBandeira = "Elo Crédito"
        }

        if (getBandeira.trim() == "ELO CREDITO") {
          formaPagamento = "Elo Débito"
          getBandeira = "Elo Débito"
        }

        if (getBandeira == "VISA ELECTRON") {
          formaPagamento = "Visa Débito"
          getBandeira = "Visa Débito"
        }

        if (getBandeira.trim() == "VISA ELECTRON") {
          formaPagamento = "Visa Débito"
          getBandeira = "Visa Débito"
        }

        if (getBandeira == "AMEX CREDITO") {
          formaPagamento = "Amex Crédito"
          getBandeira = "Amex Crédito"
        }

        if (getBandeira == "VISA") {
          formaPagamento = "Visa Crédito"
          getBandeira = "Visa Crédito"
        }
        if (getBandeira.trim() == "VISA") {
          formaPagamento = "Visa Crédito"
          getBandeira = "Visa Crédito"
        }
      }

      //vendas no xuliz
      if (dadosZ[x].detalhe && dadosZ[x].detalhe[0]) {
        getBandeira = 'Xuliz'
        formaPagamento = 'Xuliz'
      }

      //filtros
      if (filtrarBandeira == true) {

        if (getBandeira == null) {
          continue
        }

        // console.log(getBandeira + ' -- ' + bandeira)
        if (!getBandeira.includes(bandeira)) {
          continue
        }
      }

      // if (filtrarForma == true) {
      //   var forma = getBandeira
      //   var getQualForma = qual_forma;

      //   if (!forma.includes(getQualForma)) {
      //     continue
      //   }
      // }

      // if (filtroVendaInicio == true) {
      //   var totalDaVenda = '' + dadosZ[x].total;
      //   totalDaVenda = parseFloat(totalDaVenda);

      //   if (totalDaVenda < valorInicio) {
      //     continue;
      //   }
      // }


      // if (filtroVendaFinal == true) {
      //   var totalDaVenda = '' + dadosZ[x].total;
      //   totalDaVenda = parseFloat(totalDaVenda);

      //   if (totalDaVenda > valorFinal) {
      //     continue;
      //   }
      // }

      //filtros
      // var id = dadosZ[x].idvenda;
      var data = dadosZ[x].data;
      var datapagamento = '-';

      if (dadosZ[x].detalhe && dadosZ[x].detalhe[0]) {
        datapagamento = dadosZ[x].detalhe[0].datavencimento
      }

      if (data != null && data != '') {
        data = data.split('-');
        data = data[2] + '/' + data[1] + '/' + data[0]
      }

      if (datapagamento != null && datapagamento != '') {
        datapagamento = datapagamento.split('-');
        datapagamento = datapagamento[2] + '/' + datapagamento[1] + '/' + datapagamento[0]
      }

      if (datapagamento == null) {
        datapagamento = '-'
      }

      var valor_venda = 'R$ ' + dadosZ[x].total;
      // var valor_venda_liquido = 0;

      var tota = dadosZ[x].total;

      if (dadosZ[x].total == null || dadosZ[x].total == '') {
        valor_venda = '-';
        tota = 0;
      }


      // if (dadosZ[x]?.vendaparcela?.length) {
      //   // Itera sobre as parcelas da venda
      //   for (var i = 0; i < dadosZ[x].vendaparcela.length; i++) {
      //     // Converte o valor líquido para número e soma ao total
      //     valor_venda_liquido += parseFloat(dadosZ[x].vendaparcela[i].valorliquido) || 0;
      //   }
      // }

      tota = parseFloat(tota)

      if (getBandeira.includes("Crédito")) {
        totalCredito = totalCredito + tota;
      }

      if (getBandeira.includes("Débito")) {
        totalDebito = totalDebito + tota;
      }

      if (getBandeira.includes("Xuliz")) {
        totalXuliz = totalXuliz + tota;
      }

      valorVendaTotal = valorVendaTotal + tota;

      var totaLiquido = dadosZ[x].valorliquido;

      if (dadosZ[x].valorcashback == null || dadosZ[x].valorcashback == '') {
        valorcashback = '-'
      }

      // if (dadosZ[x].valorxuliz == null || dadosZ[x].valorxuliz == '') {
      //   valorxuliz = '-'
      // }

      if (dadosZ[x].total == null || dadosZ[x].total == '') {
        total = '-'
      }

      if (dadosZ[x].valorliquido == null || dadosZ[x].valorliquido == '') {
        valorliquido = '-';
        totaLiquido = 0;
      }

      totaLiquido = parseFloat(totaLiquido)

      valorLiquidoX = valorLiquidoX + totaLiquido;

      if (dadosZ[x].detalhe && dadosZ[x].detalhe[0]) {
        valor_venda = dadosZ[x].detalhe[0].valorbruto
      }

      // NEW ROWS VAR

      var codigo_autorizacao = dadosZ[x].codigoautorizacao

      if (codigo_autorizacao == null || codigo_autorizacao == "") {
        codigo_autorizacao = "-"
      }

      var dataHora = dadosZ[x].datestamp

      // var bandeiraPagamento = dadosZ[x].bandeira;

      // if (bandeiraPagamento != null) {
      //   bandeiraPagamento = bandeiraPagamento
      // }

      // var forma_pagamento = dadosZ[x].bandeira

      // dadosZ.forEach((venda) => {
      //   // Verificar se a venda possui transações
      //   if (venda.transacao && venda.transacao.length > 0) {
      //     venda.transacao.forEach((transacao) => {
      //       let forma_pagamento = transacao.bandeira;

      //       // Verifica e define a forma de pagamento
      //       if (forma_pagamento && forma_pagamento.toLowerCase() !== "xuliz") {
      //         forma_pagamento = forma_pagamento.split(" ");

      //         if (forma_pagamento[0].toLowerCase() === 'pix') {
      //           forma_pagamento = 'Pix';
      //         } else if (forma_pagamento[1] === 'Crédito' && venda.vendaparcela && venda.vendaparcela.length > 1) {
      //           forma_pagamento = 'Crédito parcelado';
      //         } else if (forma_pagamento[1] === 'Crédito' && venda.vendaparcela && venda.vendaparcela.length === 1) {
      //           forma_pagamento = 'Crédito à vista';
      //         } else if (forma_pagamento[1]) {
      //           forma_pagamento = forma_pagamento;
      //         }
      //       }

      //       // Retornar apenas a forma de pagamento processada
      //       console.log(forma_pagamento);  // Aqui você pode substituir por `return forma_pagamento` se estiver dentro de uma função
      //     });
      //   }
      // });

      var valorcashback = 'R$ ' + dadosZ[x].valorcashback;

      if (dadosZ[x].valorcashback == null || dadosZ[x].valorcashback == '') {
        valorcashback = '-'
      }

      var valortaxa = 'R$ ' + dadosZ[x].valortaxa;

      if (dadosZ[x].valortaxa == null || dadosZ[x].valortaxa == '') {
        valortaxa = '-'
      }
      var valorliquido = 'R$ 0';
      valorliquido = '' + dadosZ[x].valorliquido;
      valorliquido = valorliquido.replace("-", "")
      valorliquido = parseFloat(valorliquido);
      var totaLiquido = dadosZ[x].valorliquido;

      if (dadosZ[x].valorliquido == null || dadosZ[x].valorliquido == '') {
        valorliquido = '-';
        totaLiquido = 0;
      }

      var status = '' + dadosZ[x].vendastatus;
      var id = dadosZ[x].idvenda;
      // var vendaparcela = '';
      //

      // if (dadosZ[x]?.vendaparcela[0]?.nrtotalparcelas) {
      //   vendaparcela = dadosZ[x].vendaparcela.length;
      // }

      // console.log('nrparcela', dadosZ[x].vendaparcela[0].nrparcela);
      // console.log('nrtotalparcelas', dadosZ[x].vendaparcela[0].nrtotalparcelas);
      var idExists = pedidos.some(pedido => pedido.id === id);

      if (!idExists) {
        pedidos.push(
          {
            id: id,
            codigo_autorizacao: codigo_autorizacao,
            dataHora: '' + dataHora,
            previsao_pagamento: '' + datapagamento,
            bandeira: getBandeira,
            forma_pagamento: getBandeira,
            valor_venda: '' + valor_venda,
            cashback: '' + valorcashback,
            valor_taxa: '' + valortaxa,
            // valor_liquido_parcelado: '' + valor_venda_liquido,
            valor_liquido: '' + valorliquido,
            // vendaparcela: '' + vendaparcela,
            status: '' + status
          });
      }
    }

    setQuantidadeVendas(pedidos.length)
    setValorTotal('R$ ' + valorVendaTotal)
    setValorLiquido('R$ ' + valorLiquidoX)

    setTotalCredito('R$ ' + totalCredito)
    setTotalDebito('R$ ' + totalDebito)
    setTotalXuliz('R$ ' + totalXuliz)

    setloading(false);
    // document.getElementById('loading').style.display = 'none';
    // document.getElementById('exportCSV').style.display = 'block';

    // console.log('A',pedidos)
    pedidos = pedidos.sort((a, b) => b.id - a.id);
    // console.log('pedidos', pedidos)
    // console.log('dadosZ', dadosZ)

    setRow(pedidos)
  };

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  };

  const verDetalhes = async (dados) => {
    // alert(JSON.stringify(dados))
    location.href = './detalhesvendas?id=' + dados.id + '&data=' + dados.data_hora
  };

  const getDados = async () => {
    var dados = await localStorage.getItem('login')

    if (dados == null) {
      navigate("/");
      return false;
    }

    dados = JSON.parse(dados)
    setDadosX(dados)
    verificarNiveisLogin(dados)

    //  console.log('dadosX',dados)

    setEmailRef(dados.email)
    //  console.log(dados)
  };

  const verificarNiveisLogin = async (dadosX) => {
    var idperfil = dadosX.idusuariotipo;

    if (idperfil == "7") {
      await sleep(50)

      //financeiro somente relatorios
      document.getElementById('realizar_vendaID').style.display = "none";
      document.getElementById('menu_marketing').style.display = "none";
      document.getElementById('menu_configuracoes').style.display = "none";
    }

    if (idperfil == "8") {
      await sleep(50)

      location.href = "./xloja";
    }
  };

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  };

  const formatCurrency = (inputValue) => {
    const rawValue = inputValue.replace(/\D/g, '');

    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    return formatter.format(rawValue / 100);
  };

  if (isMobile == true) {
    return (
      <>
        <MenuMobile page="xloja" />


        <div style={{ height: 150, width: '100%', flexDirection: 'row', display: 'flex', padding: 10 }}>
          <div style={{ width: '100%', display: 'flex', flex: 1, margin: 5, borderRadius: 10, justifyContent: 'center', alignItems: 'center', flexDirection: 'column', backgroundColor: '#F1F1F1' }} id="realizar_vendaID" onClick={() => { location.href = './xloja' }}>
            {/* <img src={xloja} style={{ width: "auto", height: 30 }} /> */}
            <FontAwesomeIcon icon={faCircleDollarToSlot} style={{ fontSize: 30, color: "#262626" }} />
            <span style={{ textAlign: 'center', fontSize: 14, marginTop: 10 }}>XLoja</span>
          </div>
          <div style={{ width: '100%', backgroundColor: '#142A30', display: 'flex', flex: 1, margin: 5, borderRadius: 10, justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }} onClick={() => { location.href = './vendashoje' }}>
            <FontAwesomeIcon icon={faShoppingBag} style={{ fontSize: 30, color: "#FFFFFF" }} />
            <span style={{ textAlign: 'center', fontSize: 14, marginTop: 10, color: "#FFFFFF" }}>Vendas de hoje</span>
          </div>
          <div style={{ width: '100%', backgroundColor: '#f1f1f1', display: 'flex', flex: 1, margin: 5, borderRadius: 10, justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }} onClick={() => { location.href = './minhasvendas' }}>
            <FontAwesomeIcon icon={faBookReader} style={{ fontSize: 30, color: "#262626" }} />
            <span style={{ textAlign: 'center', fontSize: 14, marginTop: 10 }}>Histórico de vendas venda</span>
          </div>
        </div>

        <VendasHoje
          screenWidth={screenWidth}
          loading={loading}
          rows={rows}
          page={page}
          rowsPerPage={rowsPerPage}
          isMobile={isMobile}
        />

      </>
    )
  }

  return (
    <>
      <div style={{ maxWidth: '1920px', height: '100vh', display: "flex" }}>
        <div style={{ backgroundColor: '#262626' }}>
          <Menu page="xloja" menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
        </div>
        <div style={{ overflow: 'auto', width: '100%' }}>
          <VendasHoje
            screenWidth={screenWidth}
            loading={loading}
            rows={rows}
            page={page}
            rowsPerPage={rowsPerPage}
            isMobile={isMobile}
          />
        </div>
      </div>
    </>
  )
};

export default TelaXloja01Areaparceiros;
import { useEffect, useState } from "react";
import Stylesheet from "reactjs-stylesheet";

import Tabela from "./Tabela";
import CustomSeparator from "../componentes/Breadcrumb";

import InputMask from 'react-input-mask';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, OutlinedInput, TextField, Typography } from "@mui/material";
import { faArrowUpRightFromSquare, faX } from "@fortawesome/free-solid-svg-icons";
import ModalExport from "../componentes/ModalExport";

const VendasHoje = ({
    screenWidth,
    loading,
    rows,
    page,
    rowsPerPage,
    isMobile
}) => {

    const bandeiraMap = {
        "Xuliz": ["Xuliz"],
        "Visa": ["Visa Crédito", "Visa Débito"],
        "Master": ["Master Crédito", "Master Débito", "MASTERCARD", "MAESTRO"],
        "Elo": ["Elo Crédito", "Elo Débito"],
       "Hipercard": ["Hipercard Crédito", "Hipercard Débito", "Hiper Crédito", "Hiper Débito"],
        "Amex": ["Amex Crédito", "Amex Débito"],
        "Pix": ["-"],
    };

    const [selectedBandeira, setSelectedBandeira] = useState('');
    const [horaIni, setHoraIni] = useState('');
    const [horaFinal, setHoraFinal] = useState('');
    const [ultimoDigito, setUltimoDigito] = useState('');
    const [codigoAutorizacao, setCodigoAutorizacao] = useState('');
    const [filteredRows, setFilteredRows] = useState(null);
    const [filters, setFilters] = useState([]);
    const [open, setOpen] = useState(false);

    const extractTime = (dateTimeString) => {
        if (!dateTimeString) return ''; // Retorna uma string vazia se for undefined ou null

        // Se o formato for apenas HH:mm, não há necessidade de divisão
        if (dateTimeString.length === 5 && dateTimeString.includes(':')) {
            return dateTimeString;
        }

        const parts = dateTimeString.split(' '); // Divide pelo espaço
        if (parts.length < 2) return ''; // Verifica se existe a parte da hora

        const timePart = parts[1]; // A segunda parte deve ser a hora no formato HH:MM:SS
        return timePart.substring(0, 5); // Retorna apenas HH:mm
    };

    const applyFilters = () => {
        let filtered = rows;

        if (selectedBandeira && selectedBandeira !== 'bandeira') {
            const bandeiraOptions = bandeiraMap[selectedBandeira] || [];
            filtered = filtered.filter(i => bandeiraOptions.includes(i.bandeira));
        }

        if (extractTime(horaIni) && extractTime(horaFinal)) {
            const horaIniFormatted = horaIni ? extractTime(horaIni) : '00:00';
            const horaFinalFormatted = horaFinal ? extractTime(horaFinal) : '23:59';

            filtered = filtered.filter(i => {
                const itemHora = extractTime(i.dataHora);
                return itemHora >= horaIniFormatted && itemHora <= horaFinalFormatted;
            });
        }

        if (ultimoDigito) {
            filtered = filtered.filter(i => i.numero_cartao.endsWith(ultimoDigito));
        }

        if (codigoAutorizacao) {
            filtered = filtered.filter(i => i.codigo_autorizacao.includes(codigoAutorizacao));
        }

        setFilteredRows(filtered);
    };

    useEffect(() => {
        applyFilters();
    }, [selectedBandeira, horaIni, horaFinal, ultimoDigito, codigoAutorizacao, rows]);

    const handleBandeiraChange = (event) => {
        filtersTitle('Bandeira', event.target);
        setSelectedBandeira(event.target.value);
    };

    const handleHoraIniChange = (event) => {
        filtersTitle('Hora de venda (início)', event.target);
        setHoraIni(event.target.value);
    };

    const handleHoraFinalChange = (event) => {
        filtersTitle('Hora de venda (final)', event.target);
        setHoraFinal(event.target.value);
    };

    const handleUltimoDigitoChange = (event) => {
        // filtersTitle('Bandeira', event.target);
        setUltimoDigito(event.target.value);
    };

    const handleCodigoAutorizacaoChange = (event) => {
        filtersTitle('Código de autorização', event.target);
        setCodigoAutorizacao(event.target.value);
    };

    const filtersTitle = (name, data) => {
        const newFilters = [...filters];
        const existingFilterIndex = newFilters.findIndex(filter => filter.type === name);

        if (existingFilterIndex !== -1) {
            newFilters[existingFilterIndex].value = data.value;
        } else {
            newFilters.push({ type: name, value: data.value });
        }

        setFilters(newFilters);
    };

    const removeFilter = (name) => {
        let updatedFilters = filters.filter(filter => filter.type !== name);

        setFilters(updatedFilters);

        if (name === 'Bandeira') {
            setSelectedBandeira('bandeira');
        } else if (name === 'Código de autorização') {
            setCodigoAutorizacao('');
        } else if (name === 'Hora de venda (início)') {
            setHoraIni('');
        } else if (name === 'Hora de venda (final)') {
            setHoraFinal('');
        } else if (name === '4 últimos dígitos do cartão') {
            setUltimoDigito('');
        }

        applyFilters();
    };

    return (
        <Grid item xs sx={screenWidth <= 1400 ? styles.contentSmallScreen : isMobile ? styles.contentMobile : styles.content}>

            {isMobile ? null :
                <Grid sx={[screenWidth <= 1400 ? styles.customSeparatorSmallScreen : styles.customSeparator, { flexDirection: screenWidth <= 800 ? 'column' : 'row' }]}>
                    <CustomSeparator menuOption={'Xloja'} item={1} />
                    <div style={{ height: 50, display: "flex", width: "30%", alignItems: 'center', justifyContent: 'flex-end' }}>
                        <span style={{ marginLeft: 10, fontSize: 20, marginLeft: 30, fontWeight: "bold" }} id="horas"></span>
                    </div>
                </Grid>
            }

            <Grid container spacing={0} style={{ width: '100%', display: "flex", flexDirection: 'column', paddingBottom: '25px', justifyContent: 'space-between' }}>

                <Grid style={{ width: '100%', flexDirection: 'column', paddingBottom: '25px' }}>

                    <Grid item container style={{
                        gap: screenWidth <= 800 ? '5px' : null,
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: screenWidth <= 800 ? 'column' : 'row',
                    }}>

                        <Grid item sx={{ width: screenWidth <= 800 ? '100%' : screenWidth <= 1099 ? '17.7%' : '18%' }}>
                            <InputMask
                                maskChar={null}
                                mask={"99:99"}
                                value={horaIni}
                                onChange={handleHoraIniChange}
                            >
                                {() => (
                                    <TextField
                                        placeholder="Hora de venda (início)"
                                        type="text"
                                        sx={[styles.customInputNumber, {
                                            width: isMobile ? '100%' : null
                                        }]}
                                    />
                                )}
                            </InputMask>
                        </Grid>

                        <Grid item sx={{ width: screenWidth <= 800 ? '100%' : screenWidth <= 1099 ? '17.7%' : '18%' }}>
                            <InputMask
                                maskChar={null}
                                mask={"99:99"}
                                value={horaFinal}
                                onChange={handleHoraFinalChange}
                            >
                                {() => (
                                    <TextField
                                        placeholder="Hora de venda (final)"
                                        type="text"
                                        sx={[styles.customInputNumber, {
                                            width:
                                                isMobile ? `100%` : '100%'
                                        }]}
                                    />
                                )}
                            </InputMask>
                        </Grid>

                        <Grid item sx={{ width: screenWidth <= 800 ? '100%' : screenWidth <= 1099 ? '17.7%' : '18%' }}>
                            <OutlinedInput
                                sx={[styles.customInputNumber, {
                                    width:
                                        isMobile ? `100%` : '100%'
                                }]}
                                type="text"
                                placeholder="4 últimos dígitos cartão"
                                value={ultimoDigito}
                                onChange={handleUltimoDigitoChange}
                            />
                        </Grid>

                        <Grid item sx={{ width: screenWidth <= 800 ? '100%' : screenWidth <= 1099 ? '17.7%' : '18%' }}>
                            <OutlinedInput
                                sx={[styles.customInputNumber, {
                                    width:
                                        isMobile ? `100%` : '100%'
                                }]}
                                type="text"
                                placeholder="Código de autorização"
                                value={codigoAutorizacao}
                                onChange={handleCodigoAutorizacaoChange}
                            />
                        </Grid>

                        <Grid item sx={{ width: screenWidth <= 800 ? '100%' : screenWidth <= 1099 ? '17.7%' : '18%' }}>
                            <TextField
                                select
                                variant="outlined"
                                sx={[styles.customInputNumber,
                                {
                                    width:
                                        isMobile ? `100%` : '100%',
                                    display: 'flex',
                                    justifyContent: 'center'
                                }]}
                                value={selectedBandeira}
                                onChange={handleBandeiraChange}
                                InputProps={{
                                    id: "qual_bandeira",
                                }}
                                SelectProps={{
                                    native: true,
                                }}
                            >
                                <option value="bandeira">Bandeira</option>
                                <option value="Xuliz">Xulis</option>
                                <option value="Visa">Visa</option>
                                <option value="Master">Master</option>
                                <option value="Elo">Elo</option>
                                <option value="Pix">Pix</option>
                                <option value="Hipercard">Hipercard</option>
                                <option value="Amex">Amex</option>
                            </TextField>
                        </Grid>

                        <Grid onClick={() => setOpen(!open)} item
                            sx={{
                                width: isMobile ? '100%' : '5%',
                                height: '45px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                border: '1px solid #2F4650',
                                borderRadius: '15px',
                                bgcolor: '#142A30',
                                cursor: 'pointer'
                            }}>
                            <FontAwesomeIcon
                                icon={faArrowUpRightFromSquare}
                                style={{ fontSize: '16px', color: "#FFFFFF", cursor: 'pointer' }}
                                onClick={() => setOpen(!open)}
                            />
                        </Grid>

                    </Grid>

                </Grid>

                {filters.length > 0 ?
                    <Grid sx={{
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : screenWidth <= 920 ? 'column' : 'row',
                        alignItems: 'center',
                        paddingBottom: '35px'
                    }}>
                        <Typography sx={{ width: '17ch', color: '#142A3099', fontSize: '15px', fontWeight: 600, paddingRight: '10px' }}>
                            Filtros selecionados:
                        </Typography>

                        <Grid sx={{ display: 'flex', flexDirection: isMobile ? 'column' : null, width: isMobile ? '100%' : null }}>
                            {filters.map((filter, index) => (
                                <Grid
                                    key={index}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        padding: "3px 8px",
                                        marginBottom: isMobile ? '5px' : null,
                                        borderRadius: '5px',
                                        backgroundColor: '#9DE200',
                                        marginRight: '10px',
                                        alignItems: 'center',
                                        justifyContent: isMobile ? 'space-between' : null
                                    }}
                                >
                                    <Typography sx={{ color: '#142A30', fontSize: '10px', fontWeight: 600, marginRight: '10px' }}>
                                        {filter.type}: {filter.value == "Xuliz" ? "Xulis" : filter.value}
                                    </Typography>
                                    <FontAwesomeIcon
                                        icon={faX}
                                        style={{ fontSize: 10, color: "#262626", cursor: 'pointer' }}
                                        onClick={() => removeFilter(filter.type)}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid> : ''
                }

                <div style={{ width: '100%', flexDirection: 'column', display: "flex" }}>
                    <Tabela
                        loading={loading}
                        rows={filteredRows ? filteredRows : rows}
                        page={page}
                        rowsPerPage={rowsPerPage}
                    />
                </div>

            </Grid>

            <ModalExport rows={rows} open={open} setOpen={setOpen} isMobile={isMobile} />

        </Grid>
    );
};

export default VendasHoje;

const styles = Stylesheet.create({
    content: {
        margin: '40px 50px 16px 70px'
    },
    contentSmallScreen: {
        margin: '30px 25px'
    },
    contentMobile: {
        margin: '0px 10px'
    },
    customSeparator: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '43px'
    },
    customSeparatorSmallScreen: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-around',
        marginBottom: '20px'
    },
    customInputNumber: {
        height: '45px',
        borderRadius: '15px',
        border: '1px solid #2F4650',
        display: 'flex',
        justifyContent: 'center',
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
        '& .MuiInputBase-input': {
            fontSize: '15px',
            color: '#142A30',
            fontWeight: 'bold',
            padding: '15px 17px',
        },
        '& .MuiInputBase-input::placeholder': {
            color: '#142A30',
            opacity: 1,
            fontWeight: 'bold',
        },
        '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
        },
    },
});
import * as React from 'react';
import { Grid, TextField } from '@mui/material';
import Stylesheet from 'reactjs-stylesheet';

export default function MonthYearPick({ handleSelectChange, handleAnoChange, mes, ano }) {

    return (
        <Grid>
            <Grid sx={{
                height: '45px',
                display: 'flex',
                borderRadius: '15px',
                alignItems: 'center',
                justifyContent: 'center',
                border: '1px solid #142A30',

            }}>
                <TextField
                    select
                    variant="outlined"
                    sx={{ ...styles.customInputNumber }}
                    value={mes}
                    onChange={handleSelectChange}
                    SelectProps={{
                        native: true,
                    }}
                >
                    <option value="0">Janeiro</option>
                    <option value="1">Fevereiro</option>
                    <option value="2">Março</option>
                    <option value="3">Abril</option>
                    <option value="4">Maio</option>
                    <option value="5">Junho</option>
                    <option value="6">Julho</option>
                    <option value="7">Agosto</option>
                    <option value="8">Setembro</option>
                    <option value="9">Outubro</option>
                    <option value="10">Novembro</option>
                    <option value="11">Dezembro</option>
                </TextField>
                /
                <TextField
                    select
                    variant="outlined"
                    sx={{ ...styles.customInputNumber }}
                    value={ano}
                    onChange={handleAnoChange}
                    SelectProps={{
                        native: true,
                    }}
                >
                    {/* Gera uma lista de anos dinâmicos */}
                    {Array.from({ length: 11 }).map((_, index) => {
                        const year = ano - 5 + index; // 5 anos antes até 5 anos depois
                        return (
                            <option key={year} value={year}>
                                {year}
                            </option>
                        );
                    })}
                </TextField>
            </Grid>
        </Grid >
    );
}

const styles = Stylesheet.create({
    customInputNumber: {
        height: '45px',
        border: 'none',
        display: 'flex',
        justifyContent: 'center',
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
        '& .MuiInputBase-input': {
            fontSize: '15px',
            color: '#142A30',
            fontWeight: 'bold',
        },
        '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
        },
    },
});

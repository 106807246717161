import { Grid, Modal, Typography } from "@mui/material";
import closeCircle from '../../assets/icons/closeCircle.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamation } from "@fortawesome/free-solid-svg-icons";

const ModalAccess = ({ modal, close }) => {
    return (
        <Modal open={modal} onClose={close}>
            <Grid
                sx={{
                    width: '100%',
                    maxWidth: '545px',
                    top: '50%',
                    left: '50%',
                    boxShadow: 24,
                    border: 'none',
                    bgcolor: '#F0EFEB',
                    padding: '10px 12px',
                    borderRadius: '20px',
                    position: 'absolute',
                    transform: 'translate(-50%, -50%)'
                }}>

                <Grid sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <img
                        src={closeCircle}
                        onClick={close}
                        style={{
                            width: '30px',
                            height: '30px',
                            cursor: 'pointer'
                        }} />
                </Grid>

                <Grid sx={{
                    gap: '15px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '0px 30px 36px 30px',
                }}>
                    <Grid sx={{
                        width: '56px',
                        height: '56px',
                        display: 'flex',
                        borderRadius: '20px',
                        alignItems: 'center',
                        justifyContent: 'center',
                        border: '1px solid #FFB74D',
                        bgcolor: '#FFC5814D',
                    }}>
                        <FontAwesomeIcon icon={faExclamation} style={{ fontSize: 20, color: "#000000" }} />
                    </Grid>

                    <Grid sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '36ch',
                    }}>
                        <Typography sx={{ fontSize: '20px', color: '#142A30', fontWeight: '600' }}>
                            Ainda não consegue entrar aqui!
                        </Typography>
                        <Typography sx={{ fontSize: '14px', color: '#142A3099' }}>
                            Você ainda não concluiu o seu cadastro inicial, o que resultou na sua conta estar em status de "Em análise". Isso impede o acesso a algumas funcionalidades do Xulis Parceiros.
                        </Typography>
                    </Grid>

                </Grid>
            </Grid>
        </Modal >
    )
}

export default ModalAccess;

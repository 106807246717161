import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import Menu from "./componentes/menu";
import MenuMobile from "./componentes/menuMobile";
import { useEffect } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingBag, faCircleDollarToSlot, faBookReader } from '@fortawesome/free-solid-svg-icons'
import { useState } from "react";

import "react-datepicker/dist/react-datepicker.css";
import * as XLSX from 'xlsx';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import MinhasVendas from "./MinhasVendas/MinhasVendas";
import { format } from "date-fns";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const TelaXloja01Areaparceiros = () => {

  const navigate = useNavigate();
  // const [emailRef, setEmailRef] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dadosX, setDadosX] = useState([]);
  const [rows, setRow] = useState([]);

  // const [tokenEsta, setTokenEstabelecimento] = useState('');

  // const [quantidadeVendas, setQuantidadeVendas] = useState('0');
  // const [valorTotal, setValorTotal] = useState('0');
  // const [valorLiquido, setValorLiquido] = useState('0');
  // const [geralCredito, setGeralCredito] = useState('0');
  // const [geralDebito, setGeralDebito] = useState('0');
  // const [geralXuliz, setGeralXuliz] = useState('0');

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);

  // const [vendaInicio, setVendaInicio] = useState('');
  // const [vendaFinal, setVendaFinal] = useState('');

  const [filters, setFilters] = useState([
    { type: 'Bandeira', value: 'bandeira' }
  ]);

  const [screenWidth, setScreenWidth] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [loading, setloading] = useState(false);
  const [menuOpen, setMenuOpen] = useState(true);

  useEffect(() => {
    updateDimensions();
    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  const updateDimensions = () => {
    setIsMobile(window.innerWidth <= 800); // Defina o limite de largura para o modo mobile conforme necessário
    verificarNiveisLogin(dadosX);
    setScreenWidth(window.innerWidth);

    if (window.innerWidth <= 1400 && menuOpen) {
      setMenuOpen(false)
    }
    if (window.innerWidth > 1400 && menuOpen) {
      setMenuOpen(true)
    }
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;

    setStartDate((start));
    setEndDate(end);

    if (start && end) {
      setloading(true);
      VerificaChamar(end, start);
    }
  };

  // const handleIconClick = () => {

  //   //Clicou abrir os selects

  //   //mudar o icone


  //   document.getElementById("qual_bandeira1").click()
  //   if (selectRef.current) {
  //     selectRef.current.click();
  //   }
  // };

  // const clickAplicar = () => {

  //   // console.log('startDate---------------', startDate)
  //   // console.log('endDate------------------', endDate)

  //   if (endDate == null) {
  //     setEndDate(startDate)
  //     setShowCalendar(false)
  //     VerificaChamar(startDate, startDate)
  //     return false
  //   }

  //   setShowCalendar(false)
  //   VerificaChamar(endDate, startDate)
  // }

  const toggleCalendar = () => {


    setShowCalendar(true)


    return false;

  };

  const VerificaChamar = async (endDate, startDate, showCalendar) => {

    await sleep(300)

    getDadosSQL(true, format(startDate, 'yyyy-MM-dd'), format(endDate, 'yyyy-MM-dd'))

  }

  // const columns = [
  //   { id: 'id', label: 'ID', color: 'red' },
  //   { codigo_autorizacao: 'codigo_autorizacao', label: 'Código Autorização' },
  //   // { data_hora: 'data_hora', label: 'Data/hora' },
  //   { dataHora: 'dataHora', label: 'Data/Hora' },
  //   { previsao_pagamento: 'previsao_pagamento', label: 'Previsão de pagamento' },
  //   { bandeira: 'bandeira', label: 'Bandeira' },
  //   { forma_pagamento: 'forma_pagamento', label: 'Forma de Pagamento' },
  //   { valor_venda: 'valor_venda', label: 'Valor venda' },
  //   { cashback: 'Cashback', label: 'Cashback' },
  //   { valor_taxa: 'valor_taxa', label: 'Valor da taxa/tarifa' },
  //   { valor_liquido: 'valor_liquido', label: 'Valor líquido' },
  //   { status: 'status', label: 'Status' },
  // ];

  // // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setarHora()

    getDadosSQL()
  }, []);

  // const Swal = require('sweetalert2');

  const setarHora = async () => {
    localStorage.setItem('setValorFinalFloat', '');
    localStorage.setItem('setValorInicialFloat', '');

    var getDa = await getData();
    document.getElementById("horas").innerHTML = getDa;
  }

  const real = (valor) => {

    if (valor == '-') {
      return '-'
    }

    valor = parseFloat(valor)

    const options = {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2
    };

    return valor.toLocaleString('pt-BR', options);
  }

  const getData = async () => {
    var today = new Date();
    var day = today.getDate() + "";
    var month = (today.getMonth() + 1) + "";
    var year = today.getFullYear() + "";
    var hour = today.getHours() + "";
    var minutes = today.getMinutes() + "";

    day = checkZero(day);
    month = checkZero(month);
    year = checkZero(year);
    hour = checkZero(hour);
    minutes = checkZero(minutes);

    var data = day + "/" + month + "/" + year + " " + hour + ":" + minutes;

    return data;
    function checkZero(data) {
      if (data.length == 1) {
        data = "0" + data;
      }
      return data;
    }
  }

  const getDataInicial = async () => {
    var data = new Date();
    data.setDate(data.getDate() - 1);

    var ano = data.getFullYear();
    var mes = String(data.getMonth() + 1).padStart(2, '0');
    var dia = String(data.getDate()).padStart(2, '0');

    return ano + '-' + mes + '-' + dia;
  }

  const getDataFinal = async () => {
    var data = new Date();

    var ano = data.getFullYear();
    var mes = String(data.getMonth() + 1).padStart(2, '0');
    var dia = String(data.getDate()).padStart(2, '0');

    return ano + '-' + mes + '-' + dia;
  }

  const getTokenEstabelciment = async (token) => {
    const form = new FormData();
    form.append("idempresa", "1350");
    form.append("token", "e84d7ad2-f0cc-11eb-b838-568a76eb275e");
    form.append("metodo", "ListaEstabelecimentos");


    const options = {
      method: 'POST',
    };

    options.body = form;

    var rres = await fetch('https://xuliz.com.br/painel/integracao/estabelecimento.php', options).then(response => response.json());

    var arrrayEstabelecimnto = rres.estabelecimentos;

    var tokenEstabelecimento = '';
    for (var x = 0; x < arrrayEstabelecimnto.length; x++) {
      if (arrrayEstabelecimnto[x].idestabelecimento == token) {
        tokenEstabelecimento = arrrayEstabelecimnto[x].token;
        console.log('tokenEstabelecimento', tokenEstabelecimento)
        break;
      }
    }

    return tokenEstabelecimento;

  }

  const getDadosSQL = async (dataSetada, ini, fim) => {

    setRow([])

    setloading(true);
    // document.getElementById('loading').style.display = 'flex';
    // document.getElementById('exportCSV').style.display = 'none';

    await getDados()

    var dados = await localStorage.getItem('login')
    dados = JSON.parse(dados);
    var idestabele = dados.idestabelecimento;

    var getTokenEstabelecimneto = await getTokenEstabelciment(idestabele);

    if (getTokenEstabelecimneto == null) {
      getTokenEstabelecimneto = dados.tokenestabelecimento;
    }

    var datainicial = await getDataInicial()
    var datafinal = await getDataFinal()

    if (dataSetada == undefined) {

      var data = new Date();
      var data2 = new Date();

      data.setDate(data.getDate() - 1);



      setStartDate(data)
      setEndDate(data2)
    }

    if (dataSetada == true) {
      datainicial = ini;
      datafinal = fim;
    }

    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams({
        idempresa: '1350',
        token: getTokenEstabelecimneto,
        idvendaxuliz: '',
        metodo: 'Lista',
        idestabelecimento: idestabele,
        idvenda: '',
        datainicial: datainicial,
        datafinal: datafinal
      })
    };

    // fetch('https://xuliz.com.br/painel/integracao/vendaxuliz.php', options)
    fetch('https://www.xulizsistemas.com.br/retaguarda/integracao/venda/listarvendageral.php', options)
      .then(response => response.json())
      .then(response => {

        tradarDados(response, idestabele)
      })
      .catch(err => console.error(err));

  }

  const tradarDados = async (dadosG, idestabele) => {

    if (dadosG.erro == "S") {
      alert('Encontramos um problema', dadosG.msg)
      return false;
    }

    setRow([])

    var dadosZ = dadosG.retorno;
    var pedidos = []
    var valorVendaTotal = 0;
    var valorLiquidoX = 0;

    const bandeira = filters.find(filter => filter.type === 'Bandeira')?.value;

    var filtrarBandeira = false;
    if (bandeira != '' && bandeira != 'bandeira') {
      filtrarBandeira = true
    }

    // var totalCREDITO = 0;
    // var totalDEBITO = 0;
    // var totalXuliz = 0;

    for (var x = 0; x < dadosZ.length; x++) {

      if (filtrarBandeira == true) {

        var bandeiraG = dadosZ[x].bandeira;

        if (bandeiraG == null) {
          continue
        }

        if (!bandeiraG.includes(bandeira)) {

          continue
        }

      }

      var id = dadosZ[x].id;
      var data = dadosZ[x].data;
      var datapagamento = '-';

      var dataHora = formatarDataHora('dataHora', dadosZ[x].data)
      var prevPagamento = `${dadosZ[x].datavencimento} 00:00:00`

      function formatarDataHora(name, dataHora) {
        if (dataHora && dataHora !== '') {
          const [data, hora] = dataHora.split(' '); // Divide a data e a hora
          const [ano, mes, dia] = data.split('-'); // Divide a data em ano, mês e dia
          const [horaPart, minuto] = hora.split(':'); // Divide a hora em hora e minuto

          // Formata a data e hora no formato desejado
          if (name == "prevPagamento") {
            const dataFormatada = `${dia}/${mes}/${ano}`;
            return dataFormatada;
          }
          const dataFormatada = `${dia}/${mes}/${ano} ${horaPart}:${minuto}`;

          // console.log(dataFormatada); // Exibe o resultado no console
          return dataFormatada;
        }
        return '';
      }

      var valorliquido = 'R$ 0';
      datapagamento = dadosZ[x].datavencimento

      if (dadosZ[x]) {

        var getBruto = '' + dadosZ[x].valorbruto;
        var getTaxas = '' + dadosZ[x].valortaxa;

        getBruto = parseFloat(getBruto);

        var getBandeira = '' + dadosZ[x].bandeira;


      }

      valorliquido = '' + dadosZ[x].valorliquido;
      valorliquido = valorliquido.replace("-", "")
      valorliquido = parseFloat(valorliquido);

      if (data != null && data != '') {
        data = data.split('-');
        data = data[2] + '/' + data[1] + '/' + data[0]
      }

      if (datapagamento != null && datapagamento != '') {
        datapagamento = datapagamento.split('-');
        datapagamento = datapagamento[2] + '/' + datapagamento[1] + '/' + datapagamento[0]
      }

      if (datapagamento == null) {
        datapagamento = '-'
      }

      var valor_venda = 'R$ ' + dadosZ[x].valorbruto;
      var tota = dadosZ[x].valorbruto;

      if (dadosZ[x].valorbruto == null || dadosZ[x].valorbruto == '') {
        valor_venda = '-';
        tota = 0;
      }

      tota = parseFloat(tota)
      valorVendaTotal = valorVendaTotal + tota;

      var valorcashback = 'R$ ' + dadosZ[x].valorcashback;
      var valortaxa = 'R$ ' + dadosZ[x].valortaxa;
      var valorcomissao = 'R$ ' + dadosZ[x].valorcomissao;
      var totaLiquido = dadosZ[x].valorliquido;

      if (dadosZ[x].valorcashback == null || dadosZ[x].valorcashback == '') {
        valorcashback = '-'
      }

      if (dadosZ[x].valortaxa == null || dadosZ[x].valortaxa == '') {
        valortaxa = '-'
      }

      if (dadosZ[x].valorcomissao == null || dadosZ[x].valorcomissao == '') {
        valorcomissao = '-'
      }

      if (dadosZ[x].valorliquido == null || dadosZ[x].valorliquido == '') {
        valorliquido = '-';
        totaLiquido = 0;
      }

      totaLiquido = parseFloat(totaLiquido)
      valorLiquidoX = valorLiquidoX + totaLiquido;
      var bandeiraPagamento = dadosZ[x].bandeira;

      if (bandeiraPagamento != null) {
        bandeiraPagamento = bandeiraPagamento
      }

      var forma_pagamento = dadosZ[x].bandeira

      // console.log(' dadosZ[x]', dadosZ[x])

      if (forma_pagamento && forma_pagamento.toLowerCase() != "XULIZ".toLowerCase()) {
        forma_pagamento = forma_pagamento.split(" ");

        if (forma_pagamento[0].toLowerCase() == 'pix') {
          forma_pagamento = 'Pix'
        } else if (forma_pagamento[1] == 'Crédito' && dadosZ[x].vendaparcela.length > 1) {
          forma_pagamento = 'Crédito parcelado'
        } else if (forma_pagamento[1] == 'Crédito' && dadosZ[x].vendaparcela.length == 1) {
          forma_pagamento = 'Crédito à vista'
        } else {
          forma_pagamento = forma_pagamento[1]
        }
      }

      var valorbruto = dadosZ[x].valorbruto;

      if (dadosZ[x].valorbruto == null || dadosZ[x].valorbruto == '') {
        valor_venda = '-';
        tota = 0;
      }

      var numerocartao = dadosZ[x].numerocartao

      var codigo_autorizacao = dadosZ[x].codigoautorizacao

      if (codigo_autorizacao == null || codigo_autorizacao == "") {
        if (dadosZ[x].tipo == "VENDAS") {
          codigo_autorizacao = dadosZ[x].id
        } else {
          codigo_autorizacao = "-"
        }
      }

      var status = '' + dadosZ[x].status;
      var vendaparcela = '';

      if (dadosZ[x]?.vendaparcela[0]?.nrtotalparcelas) {
        vendaparcela = dadosZ[x].vendaparcela.length;
      }

      var totalParcelado = 0;

      if (dadosZ[x].vendaparcela && dadosZ[x].vendaparcela.length > 0) {
        for (let y = 0; y < dadosZ[x].vendaparcela.length; y++) {
          const valorbruto = parseFloat(dadosZ[x].vendaparcela[y].valorbruto);
          if (!isNaN(valorbruto)) {
            totalParcelado += valorbruto;
          }
        }
      }

      var totalLiquido = 0;

      if (dadosZ[x].vendaparcela && dadosZ[x].vendaparcela.length > 1) {
        for (let y = 0; y < dadosZ[x].vendaparcela.length; y++) {
          const valorliquido = parseFloat(dadosZ[x].vendaparcela[y].valorliquido);
          if (!isNaN(valorliquido)) {
            totalLiquido += valorliquido;
          }
        }
      }

      // Verifica se o pedido com o mesmo ID já existe antes de fazer o push
      if (!pedidos.some(pedido => pedido.id === id && pedido.codigo_autorizacao === codigo_autorizacao)) {
        pedidos.push({
          id: id,
          codigo_autorizacao: codigo_autorizacao,
          numerocartao: numerocartao,
          prevPagamento: '' + prevPagamento,
          dataHora: '' + dataHora,
          previsao_pagamento: '' + datapagamento,
          bandeira: bandeiraPagamento,
          forma_pagamento: forma_pagamento,
          valorbruto: '' + totalParcelado > 0 ? totalParcelado : valorbruto,
          valor_venda: '' + totalParcelado > 0 ? totalParcelado : valor_venda,
          cashback: '' + valorcashback,
          valor_taxa: '' + valortaxa,
          valorcomissao: '' + valorcomissao,
          valor_liquido: '' + totalLiquido > 0 ? totalLiquido : valorliquido,
          vendaparcela: '' + vendaparcela,
          status: '' + status
        });
      }

    }

    // totalCREDITO = totalCREDITO.toFixed(2);
    // totalCREDITO = formatCurrency(totalCREDITO)

    // setGeralCredito(totalCREDITO)
    // totalDEBITO = totalDEBITO.toFixed(2);
    // totalDEBITO = formatCurrency(totalDEBITO)
    // setGeralDebito(totalDEBITO)
    // totalXuliz = totalXuliz.toFixed(2);
    // totalXuliz = formatCurrency(totalXuliz)
    // setGeralXuliz(totalXuliz)
    // setQuantidadeVendas(pedidos.length)
    // setValorTotal('R$ ' + valorVendaTotal)
    setloading(false);
    pedidos = pedidos.sort((a, b) => b.id - a.id);
    setRow(pedidos)
  }

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const getDados = async () => {
    var dados = await localStorage.getItem('login')
    if (dados == null) {
      navigate("/");
      return false;
    }
    dados = JSON.parse(dados)
    setDadosX(dados)
    verificarNiveisLogin(dados)
    // setEmailRef(dados.email)
  }

  const verificarNiveisLogin = async (dadosX) => {
    var idperfil = dadosX.idusuariotipo;

    if (idperfil == "7") {
      await sleep(50)

      //financeiro somente relatorios
      // document.getElementById('realizar_vendaID').style.display = "none";
      // document.getElementById('menu_marketing').style.display = "none";
      // document.getElementById('menu_configuracoes').style.display = "none";

    }

    if (idperfil == "8") {
      await sleep(50)
      location.href = "./xloja";
    }

  }

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const formatCurrency = (inputValue) => {
    const rawValue = inputValue.replace(/\D/g, '');

    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    return formatter.format(rawValue / 100);
  };

  if (isMobile == true) {
    return (
      <>
        <MenuMobile page="xloja" />

        <div style={{ height: 150, width: '100%', flexDirection: 'row', display: 'flex', padding: 10 }}>
          <div style={{ width: '100%', display: 'flex', flex: 1, margin: 5, borderRadius: 10, justifyContent: 'center', alignItems: 'center', flexDirection: 'column', backgroundColor: '#F1F1F1' }} id="realizar_vendaID" onClick={() => { location.href = './xloja' }}>
            {/* <img src={xloja} style={{ width: "auto", height: 30 }} /> */}
            <FontAwesomeIcon icon={faCircleDollarToSlot} style={{ fontSize: 30, color: "#262626" }} />
            <span style={{ textAlign: 'center', fontSize: 14, marginTop: 10 }}>XLoja</span>
          </div>
          <div style={{ width: '100%', backgroundColor: '#f1f1f1', display: 'flex', flex: 1, margin: 5, borderRadius: 10, justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }} onClick={() => { location.href = './vendashoje' }}>
            <FontAwesomeIcon icon={faShoppingBag} style={{ fontSize: 30, color: "#142A30" }} />
            <span style={{ textAlign: 'center', fontSize: 14, marginTop: 10, color: "#142A30" }}>Vendas de hoje</span>
          </div>
          <div style={{ width: '100%', backgroundColor: '#142A30', display: 'flex', flex: 1, margin: 5, borderRadius: 10, justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }} onClick={() => { location.href = './minhasvendas' }}>
            <FontAwesomeIcon icon={faBookReader} style={{ fontSize: 30, color: "#FFFFFF" }} />
            <span style={{ textAlign: 'center', fontSize: 14, marginTop: 10, color: "#FFFFFF" }}>Histórico de vendas venda</span>
          </div>
        </div>

        <MinhasVendas
          real={real}
          handleDateChange={handleDateChange}
          startDate={startDate}
          endDate={endDate}
          showCalendar={showCalendar}
          toggleCalendar={toggleCalendar}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rows={rows}
          page={page}
          loading={loading}
          isMobile={isMobile}
          rowsPerPage={rowsPerPage}
          screenWidth={screenWidth}
        />

      </>
    )
  }

  //desktop
  return (
    <>

      <div style={{ maxWidth: '1920px', height: '100vh', display: "flex" }}>
        <div style={{ backgroundColor: '#262626' }}>
          <Menu page="xloja" menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
        </div>
        <div style={{ overflow: 'auto', width: '100%' }}>
          <MinhasVendas
            menuOpen={menuOpen}
            real={real}
            handleDateChange={handleDateChange}
            startDate={startDate}
            endDate={endDate}
            showCalendar={showCalendar}
            toggleCalendar={toggleCalendar}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rows={rows}
            page={page}
            loading={loading}
            isMobile={isMobile}
            rowsPerPage={rowsPerPage}
            screenWidth={screenWidth}
            getDadosSQL={getDadosSQL}
            setStartDate={setStartDate}
          />
        </div>
      </div>
    </>

  )

};

export default TelaXloja01Areaparceiros;
